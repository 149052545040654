import React from 'react'
import PF2Monster from './PF2'

const MonsterIndex = props => {
    const { data, token, gameId } = props

    // in the future, switch which component we load based on game context
    const MonsterComponent = PF2Monster

    return <MonsterComponent {...props} />
}

export default MonsterIndex
