import React from 'react'
import * as characterService from '../../services/characters'
import * as avatarService from '../../services/avatars'

import './AvatarChoice.css'

const AvatarChoice = ({id, preview, name, characterId, token, avatarSelected}) => {
  const onClick = () => {
    characterService.updateAvatarUrl(preview, characterId, token)
  }

  const onDelete = () => {
    if (window.confirm(`Delete avatar: ${name}?`)) {
      avatarService.deleteAvatar(id, characterId, token)
    }
  }

  const highlight = avatarSelected ? "AvatarChoice__portrait AvatarChoice__portrait--selected" : "AvatarChoice__portrait"

  return(
    <div className="AvatarChoice" key={id}>
      <img
        src={preview}
        className={highlight}
        onClick={onClick}
      />
      <div className='AvatarChoice__controls'>
        <button className='AvatarChoice__delete' onClick={onDelete}>🗑</button>
        <div className='AvatarChoice__name'>{name}</div>
      </div>
    </div>
  )
}

export default AvatarChoice