import { ADD_GROUP_ROLL, DESTROY_GROUP_ROLL, UPDATE_GROUP_ROLL } from "../constants/action-types"

const initialState = {
  list: INITIAL.groupRolls
}

export default (state = initialState, action) => {
  let groupRollId
  let groupRollData

  if (action.payload) {
    groupRollId = action.payload.id
    groupRollData = action.payload
  }

  switch(action.type) {
    case ADD_GROUP_ROLL:
      return {
        ...state,
        list: {
          ...state.list,
          [groupRollId]: groupRollData
        }
      }
    case UPDATE_GROUP_ROLL:
      return {
        ...state,
        list: {
          ...state.list,
          [groupRollId]: groupRollData
        }
      }
    case DESTROY_GROUP_ROLL:
      const { [action.payload.id]: _, ...withoutGroupRoll } = state.list;
      return {
        ...state,
        list: withoutGroupRoll,
      }
    default:
      return state
  }
}
