import React from 'react'
import { connect } from 'react-redux'
import * as variableService from '../../services/variables'
import store from '../../store'
import './Variable.css'

const Variable = (props) => {
  const {
    variable,
    token,
    gameId,
    characterId,
  } = props

  const { name, structured_data } = variable
  const { label, formula, active } = structured_data
  const [formulaToggle, setFormulaToggle] = React.useState(true);
  const [labelToggle, setLabelToggle] = React.useState(true);

  const handleFormulaToggleInput = () => {
    setFormulaToggle(false);
  }

  const cancelFormulaEdit = () => {
    setFormulaToggle(true)
  }

  const saveFormulaEdit = (value) => {
    variable.structured_data.formula = value
    variableService.update(variable, variable.id, characterId, token)
    setFormulaToggle(true)
  }

  const handleFormulaToggleBlur = (event) => {
    cancelFormulaEdit()
  }

  const handleFormulaToggleKeyDown = (e) => {
    if (e.key === 'Enter') {
      saveFormulaEdit(e.target.value)
    } else if (e.keyCode === 27) {
      cancelFormulaEdit()
    }
  }

  const handleLabelToggleInput = () => {
    setLabelToggle(false);
  }

  const cancelLabelEdit = () => {
    setLabelToggle(true)
  }

  const saveLabelEdit = (label) => {
    variable.name = label
    variable.structured_data.label = label
    variableService.update(variable, variable.id, characterId, token)
    setLabelToggle(true)
  }

  const handleLabelToggleBlur = (event) => {
    cancelLabelEdit()
  }

  const handleLabelToggleKeyDown = (e) => {
    if (e.key === 'Enter') {
      saveLabelEdit(e.target.value)
    } else if (e.keyCode === 27) {
      cancelLabelEdit()
    }
  }

  const handleDeleteVariable = () => {
    if (window.confirm(`Delete variable: ${variable.name}?`)) {
      variableService.destroy(variable.id, characterId, token)
    }
  }

  const activeToggle = variable.structured_data.active != false
  const handleToggleVariable = (e) => {
    variable.structured_data.active = activeToggle != true
    variableService.update(variable, variable.id, characterId, token)
  }

  return (
    <div key={variable.id} className='Variable'>
      {labelToggle ? (
        <span
          className='Variable__label'
          onDoubleClick={handleLabelToggleInput}>{label}</span>
      ) : (
        <input
          autoFocus
          className='Variable__labelEdit'
          type="text"
          defaultValue={label}
          onBlur={handleLabelToggleBlur}
          onKeyDown={handleLabelToggleKeyDown} />
      )}
      {formulaToggle ? (
        <span
          className='Variable__formula'
          onDoubleClick={handleFormulaToggleInput}>{formula}</span>
      ) : (
        <input
          autoFocus
          className='Variable__formulaEdit'
          type="text"
          defaultValue={formula}
          onBlur={handleFormulaToggleBlur}
          onKeyDown={handleFormulaToggleKeyDown} />
      )}
      {activeToggle ? (
        <button
          className='Variable__activate'
          onClick={handleToggleVariable}>Active</button>
      ) : (
        <button
          className='Variable__activate'
          onClick={handleToggleVariable}>Inactive</button>
      )}
      <button
        className='VariableEdit__delete'
        onClick={handleDeleteVariable}>🗑</button>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    variable: ownProps.variable,
    token: state.client.token,
    gameId: state.game.id,
    characterId: state.characterId,
  }
}

const mapDispatchToProps = {
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Variable)
