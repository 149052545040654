import React from 'react'
import InlineAbility from './InlineAbility';
import { comma } from '../../../../lib/pf2/monster_utils';
import Protections from './Protections';

const Hitpoints = (props) => {
  const {
    hp,
    monsterName,
    token,
    gameId
  } = props

  if (!hp) {return null}

  const sharedMonsterData = {
    monsterName: monsterName,
    token: token,
    gameId: gameId,
  }

  const opening = (hp) => {
    if (hp.name) {
      return ' ('
    } else if (hp.automatic_abilities) {
      return ', '
    }
    return ''
  }

  const hpName = (hp) => {
    if (hp.name) {
      return `(${hp.name}) `
    }
  }

  const closing = (hp) => {
    if (hp.name) {
      return ')'
    }
  }

  const renderThresholds = (hp) => {
    if (hp.thresholds) {
      return (
        <span>
          {'; '}<strong>Thresholds</strong>{' '}
          {
            hp.thresholds.map( (t, i) => {
              return ` ${t.value} (${t.squares} squares)${comma(i, hp.thresholds)}`
            })
          }
        </span>
      )
    }
  }

  const renderHardness = (hp) => {
    if (hp.hardness) {
      return (
        <span>
          {'; '}<strong>Hardness</strong>{' '}
          {hp.hardness}
        </span>
      )
    }
  }

  let abilities = hp.automatic_abilities;

  return (
    <div className='Monster__hitpoints'>
      <strong>HP</strong>
      {' '}
      {hp.hp}
      {opening(hp)}
      {hpName(hp)}
      { abilities ? <span>
        {
          abilities.map( (a, i) => {
            return (
              <InlineAbility ability={a} {...sharedMonsterData} key={i}>{comma(i, abilities)}</InlineAbility>
            )
          })
        }
      </span> : null}
      {closing(hp)}
      {renderThresholds(hp)}
      {renderHardness(hp)}
      <Protections protections={hp.immunities} protectionType='Immunities' />
      <Protections protections={hp.resistances} protectionType='Resistances' />
      <Protections protections={hp.weaknesses} protectionType='Weaknesses' />
    </div>
  )
}

export default Hitpoints

/* Hitpoint Example

Hydra:

"hitpoints": [
    {
        "type": "stat_block_section",
        "subtype": "hitpoints",
        "hp": 90,
        "name": "body",
        "automatic_abilities": [
            {
                "type": "stat_block_section",
                "subtype": "ability",
                "name": "hydra regeneration",
                "ability_type": "automatic"
            }
        ]
    },
    {
        "type": "stat_block_section",
        "subtype": "hitpoints",
        "hp": 15,
        "name": "head",
        "automatic_abilities": [
            {
                "type": "stat_block_section",
                "subtype": "ability",
                "name": "head regrowth",
                "ability_type": "automatic"
            }
        ],
        "immunities": [
          ...
        ],
        "weaknesses": [
          ...
        ]
    }
],

Animated Armor:

"hitpoints": [
    {
        "type": "stat_block_section",
        "subtype": "hitpoints",
        "hp": 20,
        "hardness": 9,
        "immunities": [
          ...
        ]
    }
],

City Guard Squadron:

"hitpoints": [
    {
        "type": "stat_block_section",
        "subtype": "hitpoints",
        "hp": 75,
        "squares": 16,
        "thresholds": [
            {
                "type": "stat_block_section",
                "subtype": "threshold",
                "value": 50,
                "squares": 12
            },
            {
                "type": "stat_block_section",
                "subtype": "threshold",
                "value": 25,
                "squares": 8
            }
        ],
        "weaknesses": [
          ...
        ]
    }
],

*/
