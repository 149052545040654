import React from 'react'
import Ability from './Ability'
import AC from './AC';
import Saves from './Saves';
import Hitpoints from './Hitpoints';
import PropTypes from 'prop-types';
import {
  DEFENSE
} from '../../../../constants/monster_constants'

const renderProtections = (protections) => {
  if (!protections) {return null}
  const fields = protections[protections.subtype]
  //TODO: Handle links
  return (
    <span>;{' '}<strong>{protections.name}</strong>{' '}{
      fields.map((p,i) => {
        return (
          <span key={i}>
            {p.name}{typeof p.value === 'undefined'
              ? null
              : <span>{' '}{p.value}</span>
            }{typeof p.modifiers === 'undefined'
              ? null
              : <span>{' '}({
                p.modifiers.map((m,i) => m.name).join(', ')
              })</span>
            }{i < fields.length - 1 ? ", " : ""}
          </span>
        )
      })}
    </span>
  )
}

const renderHp = (hp, hardness, defense) => {
  //TODO: Handle links
  return (
    <div>
      <span>
        <strong>{hp.name}</strong>{' '}{hp.value}
        {typeof hp.automatic_abilities === 'undefined'
          ? null
          : <span>{' '}({hp.automatic_abilities.map((a,i) => {
            return (
              <span>
                {a.name}
                {typeof a.modifiers === 'undefined'
                  ? null
                  : <span>{' '}({
                    a.modifiers.map((m,i) => m.name).join(', ')
                  })</span>
                }
                {i < hp.automatic_abilities.length - 1 ? ", " : ""}
              </span>
            )
          })})</span>}
      </span>
      {typeof hardness === 'undefined'
        ? null
        : <span>; <strong>{hardness.name}</strong> {hardness.value}
          {typeof hardness.automatic_abilities === 'undefined'
            ? null
            : <span> ({hardness.automatic_abilities.map((a,i) => {
              return (
                <span>
                  {a.name}
                  {typeof a.modifiers === 'undefined'
                    ? null
                    : <span> ({
                      a.modifiers.map((m,i) => m.name).join(', ')
                    })</span> }
                  {i < hardness.automatic_abilities- 1 ? ", " : ""}
                </span>
              )
            })})</span>
          }
        </span>
      }
      {renderProtections(defense.immunities)}
      {renderProtections(defense.resistances)}
      {renderProtections(defense.weaknesses)}
    </div>
  )
}

const renderAbilities = (abilities) => {
  return (
    <div className='Monster__abilities'>
      {
        abilities ? <span> {
          abilities.map((ability,i) => {
            <Ability ability={ability} i={i} />
          })}</span> : null
      }
    </div>
  )
}

const Defense = (props) => {
  const {
    defense,
    gameId,
    monsterName,
    token,
  } = props

  if (!defense) {return null}

  const sharedMonsterData = {
    monsterName: monsterName,
    token: token,
    gameId: gameId,
  }

  //TODO: Handle links
  return (
    <div className='Monster__defense'>
      <div>
        <AC ac={defense.ac} />{'; '}
        <Saves saves={defense.saves} {...sharedMonsterData} />
      </div>
      {
        defense.hitpoints.map((hp, i) => {
          return (
            <Hitpoints hp={hp} {...sharedMonsterData} key={i} />
          )
        })
      }
      {
        defense.automatic_abilities ? (
          <div className='Monster__abilities'>
            {defense.automatic_abilities.map((ability, i) => {
              return (
                <Ability ability={ability} i={i} {...sharedMonsterData} key={i} />
              )
            })}
          </div>
        ) : (null)
      }
      {
        defense.reactive_abilities ? (
          <div className='Monster__abilities'>
            {defense.reactive_abilities.map((ability, i) => {
              return (
                <Ability ability={ability} i={i} {...sharedMonsterData} key={i}/>
              )
            })}
          </div>
        ) : (null)
      }

{/*
      {renderHp(defense.hp, defense.hardness, defense)}
      {renderAbilities(defense.automatic_abilities)}
      {renderAbilities(defense.reactive_abilities)}
*/}
    </div>
  )
}

const dn = (value) => {
  return value > 0 ? '+' + value : value
}

/*
Defense.propTypes = PropTypes.shape(DEFENSE)
*/

export default Defense

/*
"defense": {
  "type": "stat_block_section",
  "subtype": "defense",
  "name": "Defense",
  "ac": {
    "type": "stat_block_section",
    "subtype": "armor_class",
    "name": "AC",
    "value": 36,
    "modifiers": [
      {
        "type": "stat_block_section",
        "subtype": "modifier",
        "name": "13 when broken"
      },
      {
        "type": "stat_block_section",
        "subtype": "modifier",
        "name": "construct armor"
      }
    ]
  },
  "saves": {
    "type": "stat_block_section",
    "subtype": "saves",
    "name": "Saves",
    "fort": {
      "type": "stat_block_section",
      "subtype": "save",
      "name": "Fort",
      "value": 27
    },
    "ref": {
      "type": "stat_block_section",
      "subtype": "save",
      "name": "Ref",
      "value": 26
    },
    "will": {
      "type": "stat_block_section",
      "subtype": "save",
      "name": "Will",
      "value": 31,
      "modifiers": [
        {
          "type": "stat_block_section",
          "subtype": "modifier",
          "name": "+33 vs. emotion"
        }
      ]
    },
    "modifiers": [
      {
        "type": "stat_block_section",
        "subtype": "modifier",
        "name": "+2 status to all saves vs. disease and poison",
        "links": [
          {
            "type": "link",
            "name": "disease",
            "alt": "disease",
            "game-obj": "Traits",
            "aonid": 46
          },
          {
            "type": "link",
            "name": "poison",
            "alt": "poison",
            "game-obj": "Traits",
            "aonid": 126
          }
        ]
      }
    ],
  },
  "hp": {
    "type": "stat_block_section",
    "subtype": "hitpoints",
    "name": "HP",
    "value": 12,
    "automatic_abilities": [
      {
        "type": "stat_block_section",
        "subtype": "ability",
        "name": "fast healing",
        "ability_type": "automatic",
        "modifiers": [
          {
            "type": "stat_block_section",
            "subtype": "ability",
            "name": "negative healing",
            "ability_type": "automatic"
          },
          {
            "type": "stat_block_section",
            "subtype": "modifier",
            "name": "in open air"
          }
        ],
        "value": 2
      },
      {
        "type": "stat_block_section",
        "subtype": "ability",
        "name": "fast healing",
        "ability_type": "automatic",
        "links": [
          {
            "type": "link",
            "name": "fast healing 30",
            "alt": "fast healing 30",
            "game-obj": "MonsterAbilities",
            "aonid": 15
          }
        ],
        "value": 30
      }
    ]
  },
  "immunities": {
    "type": "stat_block_section",
    "subtype": "immunities",
    "name": "Immunities",
    "immunities": [
      {
        "type": "stat_block_section",
        "subtype": "immunity",
        "name": "disease"
      },
      {
        "type": "stat_block_section",
        "subtype": "immunity",
        "name": "mental"
      },
      {
        "type": "stat_block_section",
        "subtype": "immunity",
        "name": "non-magical attacks"
      },
      {
        "type": "stat_block_section",
        "subtype": "immunity",
        "name": "paralysis"
      },
      {
        "type": "stat_block_section",
        "subtype": "immunity",
        "name": "poison"
      },
      {
        "type": "stat_block_section",
        "subtype": "immunity",
        "name": "precision"
      },
      {
        "type": "stat_block_section",
        "subtype": "immunity",
        "name": "unconscious"
      }
    ]
  },
  "resistances": {
    "type": "stat_block_section",
    "subtype": "resistances",
    "name": "Resistances",
    "resistances": [
      {
        "type": "stat_block_section",
        "subtype": "resistance",
        "name": "all damage",
        "modifiers": [
          {
            "type": "stat_block_section",
            "subtype": "modifier",
            "name": "except force or ghost touch"
          }
        ],
        "value": 5
      }
    ]
  },
  "weaknesses": {
    "type": "stat_block_section",
    "subtype": "weaknesses",
    "name": "Weaknesses",
    "weaknesses": [
      {
        "type": "stat_block_section",
        "subtype": "weakness",
        "name": "chaotic",
        "value": 3
      }
    ]
  }
},
*/




