import React from 'react'
import PropTypes from 'prop-types';
import {
  LANGUAGES
} from '../../../../constants/monster_constants'
import Language from './Language';
import Modifiers from './Modifiers';
import InlineAbility from './InlineAbility';
import { comma } from '../../../../lib/pf2/monster_utils';

const Languages = (props) => {
  const {
    languages,
    monsterName,
    token,
    gameId
  } = props

  const sharedMonsterData = {
    monsterName: monsterName,
    token: token,
    gameId: gameId,
  }

  if (!languages) {return null}

  let langs = languages.languages;
  let abilities = languages.communication_abilities;

  return (
    <div className='Monster__languages'>
      <strong>Languages </strong>
      {langs ? langs.map( (l, i) =>
        <Language language={l} i={i} {...sharedMonsterData} key={i}>{comma(i, langs)}</Language>
      ) : null}{comma(-1, abilities, "; ")}
      {abilities ? abilities.map( (c, i) => {
        return (
          <InlineAbility ability={c} {...sharedMonsterData} key={i}>{comma(i, abilities)}</InlineAbility>
        )
      }) : null}<Modifiers modifiers={languages.modifiers} semicolon={true}/>
    </div>
  )
}

//Languages.propTypes = PropTypes.shape(LANGUAGES)

export default Languages

/* Languages Examples
Awakened Tree:

"languages": {
    "type": "stat_block_section",
    "subtype": "languages",
    "languages": [
      ...
    ],
    "modifiers": [
        {
            "type": "stat_block_section",
            "subtype": "modifier",
            "name": "can\u2019t speak any language"
        }
    ]
},

Fungus Leshy:

"languages": {
    "type": "stat_block_section",
    "subtype": "languages",
    "languages": [
      ...
    ],
    "communication_abilities": [
        {
            "type": "stat_block_section",
            "subtype": "ability",
            "name": "speak with plants",
            "ability_type": "communication",
            "links": [
                {
                    "type": "link",
                    "name": "speak with plants",
                    "alt": "speak with plants",
                    "game-obj": "Spells",
                    "aonid": 294
                }
            ],
            "modifiers": [
                {
                    "type": "stat_block_section",
                    "subtype": "modifier",
                    "name": "fungi only"
                }
            ]
        }
    ]
},
*/

/* Language Examples
Mari Lwyd:

"languages": [
    {
        "name": "Common",
        "type": "stat_block_section",
        "subtype": "language",
        "link": {
            "type": "link",
            "name": "Common",
            "alt": "Common",
            "game-obj": "Languages",
            "aonid": 1
        }
    },
    {
        "name": "Sylvan",
        "type": "stat_block_section",
        "subtype": "language",
        "link": {
            "type": "link",
            "name": "Sylvan",
            "alt": "Sylvan",
            "game-obj": "Languages",
            "aonid": 10
        }
    }
]


Soulbound Doll:

"languages": [
    {
        "name": "one spoken by its creator",
        "type": "stat_block_section",
        "subtype": "language",
        "modifiers": [
            {
                "type": "stat_block_section",
                "subtype": "modifier",
                "name": "typically Common"
            }
        ]
    }
]
*/