import React from 'react'
/*import PropTypes from 'prop-types';
import {
  SKILLS
} from '../../../../constants/monster_constants'*/
import Skill from './Skill'
import { comma } from '../../../../lib/pf2/monster_utils';

const Skills = (props) => {
  const {
    skills,
    monsterName,
    token,
    gameId
  } = props

  const sharedMonsterData = {
    monsterName: monsterName,
    token: token,
    gameId: gameId,
  }

  if (!skills) {return null}

  return (
    <div className='Monster__skills'>
      <strong>Skills </strong>
      {
        skills.map((s, i) => {
          return (
            <Skill skill={s} i={i} {...sharedMonsterData} key={i}>{comma(i, skills)}</Skill>
          )
        })
      }
    </div>
  )
}

//Skills.propTypes = PropTypes.shape(SKILLS)

export default Skills

/* Skills Example
Alghollthu Master:

"skills": [
    {
        "type": "stat_block_section",
        "subtype": "skill",
        "name": "Athletics",
        "link": {
            "type": "link",
            "name": "Athletics",
            "alt": "Athletics",
            "game-obj": "Skills",
            "aonid": 3
        },
        "value": 16
    },
    {
        "type": "stat_block_section",
        "subtype": "skill",
        "name": "Deception",
        "link": {
            "type": "link",
            "name": "Deception",
            "alt": "Deception",
            "game-obj": "Skills",
            "aonid": 5
        },
        "value": 15
    },
    {
        "type": "stat_block_section",
        "subtype": "skill",
        "name": "Intimidation",
        "link": {
            "type": "link",
            "name": "Intimidation",
            "alt": "Intimidation",
            "game-obj": "Skills",
            "aonid": 7
        },
        "value": 15
    },
    {
        "type": "stat_block_section",
        "subtype": "skill",
        "name": "Lore",
        "link": {
            "type": "link",
            "name": "Lore",
            "alt": "Lore",
            "game-obj": "Skills",
            "aonid": 8
        },
        "value": 14,
        "modifiers": [
            {
                "type": "stat_block_section",
                "subtype": "modifier",
                "name": "any one subcategory"
            }
        ]
    },
    {
        "type": "stat_block_section",
        "subtype": "skill",
        "name": "Occultism",
        "link": {
            "type": "link",
            "name": "Occultism",
            "alt": "Occultism",
            "game-obj": "Skills",
            "aonid": 11
        },
        "value": 16
    }
],

*/