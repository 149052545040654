import React, {useRef, useEffect} from 'react'
import { connect } from 'react-redux'
import * as rollService from '../../services/rolls'
import {
  updateActivityFilter,
  updateGroupRollShortcutCommand,
  updateRollCommand,
} from '../../actions'

import './Activity.css'

import ActivityRoller from './ActivityRoller'
import Roll from '../components/Roll'
import GroupRoll from '../components/GroupRoll'

const Activity = (props) => {
  const {
    characterId,
    characters,
    gameId,
    groupRollShortcutCommand,
    groupRolls,
    groupRollDefaults,
    ownerId,
    rollCommand,
    rollFilter,
    rolls,
    shortcuts,
    variables,
    token,
    updateGroupRollShortcutCommand,
  } = props

  const activityRef = useRef()
  const scrollToBottom = () => (
    activityRef.current.scrollTop = activityRef.current.scrollHeight
  )

  const sendCommand = () => {
    rollService.create({token: token, label: "", gameId: gameId, formula: rollCommand})
  }

  const handleRollCommandChange = (e) => {
    props.updateRollCommand(e.target.value)
  }

  const handleActivityFilterChange = (e) => {
    const val = e.target.value === 'all' ? 'all' : +e.target.value
    props.updateActivityFilter(val)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      sendCommand()
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [rolls, groupRolls])

  let rollValues = Object.values(rolls)
  if (rollFilter !== 'all') {
    rollValues = rollValues.filter(roll => roll.character_id === rollFilter)
  }
  let filteredRollValues = rollValues.filter(roll => roll.group_roll_id === null)

  let groupRollValues = Object.values(groupRolls)
  const inactiveGroupRolls = groupRollValues.filter(groupRoll => groupRoll.active === false)
  const activeGroupRolls = groupRollValues.filter(groupRoll => groupRoll.active === true)

  const activityItems = [...filteredRollValues, ...inactiveGroupRolls]
  activityItems.sort((itemA, itemB) => {
    const itemAdate = new Date(itemA.updated_at)
    const itemBdate = new Date(itemB.updated_at)
    if (itemAdate < itemBdate) return -1
    if (itemAdate > itemBdate) return 1
    return 0
  })

  return(
    <div className='Activity'>

      <div className='Activity__header'>
        <div className='Activity__title lets-roll-text-l'>Activity</div>
        <div className='Activity__filter'>
          <select onChange={handleActivityFilterChange} name="ActivityFilter" id="ActivityFilter">
            <option value="all" default='default'>All</option>
            {Object.values(characters).map((character,i) => {
              const userIsGM = characterId === ownerId
              const optionIsGM = ownerId === character.id
              if (optionIsGM && !userIsGM) {
                return null
              }
              return <option key={i} value={character.id}>{character.name}</option>
            })}
          </select>
        </div>
      </div>
      <div className='Activity__container'>


        <div className='Activity__items' ref={activityRef}>
          {activityItems.map(item => {
            if (item.type === 'roll') {
              if (ownerId !== characterId && ownerId === item.character_id) {
                return null
              } else {

                let name = '???'
                let url = null
                if (characters[item.character_id]) {
                  name = characters[item.character_id].name
                  url = characters[item.character_id].avatar_url
                }

                return (<Roll
                  key={`${item.type}-${item.id}`}
                  roll={item}
                  ownerId={ownerId}
                  characterId={characterId}
                  character={{
                    id: item.character_id,
                    name: name,
                    avatarUrl: url,
                  }}
                />)
              }
            } else if (item.type === 'group_roll') {
              const rolls = rollValues.filter(r => r.group_roll_id === item.id)
              return (
                <GroupRoll
                  key={item.id}
                  {...item}
                  characters={characters}
                  ownerId={ownerId}
                  rolls={rolls}
                  characterId={characterId}
                  gameId={gameId}
                  token={token}
                  groupRollShortcutCommand={groupRollShortcutCommand}
                  updateGroupRollShortcutCommand={updateGroupRollShortcutCommand}
                  shortcuts={shortcuts}
                  variables={variables}
                  groupRollDefaults={groupRollDefaults}
                />
              )
            }
          })}
          {activeGroupRolls.map(groupRoll => {
            const rolls = rollValues.filter(r => r.group_roll_id === groupRoll.id)
            return (
              <GroupRoll
                key={groupRoll.id}
                {...groupRoll}
                characters={characters}
                ownerId={ownerId}
                rolls={rolls}
                characterId={characterId}
                gameId={gameId}
                token={token}
                groupRollShortcutCommand={groupRollShortcutCommand}
                updateGroupRollShortcutCommand={updateGroupRollShortcutCommand}
                shortcuts={shortcuts}
                variables={variables}
                groupRollDefaults={groupRollDefaults}
            />
            )
          })}
        </div>
        <div className='Activity__controls'>
          <ActivityRoller
            handleRollCommandChange={handleRollCommandChange}
            handleKeyDown={handleKeyDown}
            rollCommand={rollCommand}
            sendCommand={sendCommand}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    characterId: state.characterId,
    characters: state.characters.list,
    gameId: state.game.id,
    groupRollShortcutCommand: state.client.groupRollShortcutCommand,
    groupRolls: state.groupRolls.list,
    groupRollDefaults: state.groupRollDefaults,
    ownerId: state.ownerId,
    rollCommand: state.client.rollCommand,
    rollFilter: state.rolls.filter,
    rolls: state.rolls.list,
    shortcuts: state.shortcuts.list,
    variables: state.variables.vars,
    token: state.client.token,
  }
}

const mapDispatchToProps = { updateRollCommand, updateActivityFilter, updateGroupRollShortcutCommand }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Activity)
