import React from 'react'
/*import PropTypes from 'prop-types';
import {
  GEAR
} from '../../../../constants/monster_constants'*/
import Item from './Item';
import { comma } from '../../../../lib/pf2/monster_utils';

const Gear = (props) => {
  const {
    gear
  } = props

  if (!gear) {return null}

  // TODO: handle links
  // TODO: Break out items into their own component
  return (
    <div className='Monster__gear'>
      <strong>Items </strong>
      {gear.map((item, i) => {
        return (
          <Item item={item} i={i} key={i}>{comma(i, gear)}</Item>
        )
      })}
  </div>
  )
}

//Gear.propTypes = PropTypes.shape(GEAR)

export default Gear

/* Gear Examples:

Aasimar Redeemer:

"gear": [
    {
        "type": "stat_block_section",
        "subtype": "item",
        "name": "crossbow",
        "modifiers": [
            {
                "type": "stat_block_section",
                "subtype": "modifier",
                "name": "10 bolts"
            }
        ],
        "links": [
            {
                "type": "link",
                "name": "crossbow",
                "alt": "crossbow",
                "game-obj": "Weapons",
                "aonid": 67
            }
        ]
    },
    {
        "type": "stat_block_section",
        "subtype": "item",
        "name": "half plate",
        "links": [
            {
                "type": "link",
                "name": "half plate",
                "alt": "half plate",
                "game-obj": "Armor",
                "aonid": 12
            }
        ]
    },
    {
        "type": "stat_block_section",
        "subtype": "item",
        "name": "longsword",
        "links": [
            {
                "type": "link",
                "name": "longsword",
                "alt": "longsword",
                "game-obj": "Weapons",
                "aonid": 31
            }
        ]
    },
    {
        "type": "stat_block_section",
        "subtype": "item",
        "name": "steel shield",
        "modifiers": [
            {
                "type": "stat_block_section",
                "subtype": "modifier",
                "name": "Hardness 5"
            },
            {
                "type": "stat_block_section",
                "subtype": "modifier",
                "name": "HP 20"
            },
            {
                "type": "stat_block_section",
                "subtype": "modifier",
                "name": "BT 10"
            }
        ],
        "links": [
            {
                "type": "link",
                "name": "steel shield",
                "alt": "steel shield",
                "game-obj": "Shields",
                "aonid": 3
            }
        ]
    }
],
*/
