import { createStore, applyMiddleware, compose } from 'redux'
import mixpanel from 'mixpanel-browser'
import mixpanelMiddleware from 'redux-mixpanel-middleware'
import thunk from 'redux-thunk'

import { fetchMonsters } from '../actions'
import reducer from '../reducers'

mixpanel.init(MIXPANEL_TOKEN)
mixpanel.identify(INITIAL.user.id)
mixpanel.register({
  game_id: INITIAL.game.id,
})
mixpanel.track("load_game", {name: INITIAL.game.name})

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducer,
    composeEnhancer(applyMiddleware(mixpanelMiddleware(mixpanel), thunk))
)

store.dispatch(fetchMonsters)

export default store
