import mixpanel from 'mixpanel-browser'

import { MP_ROLL } from "../constants/mixpanel_events"

export async function create({token, label, gameId, formula, structuredFormula, groupRollId, characterId}) {
  const payload = {
    label: label,
    game_id: gameId
  }
  formula ? payload.formula = formula : null
  structuredFormula ? payload.structured_formula = structuredFormula : null
  groupRollId ? payload.group_roll_id = groupRollId : null
  characterId ? payload.character_id = characterId : null
  post(payload, token)
    .then(response => response.json())
    .then(data => {
      mixpanel.track(MP_ROLL, {
        original: data.original,
        source: 'shortcut'
      })
    })
}

async function post(values, token) {
  const response = await fetch(baseUrl(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': token
    },
    body: JSON.stringify(values)
  })
  return response
}

function baseUrl() {
  return `${window.location.protocol}//${location.host}/rolls`
}