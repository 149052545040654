import React from 'react'
import defaultPortrait from '../../assets/default_portrait.png'
import defaultPortraitGM from '../../assets/default_portrait_gm'

import './Avatar.css'

const Avatar = ({id, name, ownerId, avatarUrl, kind, initiative, initiativePriority, isOwner}) => {
  let portrait = ownerId === id ? defaultPortraitGM : defaultPortrait

  const renderInitiative = (isOwner, initiative, initiativePriority) => {
    if (isOwner) {
      return (
        <div className='Avatar__initiative Avatar__initiative--gm'>{initiative}-{initiativePriority}</div>
      )
    } else {
      return (
        <div className='Avatar__initiative'>{initiative}</div>
      )
    }
  }

  if (avatarUrl) {
    portrait = avatarUrl
  }

  if (kind === 'party') {
    const initiativeDisplay = renderInitiative(isOwner, initiative, initiativePriority)

    return(
      <div className='Avatar'>
        <img src={portrait} className='Avatar__portrait Avatar__portrait--party' />
        <div className='Avatar__name'>{name}</div>
        {initiativeDisplay}
      </div>
    )
  } else if (kind === 'roll') {
    return <img src={portrait} className='Avatar__portrait Avatar__portrait--roll' />
  }
}

export default Avatar