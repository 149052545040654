import {
  ADD_REGISTRY,
  DESTROY_REGISTRY,
  UPDATE_REGISTRY,
} from '../constants/action-types'
import { convertStateListToObject } from '../lib/utils'

const initialState = {
  list: INITIAL.registries,
  reg: convertStateListToObject(INITIAL.registries, "key"),
}

export default (state = initialState, action) => {
  const registries = Object.values(state.list)
  const initialRegistries = [...registries]

  switch(action.type) {
    case ADD_REGISTRY:
      const registryId = action.payload.id
      const registryData = action.payload
      const characterId = action.characterId

      if (registryData.character_id !== characterId) {
        return state
      }

      initialRegistries.push(registryData)

      const list = {
        ...state.list,
        [registryId]: registryData
      }
      return {
        ...state,
        list: list,
        reg: convertStateListToObject(list, "key"),
      }
    case UPDATE_REGISTRY: {
      const registryId = action.payload.id
      const registryData = action.payload
      const characterId = action.characterId

      if (registryData.character_id !== characterId) {
        return state
      }

      let registries = {...state.list}
      registries[registryId] = registryData

      return {
        ...state,
        list: registries,
        vars: convertStateListToObject(registries, "key"),
      }
    }
    case DESTROY_REGISTRY:
      const { [action.payload.id]: _, ...withoutRegistry } = state.list

      return {
        ...state,
        list: withoutRegistry,
        vars: convertStateListToObject(withoutRegistry, "key"),
      }
    default:
      return state
  }
}
