import React from 'react'
import { connect } from 'react-redux'
import {
  updateMonsterSearch,
  clearMonsterSearch,
} from '../../actions'

import './MonsterSearch.css'

const MonsterSearch = (props) => {
  const {
    label,
    onSelectMonster,
    query,
    monsters,
    searchResults,
  } = props

  const handleQueryChange = e => {
    props.updateMonsterSearch(e.target.value)
  }

  const handleMonsterSelect = monsterId => {
    props.clearMonsterSearch()
    onSelectMonster(monsterId)
  }

  return (
    <div className='MonsterSearch'>
        <label>{label}</label>
        <input type='text' value={query} onChange={handleQueryChange} />

        <div className='MonsterSearch__results'>
          {searchResults.map(monsterId => {
            const monster = monsters[monsterId]
            const selectFn = handleMonsterSelect.bind(null, monsterId)
            return (
              <div
                key={monster.id}
                className='MonsterSearch__result'
                onClick={selectFn}
              >
                {monster.name}
              </div>
            )
          })}
        </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    searchResults: state.monsters.searchResults,
    query: state.monsters.query,
    monsters: state.monsters.list,
  }
}

const mapDispatchToProps = {
  updateMonsterSearch,
  clearMonsterSearch,
}

export default connect(mapStateToProps, mapDispatchToProps)(MonsterSearch)
