import mixpanel from 'mixpanel-browser'

import {
  MP_CREATE_INVITATION,
  MP_DELETE_INVITATION,
} from '../constants/mixpanel_events'

export async function create(email, gameId, token) {
  const response = await fetch(baseUrl(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': token
    },
    body: JSON.stringify({
      email: email,
      game_id: gameId,
    })
  })

  mixpanel.track(MP_CREATE_INVITATION)

  const invitationData = await response.json()
  return invitationData
}


export async function destroy(invitationId, token) {
  const response = await fetch(`${baseUrl()}/${invitationId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': token
    }
  })

  mixpanel.track(MP_DELETE_INVITATION)

  const invitationData = await response.json()
  return invitationData
}

function baseUrl() {
  return `${window.location.protocol}//${location.host}/invitations`
}
