import mixpanel from 'mixpanel-browser'
import {
  MP_PROMOTE_USER,
} from '../constants/mixpanel_events'

export async function promote(userId, gameId, token) {
  const values = {
    user_id: userId
  }

  const response = await fetch(`${baseUrl()}/${gameId}/promote`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': token
    },
    body: JSON.stringify(values)
  })

  mixpanel.track(MP_PROMOTE_USER)
}

export async function reorder(orderedIds, gameId, token) {
  const values = {
    ordered_ids: orderedIds
  }

  const response = await fetch(`${baseUrl()}/${gameId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': token
    },
    body: JSON.stringify(values)
  })

  const gameData = await response.json()
  return gameData
}


function baseUrl() {
  return `${window.location.protocol}//${location.host}/games`
}