import React from 'react'
import Modifiers from './Modifiers';

const InlineAbility = (props) => {
  const {
    ability,
    children
  } = props

  if (!ability) {return null}

  const renderValue = (ability) => {
    if (ability.value) {
      return ` ${ability.value}`
    }
    return ''
  }

  // TODO: handle links
  return (
    <span className='Monster__inlineAbility'>
      {ability.name}
      {renderValue(ability)}
      <Modifiers modifiers={ability.modifiers} />
      {children}
    </span>
  )
}

export default InlineAbility

/* Inline Ability Examples

Fungus Leshy:

"communication_abilities": [
    {
        "type": "stat_block_section",
        "subtype": "ability",
        "name": "speak with plants",
        "ability_type": "communication",
        "links": [
            {
                "type": "link",
                "name": "speak with plants",
                "alt": "speak with plants",
                "game-obj": "Spells",
                "aonid": 294
            }
        ],
        "modifiers": [
            {
                "type": "stat_block_section",
                "subtype": "modifier",
                "name": "fungi only"
            }
        ]
    }
]

Jabberwock:

"automatic_abilities": [
    {
        "type": "stat_block_section",
        "subtype": "ability",
        "name": "regeneration",
        "ability_type": "automatic",
        "modifiers": [
            {
                "type": "stat_block_section",
                "subtype": "modifier",
                "name": "deactivated by vorpal weapons",
                "links": [
                    {
                        "type": "link",
                        "name": "vorpal",
                        "alt": "vorpal",
                        "game-obj": "Equipment",
                        "aonid": 308
                    }
                ]
            }
        ],
        "value": 25
    }
],

*/