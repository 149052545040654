import {
  ADD_ENCOUNTER,
  DESTROY_ENCOUNTER,
  SELECT_ENCOUNTER,
  UPDATE_ENCOUNTER,
  DESTROY_ENCOUNTER_MONSTER,
} from "../constants/action-types"

const initialState = {
  list: INITIAL.encounters,
  selectedEncounterId: null,
}

export default (state = initialState, action) => {
  let encounterId
  let encounterData
  let selectedEncounterId

  if (action.payload) {
    encounterId = action.payload.id
    encounterData = action.payload
    selectedEncounterId = action.payload.selectedEncounterId
  }

  switch(action.type) {
    case DESTROY_ENCOUNTER_MONSTER:
      const encounterDataCopy = JSON.parse(JSON.stringify(state.list[action.payload.encounter_id]))

      const index = encounterDataCopy.encounter_monster_ids.indexOf(action.payload.id)
      if (index > -1) {
        encounterDataCopy.encounter_monster_ids.splice(index, 1)
      }

      return {
        ...state,
        list: {
          ...state.list,
          [encounterDataCopy.id]: {...encounterDataCopy}
        }
      }
    case SELECT_ENCOUNTER:
      return {
        ...state,
        selectedEncounterId: selectedEncounterId
      }
    case ADD_ENCOUNTER:
      return {
        ...state,
        list: {
          ...state.list,
          [encounterId]: encounterData
        }
      }
    case UPDATE_ENCOUNTER:
      return {
        ...state,
        list: {
          ...state.list,
          [encounterId]: encounterData
        }
      }
    case DESTROY_ENCOUNTER:
      const { [action.payload.id]: _, ...withoutEncounter } = state.list;
      return {
        ...state,
        list: withoutEncounter,
      }
    default:
      return state
  }
}
