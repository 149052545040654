import React from 'react'
import PropTypes from 'prop-types';
import {
  SPEEDS
} from '../../../../constants/monster_constants'


const Speed = (props) => {
  const {
    speed
  } = props

  if (!speed || !speed.movement) {return null}

  //TODO: Handle links
  return (
    <div className='Monster__speed'>
      <strong>Speed{' '}</strong>
      {
        speed.movement.map((m,i) =>
          <span key={i}>
            {m.name}
            {typeof m.modifiers === 'undefined'
              ? null
              : <span>{' '}({
                m.modifiers.map((m,i) => m.name).join(', ')
              })</span>
            }
            {i < speed.movement.length - 1 ? ", " : ""}
          </span>
        )
      }
      {typeof speed.modifiers === 'undefined'
        ? null
        : <span>;{' '}{
          speed.modifiers.map((m,i) => m.name).join(', ')
        }</span>
      }
    </div>
  )
}

/*Speed.propTypes = {
  speed: PropTypes.shape(SPEEDS)
};*/

export default Speed

/*
"speeds": {
  "type": "stat_block_section",
  "subtype": "speeds",
  "name": "Speed",
  "movement": [
    {
      "type": "stat_block_section",
      "subtype": "speed",
      "name": "fly 40 feet",
      "movement_type": "fly",
      "value": 40
    },
    {
      "type": "stat_block_section",
      "subtype": "speed",
      "name": "burrow 20 feet",
      "modifiers": [
        {
          "type": "stat_block_section",
          "subtype": "modifier",
          "name": "sand only"
        }
      ],
      "movement_type": "burrow",
      "value": 20
    }
  ],
  "modifiers": [
    {
      "type": "stat_block_section",
      "subtype": "modifier",
      "name": "freedom of movement",
      "links": [
        {
          "type": "link",
          "name": "freedom of movement",
          "alt": "freedom of movement",
          "game-obj": "Spells",
          "aonid": 128
        }
      ]
    }
  ]
},
*/
