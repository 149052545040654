const initialState = {
  sort: 'created',
  orderedIds: Object.keys(INITIAL.shortcuts),
  editShortcutOpen: null,
  list: INITIAL.shortcuts
}

import {
  OPEN_EDIT_SHORTCUT,
  CLOSE_EDIT_SHORTCUT,
  ADD_SHORTCUT,
  DESTROY_SHORTCUT,
  UPDATE_SHORTCUT,
  SET_SHORTCUT_SORT_ORDER,
} from '../constants/action-types'

export default (state = initialState, action) => {
  const shortcuts = Object.values(state.list)
  const initialShortcuts = [...shortcuts]
  let sortedShortcuts
  let newOrderedIds

  switch(action.type) {
    case OPEN_EDIT_SHORTCUT:
      return {
        ...state,
        editShortcutOpen: action.payload
      }
    case CLOSE_EDIT_SHORTCUT:
      return {
        ...state,
        editShortcutOpen: action.payload
      }
    case SET_SHORTCUT_SORT_ORDER:
      const newSort = action.payload.sort

      sortedShortcuts = sortShortcuts(initialShortcuts, newSort)
      newOrderedIds = sortedShortcuts.map((a) => {return `${a.id}`})

      return {
          ...state,
          sort: newSort,
          orderedIds: newOrderedIds
      }
    case ADD_SHORTCUT:
      const shortcutId = action.payload.id
      const shortcutData = action.payload

      if (shortcutData.currentCharacterId !== shortcutData.character_id) {
        return state
      }

      initialShortcuts.push(shortcutData)
      sortedShortcuts = sortShortcuts(initialShortcuts, state.sort)
      newOrderedIds = sortedShortcuts.map((a) => {return `${a.id}`})

      return {
        ...state,
        list: {
          ...state.list,
          [shortcutId]: shortcutData
        },
        orderedIds: newOrderedIds,
      }
    case UPDATE_SHORTCUT: {
      const shortcutId = action.payload.id
      const shortcutData = action.payload

      if (shortcutData.currentCharacterId !== shortcutData.character_id) {
        return state
      }

      let shortcuts = {...state.list}
      shortcuts[shortcutId] = shortcutData
      sortedShortcuts = sortShortcuts(Object.values(shortcuts), state.sort)
      newOrderedIds = sortedShortcuts.map((a) => {return `${a.id}`})

      return {
        ...state,
        list: shortcuts,
        orderedIds: newOrderedIds
      }
    }
    case DESTROY_SHORTCUT:
      const { [action.payload.id]: _, ...withoutShortcut } = state.list
      sortedShortcuts = sortShortcuts(Object.values(withoutShortcut), state.sort)
      newOrderedIds = sortedShortcuts.map((a) => {return `${a.id}`})

      return {
        ...state,
        list: withoutShortcut,
        orderedIds: newOrderedIds
      }
    default:
      return state
  }
}

const sortShortcuts = (shortcutList, sort) => {
  let shortcutListCopy = [...shortcutList]
  if (sort === 'alpha') {
    shortcutListCopy.sort((a, b) => {
      return (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : -1
    })
  } else if (sort === 'created') {
    shortcutListCopy.sort((a, b) => {
      return (a.id > b.id) ? 1 : -1
    })
  }

  return shortcutListCopy
}
