import React from 'react'
import Modifiers from './Modifiers';

const Language = (props) => {
  const {
    language,
    i,
    children
  } = props

  if (!language) {return null}

  // TODO: handle links
  return (
    <span className='Monster__language' key={i}>
      {language.name}<Modifiers modifiers={language.modifiers} />{children}
    </span>
  )
}

export default Language

/* Language Examples
Mari Lwyd:

"languages": [
    {
        "name": "Common",
        "type": "stat_block_section",
        "subtype": "language",
        "link": {
            "type": "link",
            "name": "Common",
            "alt": "Common",
            "game-obj": "Languages",
            "aonid": 1
        }
    },
    {
        "name": "Sylvan",
        "type": "stat_block_section",
        "subtype": "language",
        "link": {
            "type": "link",
            "name": "Sylvan",
            "alt": "Sylvan",
            "game-obj": "Languages",
            "aonid": 10
        }
    }
]


Soulbound Doll:

"languages": [
    {
        "name": "one spoken by its creator",
        "type": "stat_block_section",
        "subtype": "language",
        "modifiers": [
            {
                "type": "stat_block_section",
                "subtype": "modifier",
                "name": "typically Common"
            }
        ]
    }
]
*/