import mixpanel from 'mixpanel-browser'
import {
  MP_SEND_FEEDBACK,
} from '../constants/mixpanel_events'

export async function send(characterId, email, message, labels, token) {
  const response = await fetch(baseUrl(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': token
    },
    body: JSON.stringify({
      character_id: characterId,
      email: email,
      message: message,
      labels: labels,
    })
  })

  mixpanel.track(MP_SEND_FEEDBACK)

  const feedbackData = await response.json()
  return feedbackData
}

function baseUrl() {
  return `${window.location.protocol}//${location.host}/feedback`
}
