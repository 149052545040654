import React from 'react'
import { connect } from 'react-redux'
import { updateNewInvitation } from '../../actions'
import * as invitationService from '../../services/invitations'
import * as characterService from '../../services/characters'
import * as gameService from '../../services/game'

import './Players.css'

const Players = (props) => {
  const {
    characters,
    gameId,
    invitations,
    newInvitation,
    token,
    updateNewInvitation,
  } = props

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleNewInvitationCreate()
    }
  }

  const handleNewInvitationChange = (e) => {
    updateNewInvitation(e.target.value)
  }

  const handleNewInvitationCreate = () => {
    invitationService.create(newInvitation, gameId, token)
    updateNewInvitation('')
  }

  const handleNewInvitationDestroy = (invitationId) => {
    invitationService.destroy(invitationId, token)
  }

  const handleDeleteCharacter = (characterId, characterName) => {
    let msg = `Are you sure you want to delete ${characterName} FOREVER?\n\n`
    msg += 'This means:\n'
    msg += '  All shortcuts deleted!\n'
    msg += '  All roll history deleted!\n'
    msg += '  They will have to create a new character to rejoin this game!\n'

    if (window.confirm(msg)) {
      characterService.destroy(characterId, token)
    }
  }

  const handleChangeDM = (userId, characterName) => {
    let msg = `Are you sure you want to make ${characterName} the GM?\n\n`
    if (window.confirm(msg)) {
      gameService.promote(userId, gameId, token)
    }
  }

  const invitationValues = Object.values(invitations)
  const characterValues = Object.values(characters)

  return (
    <div className='Players'>
      <div className='lets-roll-section'>Players</div>

      {characterValues.map(char => {
        const deleteFn = handleDeleteCharacter.bind(null, char.id, char.name)
        const makeGmFn = handleChangeDM.bind(null, char.user_id, char.name)

        return (
          <div key={char.id} className='Players__player'>
            <span className='Players__name'>{char.name}</span>
            <span className='Players__assignGm'><button onClick={makeGmFn}>Assign as GM</button></span>
            <span className='Players__delete'><button onClick={deleteFn}>🗑</button></span>
          </div>
        )
      })}

      <div className='lets-roll-subSection'>Invitations</div>
      <p>Invite a new player to the game by entering their email address
        and they will be able to join the game and create a character</p>
      <br />
      <div className='Players__newInvitation'>
        <label className='Players__newInvitationLabel'>Email</label>
        <input type='text'
          className='Players__newInvitation'
          value={newInvitation}
          onChange={handleNewInvitationChange}
          onKeyDown={handleKeyDown}
        />
        <button onClick={handleNewInvitationCreate}>Send Invite</button>

        <div className='lets-roll-subSection'>Pending Invitations</div>
        {invitationValues.map((invite, i) => {
          const destroyFn = handleNewInvitationDestroy.bind(null, invite.id)
          return (
            <div key={i} className='Players__invitation'>
              {invite.email}
              <button onClick={destroyFn}>🗑</button>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    gameId: state.game.id,
    invitations: state.invitations.list,
    newInvitation: state.client.newInvitation,
    token: state.client.token,
    characters: state.characters.list
  }
}

const mapDispatchToProps = { updateNewInvitation }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Players)
