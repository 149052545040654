import React from 'react'
import { connect } from 'react-redux'
import * as characterService from '../../services/characters'
import Modal from '../components/Modal'
import './Settings.css'

import {
  closeSettings,
  updateNewCharacterName,
  updateNewCharacterAvatarUrl,
} from '../../actions'

const Settings = (props) => {
  const {
    newCharacterName,
    newCharacterAvatarUrl,
    characterId,
    token,
  } = props

  const handleClose = () => {
    props.closeSettings()
  }

  const handleNewCharacterNameChange = (e) => {
    props.updateNewCharacterName(e.target.value)
  }

  const handleCharacterNameSave = () => {
    characterService.updateName(newCharacterName, characterId, token)
  }

  const handleNewCharacterAvatarUrlChange = (e) => {
    props.updateNewCharacterAvatarUrl(e.target.value)
  }

  const handleCharacterAvatarUrlSave = () => {
    characterService.updateAvatarUrl(newCharacterAvatarUrl, characterId, token)
  }

  async function handleCharacterDelete() {
    let msg = 'Are you sure you want to delete your character FOREVER?\n\n'
    msg += 'This means:\n'
    msg += '  All shortcuts deleted!\n'
    msg += '  All roll history deleted!\n'
    msg += '  You will have to create a new character to rejoin this game!\n'

    if (window.confirm(msg)) {
      characterService.destroy(characterId, token)
    }
  }


  return(
    <Modal title='Settings' onClose={handleClose}>
      <SettingsControl
        displayName='Character Name'
        value={newCharacterName}
        onChangeFn={handleNewCharacterNameChange}
        onSaveFn={handleCharacterNameSave}
      />

      <SettingsControl
        displayName='Avatar URL'
        value={newCharacterAvatarUrl}
        onChangeFn={handleNewCharacterAvatarUrlChange}
        onSaveFn={handleCharacterAvatarUrlSave}
      />

      <div className='Settings__control'>
        <button
          className='Settings__button--warning'
          onClick={handleCharacterDelete}
        >Delete my character for-evar!</button>
      </div>
    </Modal>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    characters: state.characters.list,
    characterId: state.characterId,
    newCharacterName: state.client.newCharacterName,
    newCharacterAvatarUrl: state.client.newCharacterAvatarUrl,
    token: state.client.token,
    ownerId: state.ownerId,
  }
}

const mapDispatchToProps = {
  closeSettings,
  updateNewCharacterName,
  updateNewCharacterAvatarUrl,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings)

const SettingsControl = ({displayName, value, onChangeFn, onSaveFn}) => {

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      onSaveFn()
    }
  }

  return(
    <div className='Settings__control'>
      <label className='Settings__label'>{displayName}</label>
      <input type='text'
        className='Settings__input'
        value={value}
        onChange={onChangeFn}
        onKeyDown={handleKeyDown}
      />
      <button className='Settings__button' onClick={onSaveFn}>Save</button>
    </div>
  )
}
