import React from 'react'
import Range from './Range';
import Modifiers from './Modifiers';

const SpecialSense = (props) => {
  const {
    specialSense,
    i,
    children
  } = props

  if (!specialSense) {return null}

  // TODO: handle link
  return (
    <span className='Monster__inlineAbility' key={i}>
      {specialSense.name}<Modifiers modifiers={specialSense.modifiers} /><Range range={specialSense.range}/>{children}
    </span>
  )
}

export default SpecialSense

/* Special Sense Examples

Mari Lwyd:

"special_senses": [
    {
        "type": "stat_block_section",
        "subtype": "special_sense",
        "link": {
            "type": "link",
            "name": "darkvision",
            "alt": "darkvision",
            "game-obj": "MonsterAbilities",
            "aonid": 12
        },
        "name": "darkvision"
    }
]

Adult Black Dragon:

"special_senses": [
    {
        "type": "stat_block_section",
        "subtype": "special_sense",
        "name": "darkvision"
    },
    {
        "type": "stat_block_section",
        "subtype": "special_sense",
        "range": {
            "type": "stat_block_section",
            "subtype": "range",
            "range": 60,
            "text": "60 feet",
            "unit": "feet"
        },
        "modifiers": [
            {
                "type": "stat_block_section",
                "subtype": "modifier",
                "name": "imprecise"
            }
        ],
        "name": "scent"
    }
]

*/
