import React from 'react'
import Modifiers from './Modifiers';

const AC = (props) => {
  const {
    ac
  } = props

  if (!ac) {return null}

  return (
    <span className='Monster__ac'>
      <strong>AC </strong>
      {ac.value}<Modifiers modifiers={ac.modifiers} />
    </span>
  )
}

export default AC

/* AC Example
Aasimar Redeemer:

"ac": {
    "type": "stat_block_section",
    "subtype": "armor_class",
    "value": 23,
    "modifiers": [
        {
            "type": "stat_block_section",
            "subtype": "modifier",
            "name": "25 with shield raised"
        }
    ]
},

*/
