import React from 'react'
import { Provider } from 'react-redux'
import Game from './containers/Game'
import store from '../store'
import * as ws from '../lib/websocket'

ws.connect()

console.log('websocket connected', ws)

const GameRoot = props => {
  console.log('INITIAL', INITIAL)

  return (
    <Provider store={store}>
      <Game />
    </Provider>
  )
}

export default GameRoot
