import mixpanel from 'mixpanel-browser'
import {
  MP_UPDATE_ENCOUNTER,
} from '../constants/mixpanel_events'

export async function create(encounterId, monsterId, quantity, token) {
  const payload = {
    encounter_id: encounterId,
    monster_id: monsterId,
    quantity: quantity,
  }

  post(payload, token)
    .then(response => response.json())
    .then(data => {
      // mixpanel.track(MP_UPDATE_ENCOUNTER, {
      //   original: data.original
      // })
    })
}

export async function destroy(encounterMonsterId, token) {
  const response = await fetch(`${baseUrl()}/${encounterMonsterId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': token
    }
  })
  // mixpanel.track(MP_DELETE_ENCOUNTER)

  const encounterMonsterData = await response.json()
  return encounterMonsterData
}

export async function update(encounterMonsterId, token, payload) {
  put(encounterMonsterId, payload, token)
    .then(response => response.json())
    .then(data => {
      // mixpanel.track(MP_UPDATE_ENCOUNTER, {
      //   original: data.original
      // })
    })
}

async function post(values, token) {
  const response = await fetch(baseUrl(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': token
    },
    body: JSON.stringify(values)
  })
  return response
}

async function put(encounterMonsterId, values, token) {
  const response = await fetch(`${baseUrl()}/${encounterMonsterId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': token
    },
    body: JSON.stringify(values)
  })
  return response
}

function baseUrl() {
  return `${window.location.protocol}//${location.host}/encounter_monsters`
}
