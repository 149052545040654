import React, { useState } from 'react'
import Avatar from 'react-avatar-edit'
import CancelIcon from '@material-ui/icons/Cancel'
import { connect } from 'react-redux'
import * as avatarService from '../../services/avatars'
import * as characterService from '../../services/characters'

import './AvatarSelection.css'

import {
  closeAddAvatar,
} from '../../actions'

const AvatarSelection = (props) => {
  const {
    characterId,
    token,
  } = props

  const [preview, setPreview] = useState(null)
  const [name, setName] = useState("")

  const onClose = () => {
    setPreview(null)
  }

  const onCrop = (p) => {
    setPreview(p)
  }

  const handleClose = () => {
    props.closeAddAvatar()
  }

  const onSave = () => {
    avatarService.saveAvatar(preview, name, characterId, token)
    characterService.updateAvatarUrl(preview, characterId, token)
    props.closeAddAvatar()
  }

  const handleNameChange = (e) => {
    setName(e.target.value)
  }

  return(
    <div className='AvatarSelection'>
      <div className='AvatarSelection__container'>
        <div className='AvatarSelection__controls'>
          <h1 className='AvatarSelection__title'>Add New Avatar</h1>
          <CancelIcon
            className='AvatarSelection__closeIcon'
            fontSize="large"
            style={{ color: '#eee', cursor: 'pointer' }}
            onClick={handleClose}
          />
        </div>

        <div className='AvatarSelection__content'>
          <div className='AvatarSelection__editor'>
            <Avatar
              width={390}
              height={295}
              onCrop={onCrop}
              onClose={onClose}
              exportSize={102}
              exportAsSquare={true}
              className='AvatarSelection__original'
            />
            { preview ? <div className='AvatarSelection__preview'><img height="102" width="102" src={preview} alt="Preview" /></div> : null }
          </div>

          <input type='text'
            name='name'
            value={name}
            onChange={handleNameChange}
            className='AvatarSelection__input'
            placeholder='Avatar Name'
          />
          <button onClick={onSave}>Save</button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    characterId: state.characterId,
    token: state.client.token,
  }
}

const mapDispatchToProps = {
  closeAddAvatar,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AvatarSelection)
