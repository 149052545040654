import {
  ADD_SHORTCUT,
  CLOSE_DASHBOARD,
  CLOSE_FEEDBACK,
  CLOSE_RELEASE_NOTES,
  CLOSE_SETTINGS,
  OPEN_DASHBOARD,
  OPEN_FEEDBACK,
  OPEN_RELEASE_NOTES,
  OPEN_SETTINGS,
  SWITCH_PANEL,
  UPDATE_ENCOUNTER_NAME,
  UPDATE_GAME,
  UPDATE_GROUP_ROLL_NAME,
  UPDATE_GROUP_ROLL_SHORTCUT_COMMAND,
  UPDATE_NEW_CHARACTER_AVATAR_URL,
  UPDATE_NEW_CHARACTER_NAME,
  UPDATE_NEW_ENCOUNTER_NAME,
  UPDATE_NEW_INVITATION,
  UPDATE_NEW_SHORTCUT_COMMAND,
  UPDATE_NEW_SHORTCUT_LABEL,
  UPDATE_ROLL_COMMAND,
} from "../constants/action-types"

const initialState = {
  currentPanel: 'Shortcuts',
  dashboardOpen: false,
  feedbackOpen: false,
  groupRollName: '',
  groupRollShortcutCommand: '',
  newCharacterAvatarUrl: INITIAL.characters[INITIAL.characterId].avatar_url || '',
  newCharacterName: INITIAL.characters[INITIAL.characterId].name,
  newEncounterName: '',
  newInvitation: '',
  releaseNotes: INITIAL.releaseNotes,
  releaseNotesOpen: false,
  rollCommand: '',
  settingsOpen: false,
  shortcutCommand: '',
  shortcutLabel: '',
  token: INITIAL.token,
  updatedEncounterName: '',
}

export default (state = initialState, action) => {
  switch(action.type) {
    case OPEN_DASHBOARD:
      return {
        ...state,
        dashboardOpen: action.payload
      }
    case CLOSE_DASHBOARD:
      return {
        ...state,
        dashboardOpen: action.payload
      }
    case UPDATE_ROLL_COMMAND:
      return {
        ...state,
        rollCommand: action.payload.rollCommand
      }
    case UPDATE_NEW_SHORTCUT_LABEL:
      return {
        ...state,
        shortcutLabel: action.payload.newShortcutLabel
      }
    case UPDATE_NEW_SHORTCUT_COMMAND:
      return {
        ...state,
        shortcutCommand: action.payload.newShortcutCommand
      }
    case UPDATE_NEW_CHARACTER_NAME:
      return {
        ...state,
        newCharacterName: action.payload.newCharacterName
      }
    case UPDATE_NEW_CHARACTER_AVATAR_URL:
      return {
        ...state,
        newCharacterAvatarUrl: action.payload.newCharacterAvatarUrl
      }
    case UPDATE_NEW_ENCOUNTER_NAME:
      return {
        ...state,
        newEncounterName: action.payload.newEncounterName
      }
    case ADD_SHORTCUT:
      return {
        ...state,
        shortcutLabel: '',
        shortcutCommand: '',
      }
    case OPEN_SETTINGS:
      return {
        ...state,
        settingsOpen: action.payload
      }
    case CLOSE_SETTINGS:
      return {
        ...state,
        settingsOpen: action.payload
      }
    case OPEN_FEEDBACK:
      return {
        ...state,
        feedbackOpen: action.payload
      }
    case CLOSE_FEEDBACK:
      return {
        ...state,
        feedbackOpen: action.payload
      }
    case OPEN_RELEASE_NOTES:
      return {
        ...state,
        releaseNotesOpen: action.payload
      }
    case CLOSE_RELEASE_NOTES:
      return {
        ...state,
        releaseNotesOpen: action.payload
      }
    case SWITCH_PANEL:
      return {
        ...state,
        currentPanel: action.payload
      }
    case UPDATE_GROUP_ROLL_NAME:
      return {
        ...state,
        groupRollName: action.payload
      }
    case UPDATE_GROUP_ROLL_SHORTCUT_COMMAND:
      return {
        ...state,
        groupRollShortcutCommand: action.payload
      }
    case UPDATE_NEW_INVITATION:
      return {
        ...state,
        newInvitation: action.payload
      }
    case UPDATE_GAME:
      return {
        ...state,
        currentPanel: 'Shortcuts'
      }
    case UPDATE_ENCOUNTER_NAME:
      return {
        ...state,
        updatedEncounterName: action.payload
      }
    default:
      return state
  }
}