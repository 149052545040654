import mixpanel from 'mixpanel-browser'
import {
  MP_CREATE_ENCOUNTER,
  MP_UPDATE_ENCOUNTER,
  MP_DELETE_ENCOUNTER,
} from '../constants/mixpanel_events'

export async function create(name, gameId, token) {
  const payload = {
    name: name,
    game_id: gameId
  }

  post(payload, token)
    .then(response => response.json())
    .then(data => {
      mixpanel.track(MP_CREATE_ENCOUNTER, {
        original: data.original
      })
    })
}

export async function destroy(encounterId, token) {
  const response = await fetch(`${baseUrl()}/${encounterId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': token
    }
  })
  mixpanel.track(MP_DELETE_ENCOUNTER)

  const encounterData = await response.json()
  return encounterData
}

export async function update(encounterId, token, payload) {
  put(encounterId, payload, token)
    .then(response => response.json())
    .then(data => {
      mixpanel.track(MP_UPDATE_ENCOUNTER, {
        original: data.original
      })
    })
}

async function post(values, token) {
  const response = await fetch(baseUrl(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': token
    },
    body: JSON.stringify(values)
  })
  return response
}

async function put(encounterId, values, token) {
  const response = await fetch(`${baseUrl()}/${encounterId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': token
    },
    body: JSON.stringify(values)
  })
  return response
}

function baseUrl() {
  return `${window.location.protocol}//${location.host}/encounters`
}
