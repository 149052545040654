import { ADD_ROLL, FILTER_ROLLS } from "../constants/action-types"
import * as soundService from '../services/sound'

const initialState = {
  filter: 'all',
  list: INITIAL.rolls
}

export default (state = initialState, action) => {
  switch(action.type) {
    case ADD_ROLL:
      const rollId = action.payload.id
      const rollData = action.payload

      const isGM = rollData.characterId === rollData.ownerId

      if (rollData.character_id === rollData.ownerId && !isGM) {
        return state
      }

      soundService.diceRoll()

      if (hasFumble(rollData)) {
        window.setTimeout(
          () => {soundService.fumble()},
          1000
        )
      }

      if (hasCritical(rollData)) {
        window.setTimeout(
          () => {soundService.critical()},
          1000
        )
      }

      return {
        ...state,
        list: {
          ...state.list,
          [rollId]: rollData
        }
      }
    case FILTER_ROLLS:
      return {
        ...state,
        filter: action.payload
      }
    default:
      return state
  }
}

const hasFumble = (rollData) => {
  for (let i = 0; i < rollData.structured_results.length; i++) {
    let result = rollData.structured_results[i]
    if (result.fumble === true) {
      return true
    }
  }
  return false
}

const hasCritical = (rollData) => {
  for (let i = 0; i < rollData.structured_results.length; i++) {
    let result = rollData.structured_results[i]
    if (result.critical === true) {
      return true
    }
  }
  return false
}