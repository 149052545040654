import React from 'react'
/*import PropTypes from 'prop-types';
import {
  GEAR
} from '../../../../constants/monster_constants'*/
import Modifiers from './Modifiers';

const Item = (props) => {
  const {
    item,
    i,
    children
  } = props

  if (!item) { return null }

  // TODO: handle links
  return (
    <span className='Monster__item'>{item.name}<Modifiers modifiers={item.modifiers} />{children}</span>
  )
}

export default Item

/* Item Examples:

Aasimar Redeemer:

{
    "type": "stat_block_section",
    "subtype": "item",
    "name": "steel shield",
    "modifiers": [
        {
            "type": "stat_block_section",
            "subtype": "modifier",
            "name": "Hardness 5"
        },
        {
            "type": "stat_block_section",
            "subtype": "modifier",
            "name": "HP 20"
        },
        {
            "type": "stat_block_section",
            "subtype": "modifier",
            "name": "BT 10"
        }
    ],
    "links": [
        {
            "type": "link",
            "name": "steel shield",
            "alt": "steel shield",
            "game-obj": "Shields",
            "aonid": 3
        }
    ]
}
*/
