import store from '../store'
import {
  addAvatar,
  addCharacter,
  addEncounter,
  addEncounterMonster,
  addGroupRoll,
  addInvitation,
  addRegistry,
  addRoll,
  addShortcut,
  addVariable,
  destroyAvatar,
  destroyCharacter,
  destroyEncounter,
  destroyEncounterMonster,
  destroyGroupRoll,
  destroyInvitation,
  destroyRegistry,
  destroyShortcut,
  destroyVariable,
  updateCharacter,
  updateEncounter,
  updateEncounterMonster,
  updateGroupRoll,
  updateRegistry,
  updateShortcut,
  updateVariable,
  updateGame,
  reorderGame,
  reorderInitiativeQueueItems,
} from '../actions'

export function connect() {
  const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws'
  const ws = new WebSocket(`${protocol}://${location.host}/cable`)

  ws.onopen = () => {
    const config = {
      command: 'subscribe',
      identifier: JSON.stringify({
        channel: 'GameChannel',
        game: store.getState().game.id,
      }),
    }
    ws.send(JSON.stringify(config))
  }

  ws.onclose = e => {
    console.log(
      'Socket is closed. Reconnect will be attempted in 1 second.',
      e.reason,
    )
    setTimeout(() => {
      connect()
    }, 1000)
  }

  ws.onmessage = event => {
    const data = JSON.parse(event.data)
    if (data.identifier) {
      const { channel, game } = JSON.parse(data.identifier)
      console.log('🕷🕸', { channel, game, data })

      if (
        channel == 'GameChannel' &&
        game === store.getState().game.id &&
        data.message
      ) {
        store.dispatch(
          handlers[`${data.message.action}-${data.message.type}`](
            data.message.model,
          ),
        )
      }
    }
  }

  const handlers = {
    'create-avatar': addAvatar,
    'create-character': addCharacter,
    'create-encounter': addEncounter,
    'create-encounter_monster': addEncounterMonster,
    'create-group_roll': addGroupRoll,
    'create-invitation': addInvitation,
    'create-registry': addRegistry,
    'create-roll': addRoll,
    'create-shortcut': addShortcut,
    'create-variable': addVariable,
    'destroy-avatar': destroyAvatar,
    'destroy-character': destroyCharacter,
    'destroy-encounter': destroyEncounter,
    'destroy-encounter_monster': destroyEncounterMonster,
    'destroy-group_roll': destroyGroupRoll,
    'destroy-invitation': destroyInvitation,
    'destroy-registry': destroyRegistry,
    'destroy-shortcut': destroyShortcut,
    'destroy-variable': destroyVariable,
    'update-character': updateCharacter,
    'update-group_roll': updateGroupRoll,
    'update-encounter': updateEncounter,
    'update-encounter_monster': updateEncounterMonster,
    'update-game': updateGame,
    'update-registry': updateRegistry,
    'update-shortcut': updateShortcut,
    'update-variable': updateVariable,
    'reorder-game': reorderGame,
    'reorder-initiative_queue_items': reorderInitiativeQueueItems,
  }
}
