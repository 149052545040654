import mixpanel from 'mixpanel-browser'
import {
  MP_CREATE_REGISTRY,
  MP_UPDATE_REGISTRY,
  MP_DELETE_REGISTRY,
} from '../constants/mixpanel_events'

export const REG_SHORTCUT_SORT = 'shortcut.sort'
export const REG_TEST = 'test'

export function unpackRegistry(reg) {
  return {
    ...reg,
    getEntry: function (key) {
      if (!this.hasOwnProperty(key)) {
        this[key] = {
          "key": key,
          entry: {}
        }
      }
      return this[key]
    },
    writeEntry: function (entry, characterId, token) {
      if ("id" in entry) {
        update(entry, entry.id, characterId, token)
      } else {
        entry.character_id = characterId
        save(entry, characterId, token)
      }
    }
  }
}

export async function save(values, characterId, token) {
  const response = await fetch(`${baseUrl()}/${characterId}/registries`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': token
    },
    body: JSON.stringify(values)
  })
  mixpanel.track(MP_CREATE_REGISTRY, values)
  const registryData = await response.json()
  return registryData
}

export async function update(values, registryId, characterId, token) {
  const response = await fetch(`${baseUrl()}/${characterId}/registries/${registryId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': token
    },
    body: JSON.stringify(values)
  })

  mixpanel.track(MP_UPDATE_REGISTRY, values)
  const registryData = await response.json()
  return registryData
}

export async function destroy(registryId, characterId, token) {
  const response = await fetch(`${baseUrl()}/${characterId}/registries/${registryId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': token
    }
  })

  mixpanel.track(MP_DELETE_REGISTRY)
  const registryData = await response.json()
  return registryData
}

function baseUrl() {
  return `${window.location.protocol}//${location.host}/characters`
}
