import mixpanel from 'mixpanel-browser'
import {
  MP_CHANGE_AVATAR,
  MP_CHANGE_CHARACTER_NAME,
  MP_DELETE_CHARACTER,
} from '../constants/mixpanel_events'

export async function updateAvatarUrl(newCharacterAvatarUrl, characterId, token) {
  let values = {
    character: {
      avatar_url: newCharacterAvatarUrl,
    },
  }
  update(values, characterId, token)
  mixpanel.track(MP_CHANGE_AVATAR)
}

export async function updateName(newCharacterName, characterId, token) {
  let values = {
    character: {
      name: newCharacterName,
    },
  }
  update(values, characterId, token)
  mixpanel.track(MP_CHANGE_CHARACTER_NAME, {
    name: newCharacterName
  })
}

export async function updateInitiative(newInitiative, newInitiativePriority, characterId, token) {
  let values = {
    character: {
      initiative: newInitiative,
      initiative_priority: newInitiativePriority,
    },
  }
  update(values, characterId, token)
}

export async function update(values, characterId, token) {
  const response = await fetch(`${baseUrl()}/${characterId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': token
    },
    body: JSON.stringify(values)
  })
  const characterData = await response.json()
  return characterData
}

export async function destroy(characterId, token) {
  const response = await fetch(`${baseUrl()}/${characterId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': token
    }
  })
  mixpanel.track(MP_DELETE_CHARACTER)
}

function baseUrl() {
  return `${window.location.protocol}//${location.host}/characters`
}