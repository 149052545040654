import React from 'react'
import { connect } from 'react-redux'

import './Dashboard.css'

import { } from '../../actions'
import store from '../../store'


const Dashboard = (props) => {
  const {
  } = props


  return (
    <div className='Dashboard'>
      Dashboard
    </div>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {

  }
}

const mapDispatchToProps = {  }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)
