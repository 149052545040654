import React, { useState } from 'react'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { connect } from 'react-redux'
import * as shortcutService from '../../services/shortcuts'
import * as dice from '../../lib/dice'
import Modal from '../components/Modal'
import './ShortcutEdit.css'

import {
  closeEditShortcut,
} from '../../actions'

const getStructuredFormulaFromShortcut = (shortcut) => {
  if(shortcut.structured_formula) {
    let structured_formula = shortcut.structured_formula
    if(!structured_formula.max_id) {
      for (var i = 0; i < structured_formula.formulas.length; i++) {
        structured_formula.formulas[i].unique_id = i
      }
      structured_formula.max_id = structured_formula.formulas.length-1
    }
    return shortcut.structured_formula
  }
  return  {
    name: shortcut.label,
    max_id: 0,
    formulas: [
      {
        unique_id: 0,
        formula: shortcut.value,
      }
    ]
  }
}

const ShortcutEdit = (props) => {
  const {
    shortcut,
    characterId,
    token,
    gameId,
    variables,
  } = props

  const handleNameChange = (event) => {
    structuredFormula.name = event.target.value
    setStructuredFormula({...structuredFormula})
  }

  const handleGroupChange = (event) => {
    setGroup(event.target.value)
  }

  const handleFormulaChange = (id, field, event) => {
    let formula = structuredFormula.formulas.find(obj => {return obj.unique_id == id})
    if (event.target.value == "") {
      delete formula[field]
    } else {
      formula[field] = event.target.value
      if (field == "formula") {
        formula[field] = event.target.value.toLowerCase()
      }
    }
    const d20 = dice.has1d20(formula.formula)
    if (!d20) {
      delete formula.critical_range
      delete formula.fumble_range
    }
    setStructuredFormula({...structuredFormula})
  }

  const handleCritical = (id, event) => {
    let formula = structuredFormula.formulas.find(obj => {return obj.unique_id == id})
    formula.critical_range = parseInt(event.target.value)
    if (formula.critical_range == 20) {
      delete formula.critical_range
    }
    setStructuredFormula({...structuredFormula})
  }

  const handleFumble = (id, event) => {
    let formula = structuredFormula.formulas.find(obj => {return obj.unique_id == id})
    formula.fumble_range = parseInt(event.target.value)
    if (formula.fumble_range == 1) {
      delete formula.fumble_range
    }
    setStructuredFormula({...structuredFormula})
  }

  const handleVariableAdd = (id, event) => {
    let formula = structuredFormula.formulas.find(obj => {return obj.unique_id == id})
    if (event.target.value != "") {
      let vars = formula.variables ? formula.variables : []
      vars.push(variables[event.target.value].name)
      formula.variables = vars
    }
    setStructuredFormula({...structuredFormula})
    setSelected("")
  }

  const handleVariableDelete = (id, v, event) => {
    let formula = structuredFormula.formulas.find(obj => {return obj.unique_id == id})
    var index = formula.variables.indexOf(v);
    if (index !== -1) {
      formula.variables.splice(index, 1);
    }
    if (formula.variables.length == 0) {
      delete formula.variables
    }
    setStructuredFormula({...structuredFormula})
  }

  const handleDeleteShortcut = () => {
    if (window.confirm(`Delete shortcut: ${shortcut.label}?`)) {
      shortcutService.destroy(shortcut.id, token)
      props.closeEditShortcut()
    }
  }

  const [structuredFormula, setStructuredFormula] = useState(
    getStructuredFormulaFromShortcut(shortcut))
  const [group, setGroup] = useState(shortcut.group_name)
  const [selected, setSelected] = useState("")

  const handleRemoveRow = (i) => {
    let formulas = structuredFormula.formulas.filter((formula) => formula.unique_id !== i)
    structuredFormula.formulas = formulas
    setStructuredFormula({...structuredFormula})
  }

  const handleAddRow = (e) => {
    structuredFormula.max_id++
    let max_id = structuredFormula.max_id
    structuredFormula.formulas.push({
      unique_id: max_id,
    })
    setStructuredFormula({...structuredFormula})
  }

  const handleClose = () => {
    props.closeEditShortcut()
  }

  const critFumbleChanged = (formula) => {
    if (formula.critical_range && formula.critical_range != 20) {
      return true
    }
    if (formula.fumble_range && formula.fumble_range != 1) {
      return true
    }
    return false
  }

  const onSave = () => {
    if (structuredFormula.formulas.length == 1) {
      const formula = structuredFormula.formulas[0]
      if (!formula.label && !formula.note && !formula.variables && !critFumbleChanged(formula)) {
        shortcutService.update({
          value: formula.formula,
          label: structuredFormula.name,
          structured_formula: null,
          group_name: group,
        }, shortcut.id, token)
        props.closeEditShortcut()
        return
      }
    }
    shortcutService.update({
      value: null,
      label: structuredFormula.name,
      structured_formula: structuredFormula,
      group_name: group,
    }, shortcut.id, token)
    props.closeEditShortcut()
  }

  return(
    <Modal title='Edit Shortcut' onClose={handleClose}>
      <div className='ShortcutEdit'>
        <input
          className='ShortcutEdit__labelEdit'
          type="text"
          value={structuredFormula.name}
          onChange={handleNameChange}
          placeholder='Name of your shortcut'
        />
        <div className='ShortcutEdit__editor'>
          Group:
          <input
            className='ShortcutEdit__groupEdit'
            type="text"
            value={group}
            onChange={handleGroupChange}
            placeholder='Name of shortcut group'
          />
        </div>
        <div className='ShortcutEdit__editor'>
          <table>
            <thead>
              <tr><th>Label</th><th>Formula</th><th>Note</th></tr>
            </thead>
            <tbody>
              {structuredFormula.formulas.map((formula) => {
                const label_text = formula.label ? formula.label : ""
                const formula_text = formula.formula ? formula.formula : ""
                const d20 = dice.has1d20(formula.formula)
                const note_text = formula.note ? formula.note : ""
                const vars = formula.variables ? formula.variables : []
                const critical_range = formula.critical_range ? formula.critical_range : null
                const fumble_range = formula.fumble_range ? formula.fumble_range : null
                return (
                  <React.Fragment>
                    <tr key={formula.unique_id} className='ShortcutEdit__rowEdit'>
                      <td>
                        <input
                          className='ShortcutEdit__labelEdit'
                          type="text"
                          value={label_text}
                          placeholder='label for section'
                          onChange={handleFormulaChange.bind(null, formula.unique_id, "label")}
                        />
                      </td>
                      <td>
                        <input
                          className='ShortcutEdit__formulaEdit'
                          type="text"
                          value={formula_text}
                          placeholder='1d20+3, etc'
                          onChange={handleFormulaChange.bind(null, formula.unique_id, "formula")}
                        />
                      </td>
                      <td>
                        <input
                          className='ShortcutEdit__noteEdit'
                          type="text"
                          value={note_text}
                          placeholder='note for section'
                          onChange={handleFormulaChange.bind(null, formula.unique_id, "note")}
                        />
                      </td>
                      <td>
                        { d20 ? (
                          <div>
                            Critical:
                            <select onChange={handleCritical.bind(null, formula.unique_id)} value={critical_range} name="CriticalSelection" id="CriticalSelection">
                              {
                                [...Array(20).keys()].map(i => 20-i).map((num, i) => {
                                  return <option key={num} value={num} >{num}</option>
                                })
                              }
                            </select>
                          </div>
                        ) : (null)}
                      </td>
                      <td>
                        { d20 ? (
                          <div>
                            Fumble:
                            <select onChange={handleFumble.bind(null, formula.unique_id)} value={fumble_range} name="FumbleSelection" id="FumbleSelection">
                              {
                                [...Array(20).keys()].map(i => i+1).map((num, i) => {
                                  return <option key={num} value={num}>{num}</option>
                                })
                              }
                            </select>
                          </div>
                        ) : (null)}
                      </td>
                      <td>
                        <select onChange={handleVariableAdd.bind(null, formula.unique_id)} value={selected} name="VariableSelection" id="VariableSelection">
                          <option value="" default='default'></option>
                          {
                            Object.values(variables).map((variable, i) => {
                            return <option key={i} value={variable.id}>{variable.name}</option>
                          })
                          }
                        </select>
                      </td>
                      <td>
                        <RemoveIcon
                          fontSize="small"
                          onClick={handleRemoveRow.bind(null, formula.unique_id)}/>
                      </td>
                    </tr>
                    { vars.length > 0 ? (
                      <tr>
                        <td colSpan="4">
                          {vars.map(v => {
                            return (
                              <button
                                className='ShortcutEdit__deleteVariable'
                                onClick={handleVariableDelete.bind(null, formula.unique_id, v)}>{v} 🗑</button>
                            )
                          })}
                        </td>
                      </tr>
                    ) : (null) }
                  </React.Fragment>
                )
              })}
              <tr>
                <td></td><td></td><td></td><td></td><td></td><td></td>
                <td>
                  <AddIcon fontSize="small" onClick={handleAddRow}/>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <button onClick={onSave}>Save</button>
        <button
          className='ShortcutEdit__delete'
          onClick={handleDeleteShortcut}>🗑</button>
      </div>
    </Modal>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    shortcut: ownProps.shortcut,
    characterId: state.characterId,
    token: state.client.token,
    gameId: state.game.id,
    variables: state.variables.list,
  }
}

const mapDispatchToProps = {
  closeEditShortcut,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShortcutEdit)
