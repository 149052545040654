import React from 'react'
import PropTypes from 'prop-types';
import {
  OFFENSE
} from '../../../../constants/monster_constants'
import Ability from './Ability'
import Affliction from './Affliction'
import Attack from './Attack'
import Speed from './Speed'
import Spells from './Spells'

const Offense = (props) => {
  const {
    offense,
    gameId,
    monsterName,
    token,
  } = props

  const sharedMonsterData = {
    monsterName: monsterName,
    token: token,
    gameId: gameId,
  }

  return (
    <div className='Monster__offense'>
      <Speed speed={offense.speed} />
      {offense.offensive_actions.map((oa, i) => {
        if (oa.offensive_action_type == 'attack') {
          return (<Attack attack={oa.attack} i={i} {...sharedMonsterData} />)
        } else if (oa.offensive_action_type == 'spells') {
          return (<Spells spells={oa.spells} i={i} {...sharedMonsterData} />)
        } else if (oa.offensive_action_type == 'affliction') {
          return (<Affliction affliction={oa.affliction} i={i} {...sharedMonsterData} />)
        } else if (oa.offensive_action_type == 'ability') {
          return (<Ability ability={oa.ability} i={i} />)
        }
      })}
    </div>
  )
}

//Offense.propTypes = PropTypes.shape(OFFENSE)

export default Offense

/*
  "offense": {
    "type": "stat_block_section",
    "subtype": "offense",
    "name": "Offense",
    "speeds": <Speed />
    "offensive_actions": {
      "name": "Melee",
      "type": "stat_block_section",
      "subtype": "offensive_action",
      "offensive_action_type": "attack",
      "attack": <Attack />
      "spells": <Spells />
      "affliction": <Affliction />
      "ability": <Ability />
    }
  }
*/
