import React from "react"
import PropTypes from "prop-types";
import Games from "./Games";

class NewGame extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: props.name || ''
    }
    this.handleSubmit_ = this.handleSubmit_.bind(this)
    this.handleChange_ = this.handleChange_.bind(this)
  }

  render () {
    return (
      <div>
        <h1>What's the name of your new game?</h1>
        <div className='ContentBox'>
          <form onSubmit={this.handleSubmit} action='/games' method='POST'>
            <input type='hidden' name='authenticity_token' value={this.props.token} />
            <input type='text' name='game[name]' value={this.state.name} onChange={this.handleChange_} />
            <input type='submit' value='Create Game' />
          </form>
        </div>
      </div>
    )
  }

  handleChange_(e) {
    this.setState({name: e.target.value})
  }

  handleSubmit_(e) {
    e.preventDefault()
  }
}

NewGame.propTypes = {
  name: PropTypes.string,
  token: PropTypes.string,
  error: PropTypes.string
};

export default NewGame
