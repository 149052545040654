export function union(setA, setB) {
  let _union = new Set(setA)
  for (let elem of setB) {
      _union.add(elem)
  }
  return _union
}

export function difference(setA, setB) {
  let _difference = new Set(setA)
  for (let elem of setB) {
      _difference.delete(elem)
  }
  return _difference
}

export function intersection(setA, setB) {
  let _intersection = new Set()
  for (let elem of setB) {
      if (setA.has(elem)) {
          _intersection.add(elem)
      }
  }
  return _intersection
}

export function convertArrayToObject(array, key) {
  const initialValue = {}
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    }
  }, initialValue)
}

export function convertStateListToObject(list, key) {
  const initialValue = {};
  for (const [k, value] of Object.entries(list)) {
    initialValue[value[key]] = value
  }
  return initialValue
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
