import React from 'react'
import PropTypes from 'prop-types';
import {
  SKILLS
} from '../../../../constants/monster_constants'
import Modifiers from './Modifiers';
import * as dice from '../../../../lib/dice'

const Skill = (props) => {
  const {
    skill,
    i,
    monsterName,
    token,
    gameId,
    children
  } = props

  if (!skill) {return null}

  // TODO: handle skill link
  return (
    <span className='Monster__skill' key={i}>
      {skill.name}<Modifiers modifiers={skill.modifiers} />&nbsp;
      {dice.d20Text(`${monsterName} ${skill.name}`, skill.value, 'Monster__roll', gameId, token)}{children}
    </span>
  )
}

export default Skill

/* Skills Example
Alghollthu Master:

{
    "type": "stat_block_section",
    "subtype": "skill",
    "name": "Athletics",
    "link": {
        "type": "link",
        "name": "Athletics",
        "alt": "Athletics",
        "game-obj": "Skills",
        "aonid": 3
    },
    "value": 16
},
{
    "type": "stat_block_section",
    "subtype": "skill",
    "name": "Lore",
    "link": {
        "type": "link",
        "name": "Lore",
        "alt": "Lore",
        "game-obj": "Skills",
        "aonid": 8
    },
    "value": 14,
    "modifiers": [
        {
            "type": "stat_block_section",
            "subtype": "modifier",
            "name": "any one subcategory"
        }
    ]
},

*/
