import mixpanel from 'mixpanel-browser'
import {
  MP_CREATE_AVATAR,
  MP_DELETE_AVATAR,
} from '../constants/mixpanel_events'

export async function saveAvatar(avatar, name, characterId, token) {
  let values = {
    avatar: avatar,
    name: name,
  }
  save(values, characterId, token)
  mixpanel.track(MP_CREATE_AVATAR)
}

export async function save(values, characterId, token) {
  const response = await fetch(`${baseUrl()}/${characterId}/avatars`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': token
    },
    body: JSON.stringify(values)
  })
  const avatarData = await response.json()
  return avatarData
}

export async function deleteAvatar(avatarId, characterId, token) {
  const response = await fetch(`${baseUrl()}/${characterId}/avatars/${avatarId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': token
    },
  })
  const avatarData = await response.json()
  mixpanel.track(MP_DELETE_AVATAR)
  return avatarData
}

function baseUrl() {
  return `${window.location.protocol}//${location.host}/characters`
}