import {
  CLOSE_ADD_AVATAR,
  OPEN_ADD_AVATAR,
  ADD_AVATAR,
  DESTROY_AVATAR,
} from "../constants/action-types"

const initialState = {
  addAvatarOpen: false,
  list: INITIAL.avatars
}

export default (state = initialState, action) => {
  const avatars = Object.values(state.list)
  const initialAvatars = [...avatars]

  switch(action.type) {
    case OPEN_ADD_AVATAR:
      return {
        ...state,
        addAvatarOpen: action.payload
      }
    case CLOSE_ADD_AVATAR:
      return {
        ...state,
        addAvatarOpen: action.payload
      }
    case ADD_AVATAR:
      const avatarId = action.payload.id
      const avatarData = action.payload
      const characterId = action.characterId

      if (avatarData.character_id == characterId) {
        initialAvatars.push(avatarData)

        return {
          ...state,
          list: {
            ...state.list,
            [avatarId]: avatarData
          }
        }
      }
      return state
    case DESTROY_AVATAR:
      const { [action.payload.id]: _, ...withoutAvatar } = state.list

      return {
        ...state,
        list: withoutAvatar,
      }
    default:
      return state
  }
}