import React, {useState} from "react";

import { connect } from 'react-redux'
import * as rollService from '../../services/rolls'
import store from '../../store'

import {
  addTrackedRoll
} from '../../actions'
import './ActivityRoller.css'

const FACES = ['20','12','10','8','6','4','100']

const ActivityRoller = (props) => {
  const {
    addTrackedRoll,
    handleRollCommandChange,
    handleKeyDown,
    rollCommand,
    sendCommand,
    token,
    trackedRolls,
    gameId
  } = props

  const [showPanel, setShowPanel] = useState(false);
  const toggle = showPanel => !showPanel;

  const quickRoll = (formula) => {
    rollService.create({token: token, label: "", gameId: gameId, formula})
  }

  const trackSentCommand_ = () => {
    addTrackedRoll(rollCommand)
    sendCommand()
  }

  const maybeRenderPanel_ = () => {
    if (showPanel) {
      return (
        <div className='ActivityRoller__panel'>
          <div className='ActivityRoller__buttonRow'>
            <span>Roll a die: </span>
            {FACES.map((faces, i) => {
              return (
                <button key={i} className='ActivityRoller__quickButton' onClick={quickRoll.bind(this, `1d${faces}`)}>d{faces}</button>
              )
            })}
          </div>

          <div className='ActivityRoller__buttonRow'>
          <span>Roll History: </span>
            {trackedRolls.map((roll, i) => {
              return (
                <button key={i} className='ActivityRoller__quickButton' onClick={quickRoll.bind(this, roll)}>{roll}</button>
              )
            })}
          </div>

        </div>
      )
    }
  }

  return (
    <div className='ActivityRoller'>
      {maybeRenderPanel_()}

      <input type='text'
        className='ActivityRoller__rollField'
        value={rollCommand}
        placeholder='1d20+3, etc'
        onChange={handleRollCommandChange}
        onKeyDown={handleKeyDown} />
      <button className='ActivityRoller__rollButton' onClick={trackSentCommand_}>Roll</button>

      <div className='ActivityRoller__toggle'>
        <button className='ActivityRoller__toggleButton' onClick={() => setShowPanel(toggle)}>Quick Rolls</button>
      </div>
    </div>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    token: state.client.token,
    gameId: state.game.id,
    trackedRolls: state.history.trackedRolls,
  }
}

const mapDispatchToProps = {
  addTrackedRoll
 }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityRoller)
