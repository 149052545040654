import { ADD_INVITATION, DESTROY_INVITATION } from "../constants/action-types"

const initialState = {
  list: INITIAL.invitations
}

export default (state = initialState, action) => {
  let invitationId
  let invitationData

  if (action.payload) {
    invitationId = action.payload.id
    invitationData = action.payload
  }

  switch(action.type) {
    case ADD_INVITATION:
      return {
        ...state,
        list: {
          ...state.list,
          [invitationId]: invitationData
        }
      }
    case DESTROY_INVITATION:
      const { [invitationId]: _, ...withoutInvitation } = state.list
      return {
        ...state,
        list: withoutInvitation,
      }
    default:
      return state
  }
}
