import { ADD_ROLL } from "../constants/action-types"

const initialState = INITIAL.groupRollDefaults

export default (state = initialState, action) => {
  let groupRollId
  let groupRollData

  if (action.payload) {
    groupRollId = action.payload.id
    groupRollData = action.payload
  }

  switch(action.type) {
    case ADD_ROLL:
      const rollData = action.payload
      if (rollData.group_roll_id) {
        const output = {
          label: rollData.label
        }
        if (rollData.formula) {
          output.formula = rollData.formula
        }
        if (rollData.structured_formula) {
          output.structured_formula = rollData.structured_formula
        }
        const users = state[rollData.label] || {}
        users[rollData.character_id] = output
      }
      return state
    default:
      return state
  }
}
