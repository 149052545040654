import React from 'react'
import { connect } from 'react-redux'
import * as rollService from '../../services/rolls'
import * as shortcutService from '../../services/shortcuts'
import store from '../../store'
import EditIcon from '@material-ui/icons/Edit'
import ShortcutEdit from './ShortcutEdit'
import { openEditShortcut } from '../../actions'
import * as dice from '../../lib/dice'

import './Shortcut.css'

const Shortcut = (props) => {
  const {
    editShortcutOpen,
    shortcut,
    token,
    gameId,
    variables,
  } = props

  const { label, value, structured_formula } = shortcut
  const [formulaToggle, setFormulaToggle] = React.useState(true);
  const [labelToggle, setLabelToggle] = React.useState(true);

  const handleEditIconClick = () => {
    store.dispatch(openEditShortcut(shortcut))
  }

  const handleUseShortcut = () => {
    if (shortcut.structured_formula) {
      rollService.create({
        token: token,
        label: shortcut.label,
        gameId: gameId,
        structuredFormula: dice.getCollapsedFormula(shortcut.structured_formula, variables)})
    } else {
      rollService.create({
        token: token,
        label: shortcut.label,
        gameId: gameId,
        formula: shortcut.value})
    }
  }

  const handleFormulaToggleInput = () => {
    setFormulaToggle(false);
  }

  const cancelFormulaEdit = () => {
    setFormulaToggle(true)
  }

  const saveFormulaEdit = (value) => {
    shortcutService.update({value: value.toLowerCase() }, shortcut.id, token)
    setFormulaToggle(true)
  }

  const handleFormulaToggleBlur = (event) => {
    cancelFormulaEdit()
  }

  const handleFormulaToggleKeyDown = (e) => {
    if (e.key === 'Enter') {
      saveFormulaEdit(e.target.value)
    } else if (e.keyCode === 27) {
      cancelFormulaEdit()
    }
  }

  const handleLabelToggleInput = () => {
    setLabelToggle(false);
  }

  const cancelLabelEdit = () => {
    setLabelToggle(true)
  }

  const saveLabelEdit = (label) => {
    shortcutService.update({label: label}, shortcut.id, token)
    setLabelToggle(true)
  }

  const handleLabelToggleBlur = (event) => {
    cancelLabelEdit()
  }

  const handleLabelToggleKeyDown = (e) => {
    if (e.key === 'Enter') {
      saveLabelEdit(e.target.value)
    } else if (e.keyCode === 27) {
      cancelLabelEdit()
    }
  }

  const getStructuredFormula = () => {
    if(structured_formula) {
      return structured_formula
    }
    return {
      name: label,
      formulas: [
        {
          formula: value
        }
      ]
    }
  }

  const printableValue = dice.getPrintableValue(getStructuredFormula(), variables)

  return (
    <div className='Shortcut'>
      {
        editShortcutOpen && editShortcutOpen.id == shortcut.id ? (
          <ShortcutEdit shortcut={shortcut} />
        ) : (
          null
        )}
      <button
        className='Shortcut__activate'
        onClick={handleUseShortcut}>Roll</button>
      {labelToggle || structured_formula ? (
        <span
          className='Shortcut__label'
          onDoubleClick={handleLabelToggleInput}>{label}</span>
      ) : (
        <input
          autoFocus
          className='Shortcut__labelEdit'
          type="text"
          defaultValue={label}
          onBlur={handleLabelToggleBlur}
          onKeyDown={handleLabelToggleKeyDown} />
      )}
      {formulaToggle || structured_formula ? (
        <span
          className='Shortcut__value'
          onDoubleClick={handleFormulaToggleInput}>{printableValue}</span>
      ) : (
        <input
          autoFocus
          className='Shortcut__valueEdit'
          type="text"
          defaultValue={value}
          onBlur={handleFormulaToggleBlur}
          onKeyDown={handleFormulaToggleKeyDown} />
      )}
      <button className='Shortcut__edit' onClick={handleEditIconClick}>
        <EditIcon style={{ fontSize: 13 }} />
      </button>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    editShortcutOpen: state.shortcuts.editShortcutOpen,
    shortcut: ownProps.shortcut,
    token: state.client.token,
    gameId: state.game.id,
    variables: state.variables.vars,
  }
}

const mapDispatchToProps = {
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Shortcut)
