import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import { union, difference } from '../../lib/utils'
import Shortcut from './Shortcut'
import VariableButton from './VariableButton'
import * as shortcutService from '../../services/shortcuts'
import * as variableService from '../../services/variables'
import {
  REG_SHORTCUT_SORT,
  unpackRegistry,
} from '../../services/registries'

import './Shortcuts.css'

import {
  setShortcutSortOrder,
  updateNewShortcutLabel,
  updateNewShortcutCommand,
} from '../../actions'

const Shortcuts = (props) => {
  const {
    orderedIds,
    newShortcutLabel,
    newShortcutCommand,
    registries,
    sort,
    shortcuts,
    variables,
    characterId,
    token,
  } = props

  const reg = unpackRegistry(registries)

  const variableList = Object.values(variables)
  variableList.sort((a, b) => a.name > b.name ? 1 : -1)

  const getShortcutGroups = (ordered) => {
    const groups = new Set(Array.from(
      new Set(Object.values(shortcuts).map(s => s.group_name))).sort());
    if (groups.has(null)) {
      // Ensures the null group is last in the order
      groups.delete(null)
      groups.add(null)
    }
    return groups
  }

  const binShortcuts = () => {
    const groups = getShortcutGroups()
    const bins = Array.from(groups).map((v) => {
      return {group: v, shortcuts: []}
    }).reduce((result, item) => (
      result[item.group] = item, result
    ), {})

    // Place all shortcuts sorted previously via drag and drop
    let usedIds = new Set()
    for(let group of groups) {
      const bin = bins[group]
      usedIds = union(usedIds, binGroup(bin, group))
    }

    // Place remaining shortcuts
    const d = difference(orderedIds, usedIds)
    for (let id of d) {
      var shortcut = shortcuts[id]
      bins[shortcut.group_name].shortcuts.push(shortcut)
    }
    return {groups: Array.from(groups), bins: bins}
  }

  const binGroup = (bin, group) => {
    const usedIds = new Set()
    const r = reg.getEntry(REG_SHORTCUT_SORT)
    try {
      const regBins = r.entry["bins"]
      if(regBins) {
        const groupBin = regBins[group]
        const groupSort = groupBin.shortcuts

        for(var j = 0; j < groupSort.length; j++) {
          const shortcutId = groupSort[j]
          const s = shortcuts[shortcutId]
          if(s && s.group_name == group) {
            usedIds.add(`${shortcutId}`)
            bin.shortcuts.push(s)
          }
        }
        return usedIds
      }
      return new Set()
    } catch (error) {
      console.error(error)
      return new Set()
    }
  }

  const handleSortChange = newSortOrder => {
    props.setShortcutSortOrder(newSortOrder)

    const entry = reg.getEntry(REG_SHORTCUT_SORT)
    entry.entry.sort = newSortOrder
    reg.writeEntry(entry, characterId, token)
  }

  const handleNewShortcutLabelChange = (e) => {
    props.updateNewShortcutLabel(e.target.value)
  }

  const handleNewShortcutCommandChange = (e) => {
    props.updateNewShortcutCommand(e.target.value)
  }

  const handleAddShortcut = () => {
    shortcutService.create(
      newShortcutLabel,
      newShortcutCommand,
      characterId,
      token,
    )
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleAddShortcut()
    }
  }

  const grid = 1;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 ${grid}px 0 0`,

    // change background colour if dragging
    background: isDragging ? '#F79639' : '#222',
    borderRadius: '4px',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const onDragEnd = (result) => {
    const newGroupString = result.destination.droppableId.replace("group-", "")
    const newGroup = newGroupString == "null" ? null : newGroupString
    const newBin = bins[newGroup]
    const oldGroupString = result.source.droppableId.replace("group-", "")
    const oldGroup = oldGroupString == "null" ? null : oldGroupString
    const oldBin = bins[oldGroup]

    // Remove dragged object from it's old bin and position, and add it to the new one
    const [removed] = oldBin.shortcuts.splice(result.source.index, 1)
    newBin.shortcuts.splice(result.destination.index, 0, removed)

    // Generate a map of the current sort
    const sorting = Array.from(groups).map((v) => {
      return {group: v, shortcuts: bins[v].shortcuts.map((shortcut) => {
        return(shortcut.id)
      })}
    }).reduce((result, item) => (
      result[item.group] = item, result
    ), {})

    // Write the sort registry
    const r = reg.getEntry(REG_SHORTCUT_SORT)
    r.entry.bins = sorting
    reg.writeEntry(r, characterId, token)

    // Update the shortcut group
    shortcutService.update({
      ...removed,
      group_name: newGroup,
    }, removed.id, token)
  }

  const { groups, bins } = binShortcuts()
  const hasSort = "bins" in reg.getEntry(REG_SHORTCUT_SORT).entry

  return(
    <div className='Shortcuts'>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className='Shortcuts__list'>
          <div className='Shortcuts__variables'>
            {variableList.map((variable) => {
              return (
                <VariableButton key={`VariableButton-${variable.id}`} variable={variable} />
              )
            })}
          </div>
          {hasSort ? null : (
          <div className='Shortcuts__sort'>
            Sort:
            <button disabled={sort === 'created'}
                    onClick={handleSortChange.bind(null, 'created')}>Order Created</button>
            <button disabled={sort === 'alpha'}
                    onClick={handleSortChange.bind(null, 'alpha')}>Name</button>
          </div>)}
          {groups.map((group) => {
            const classes = group ? 'Shortcuts__container Shortcuts__container--group' : 'Shortcuts__container'
            return (
              <Droppable key={`ShortcutDrop-${group}`} droppableId={`group-${group}`} direction="vertical">
                {(provided, snapshot) => (
                  <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className={classes}>
                    {group ? <span>{group}</span> : null}

                    {bins[group].shortcuts.map((shortcut, index) => {
                      return (
                        <Draggable key={`ShortcutDrag-${shortcut.id}`} draggableId={`${shortcut.id}`} index={index} >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <Shortcut key={`Shortcut-${shortcut.id}`} shortcut={shortcut} />
                              {provided.placeholder}
                            </div>
                          )}
                        </Draggable>
                      )
                    })}
                  </div>
                )}
              </Droppable>
            )
          })}
        </div>
        <div className='Shortcuts__controls'>
          <label htmlFor='newLabel'>Name</label>
          <input type='text'
                name='newLabel'
                value={newShortcutLabel}
                onChange={handleNewShortcutLabelChange}
                className='Shortcuts__newName'
                placeholder='Name your shortcut'
                onKeyDown={handleKeyDown}
          />
          <label htmlFor='newValue'>Value</label>
          <input type='text'
                name='newValue'
                value={newShortcutCommand}
                onChange={handleNewShortcutCommandChange}
                className='Shortcuts__newValue'
                placeholder='1d20+3, etc'
                onKeyDown={handleKeyDown}
          />
          <button onClick={handleAddShortcut}>Add Shortcut</button>
        </div>
      </DragDropContext>
    </div>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    shortcuts: state.shortcuts.list,
    orderedIds: state.shortcuts.orderedIds,
    characterId: state.characterId,
    sort: state.shortcuts.sort,
    newShortcutLabel: state.client.shortcutLabel,
    newShortcutCommand: state.client.shortcutCommand,
    token: state.client.token,
    currentPanel: state.client.currentPanel,
    gameId: state.game.id,
    ownerId: state.ownerId,
    registries: state.registries.reg,
    variables: state.variables.vars,
  }
}

const mapDispatchToProps = {
  setShortcutSortOrder,
  updateNewShortcutLabel,
  updateNewShortcutCommand,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Shortcuts)
