import React from 'react'
/*import PropTypes from 'prop-types'
import {
  AFFLICTION
} from '../../../../constants/monster_constants'*/
import Action from './Action'
import Markdown from '../../../containers/Markdown';
import Modifiers from './Modifiers';

// Tests
// Cacodaemon: text: Cacodaemonia (disease) The cacodaemon can telepathically communicate with the afflicted creature at any distance on the same plane; Saving Throw DC 17 Fortitude; Stage 1 carrier (1 day); Stage 2 stupefied 1 (1 day); Stage 3 stupefied 2 (1 day)
// Azure Worm: maximum duration: Azure Worm Venom (poison) Saving Throw DC 37 Fortitude, Maximum Duration 6 rounds; Stage 1 3d6 poison damage and clumsy 2 (1 round); Stage 2 4d6 poison damage and clumsy 2 (1 round); Stage 3 6d6 poison damage and clumsy 2 (1 round)
// Slime Mold: onset: Slime Rot (disease) Saving Throw DC 18 Fortitude; Onset 1d4 days; Stage 1 enfeebled 1 and sickened 1 (1 day); Stage 2 as stage 1 (1 day); Stage 3 drained 1, enfeebled 2, and sickened 2 (1 day); Stage 4 as stage 3 (1 day); Stage 5 drained 2 plus unconscious (no Perception check to wake up) (1 day); Stage 6 dead, and the body erupts to release a new slime mold
// Slime Mold: Lots of stages: Slime Rot (disease) Saving Throw DC 18 Fortitude; Onset 1d4 days; Stage 1 enfeebled 1 and sickened 1 (1 day); Stage 2 as stage 1 (1 day); Stage 3 drained 1, enfeebled 2, and sickened 2 (1 day); Stage 4 as stage 3 (1 day); Stage 5 drained 2 plus unconscious (no Perception check to wake up) (1 day); Stage 6 dead, and the body erupts to release a new slime mold

// TODO: Fix Counteflora (518)
const getTraits = (affliction) => {
  if (affliction.traits && affliction.traits.length > 0) {
    return ` (${affliction.traits.map(t => t.name).join(', ')})`
  }
  return ""
}

let semicolon = " ";

const separator = () => {
  let result = `${semicolon}`;
  semicolon = "; ";
  return result
}

const renderSavingThrow = (affliction) => {
  if (affliction.saving_throw) {
    return (
      <span>
        {separator()}
        <strong>Saving Throw</strong>{' '}
        <span>
          {affliction.saving_throw.save_type} DC {affliction.saving_throw.dc}
          <Modifiers modifiers={affliction.saving_throw.modifiers} />
        </span>
      </span>
    )
  }
}

const renderSection = (section, name) => {
  if (!section) {return null}
  return (
    <span>
      {separator()}
      { name ? <strong>{name}</strong> : null }
      {' '}
      <Markdown text={section} />
    </span>
  )
}

const Affliction = (props) => {
  const {
    affliction,
    i,
    gameId,
    monsterName,
    token,
  } = props

  const sharedMonsterData = {
    monsterName: monsterName,
    token: token,
    gameId: gameId,
  }
  if (!affliction) {return null}

  let traits = getTraits(affliction)
  let text = affliction.text ? `${affliction.text};` : null

  return (
    <div key={i}>
      <strong>{affliction.name}{' '}</strong>
      {affliction.action ? <Action name={affliction.action.name} /> : null}
      {traits}
      {' '}{
        renderSection(affliction.context, null)
      }{
        renderSavingThrow(affliction)
      }{
        renderSection(affliction.requirements, "Requirements")
      }{
        renderSection(affliction.effect, "Effect")
      }{
        renderSection(affliction.onset, "Onset")
      }{
        renderSection(affliction.maximum_duration, "Maximum Duration")
      }
      {affliction.stages ? affliction.stages.map((s,i) => {
        return (
          <span>{separator()}<strong>Stage {i+1}</strong>{' '}{s.text}</span>
        )
      }) : null}{
        renderSection(affliction.special, "Special")
      }{
        renderSection(affliction.text, null)
      }
    </div>
  )
}

//Affliction.propTypes = PropTypes.shape(AFFLICTION)

export default Affliction

/*
"affliction": {
  "type": "stat_block_section",
  "subtype": "affliction",
  "name": "Cacodaemonia",
  "traits": [
    {
      "type": "stat_block_section",
      "subtype": "trait",
      "name": "disease",
      "link": {
        "type": "link",
        "name": "disease",
        "alt": "disease",
        "game-obj": "Traits",
        "aonid": 46
      }
    }
  ],
  "links": [
    {
      "type": "link",
      "name": "stupefied 1",
      "alt": "stupefied 1",
      "game-obj": "Conditions",
      "aonid": 34
    },
    {
      "type": "link",
      "name": "stupefied 2",
      "alt": "stupefied 2",
      "game-obj": "Conditions",
      "aonid": 34
    }
  ],
  "text": "The cacodaemon can telepathically communicate with the afflicted creature at any distance on the same plane",
  "saving_throw": "DC 17 Fortitude",
  "stages": [
    "carrier (1 day)",
    "stupefied 1 (1 day)",
    "stupefied 2 (1 day)"
  ]
}
*/
