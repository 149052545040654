import React from 'react'
import AvatarSelection from './AvatarSelection'
import { connect } from 'react-redux'
import AddIcon from '@material-ui/icons/AddAPhoto'
import store from '../../store'
import { openAddAvatar } from '../../actions'
import './Avatars.css'
import AvatarChoice from '../components/AvatarChoice'


const Avatars = (props) => {
  const {
    addAvatarOpen,
    avatars,
    characterId,
    token,
    characters,
  } = props

  const character = characters[characterId]

  const handleAddIconClick = () => {
    store.dispatch(openAddAvatar())
  }

  return (
    <div className='Avatars'>
      {addAvatarOpen ? <AvatarSelection /> : null}
      <AddIcon
        fontSize="large"
        style={{ color: '#a0a0a0', cursor: 'pointer' }}
        onClick={handleAddIconClick}
        />
      <div className='Avatars__avatarList'>
        {Object.values(avatars).map((avatar, i) => {
          const avatarSelected = avatar.avatar == character.avatar_url
          return(
            <AvatarChoice
              id={avatar.id}
              preview={avatar.avatar}
              name={avatar.name}
              characterId={characterId}
              token={token}
              avatarSelected={avatarSelected}
            />
          )
        })}
      </div>
    </div>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    addAvatarOpen: state.avatars.addAvatarOpen,
    avatars: state.avatars.list,
    characterId: state.characterId,
    token: state.client.token,
    characters: state.characters.list,
  }
}

const mapDispatchToProps = {
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Avatars)
