import React from 'react'
import Markdown from '../../../containers/Markdown';

const Modifier = props => {
  const {
    modifier,
    i,
    children
  } = props

  if (!modifier) {return null}

  // TODO: handle modifier links
  return (
    <span className="Monster__modifier"><Markdown text={modifier.name} />{children}</span>
  )
}

export default Modifier
