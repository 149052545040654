import React from 'react'
import { connect } from 'react-redux'

import Shortcuts from './Shortcuts'
import Variables from './Variables'
import Avatars from './Avatars'
import GM from './GM'
import Players from './Players'
import Monsters from './Monsters'
import Encounters from './Encounters'
import './PanelContent.css'
const PanelContent = (props) => {
  const {
    currentPanel,
  } = props

  return(
    <div className='PanelContent'>
      {renderPanel()}
    </div>
  )

function renderPanel() {
  switch(currentPanel) {
    case 'Shortcuts':
      return <Shortcuts />
    case 'Variables':
      return <Variables />
    case 'Avatars':
      return <Avatars />
    case 'GM':
      return <GM />
    case 'Players':
      return <Players />
    case 'Monsters':
      return <Monsters />
    case 'Encounters':
      return <Encounters />
    default:
      return (
        <div>No such panel: {currentPanel}</div>
      )
    }
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    currentPanel: state.client.currentPanel,
    ownerId: state.ownerId,
  }
}

export default connect(
  mapStateToProps
)(PanelContent)


