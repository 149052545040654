import diceRollSound from '../assets/dice.wav'
import criticalSound from '../assets/critical.wav'
import fumbleSound from '../assets/fumble.wav'

export function diceRoll() {
  console.log('🔊 playing dice roll sound')
  const sound = new Audio(diceRollSound)
  sound.play()
}

export function critical() {
  const sound = new Audio(criticalSound)
  sound.play()
}

export function fumble() {
  const sound = new Audio(fumbleSound)
  sound.play()
}
