import React from 'react'
import Modifiers from './Modifiers';
import Markdown from '../../../containers/Markdown';

const Protection = (props) => {
  const {
    protection,
    children
  } = props

  if (!protection) {return null}

  const renderValue = (protection) => {
    if (protection.value) {
      return ` ${protection.value}`
    }
    return ''
  }
  // TODO: handle protection link
  return (
    <span className='Monster__protection'>
      <Markdown text={protection.name} />{renderValue(protection)}<Modifiers modifiers={protection.modifiers} />{children}
    </span>
  )
}

export default Protection

/* Protection Example
Jabberwock:

"immunities": [
    {
        "type": "stat_block_section",
        "subtype": "immunity",
        "name": "paralyzed",
        "link": {
            "type": "link",
            "name": "paralyzed",
            "alt": "paralyzed",
            "game-obj": "Conditions",
            "aonid": 28
        }
    },
    {
        "type": "stat_block_section",
        "subtype": "immunity",
        "name": "sleep",
        "link": {
            "type": "link",
            "name": "sleep",
            "alt": "sleep",
            "game-obj": "Traits",
            "aonid": 145
        }
    }
],
"resistances": [
    {
        "type": "stat_block_section",
        "subtype": "resistance",
        "name": "fire",
        "value": 20
    }
],
"weaknesses": [
    {
        "type": "stat_block_section",
        "subtype": "weakness",
        "name": "*vorpal* weapons",
        "value": 20
    },
    {
        "type": "stat_block_section",
        "subtype": "weakness",
        "name": "vorpal fear"
    }
]


Adamantine Golem:

"immunities": [
    {
        "type": "stat_block_section",
        "subtype": "immunity",
        "name": "bleed"
    },
    {
        "type": "stat_block_section",
        "subtype": "immunity",
        "name": "death effects"
    },
    {
        "type": "stat_block_section",
        "subtype": "immunity",
        "name": "disease"
    },
    {
        "type": "stat_block_section",
        "subtype": "immunity",
        "name": "doomed"
    },
    {
        "type": "stat_block_section",
        "subtype": "immunity",
        "name": "drained"
    },
    {
        "type": "stat_block_section",
        "subtype": "immunity",
        "name": "fatigued"
    },
    {
        "type": "stat_block_section",
        "subtype": "immunity",
        "name": "fire"
    },
    {
        "type": "stat_block_section",
        "subtype": "immunity",
        "name": "healing"
    },
    {
        "type": "stat_block_section",
        "subtype": "immunity",
        "name": "magic",
        "modifiers": [
            {
                "type": "stat_block_section",
                "subtype": "modifier",
                "name": "see below"
            }
        ]
    },
    {
        "type": "stat_block_section",
        "subtype": "immunity",
        "name": "mental"
    },
    {
        "type": "stat_block_section",
        "subtype": "immunity",
        "name": "necromancy"
    },
    {
        "type": "stat_block_section",
        "subtype": "immunity",
        "name": "nonlethal attacks"
    },
    {
        "type": "stat_block_section",
        "subtype": "immunity",
        "name": "paralyzed"
    },
    {
        "type": "stat_block_section",
        "subtype": "immunity",
        "name": "poison"
    },
    {
        "type": "stat_block_section",
        "subtype": "immunity",
        "name": "sickened"
    },
    {
        "type": "stat_block_section",
        "subtype": "immunity",
        "name": "unconscious"
    }
],
"resistances": [
    {
        "type": "stat_block_section",
        "subtype": "resistance",
        "name": "physical",
        "modifiers": [
            {
                "type": "stat_block_section",
                "subtype": "modifier",
                "name": "except vorpal adamantine",
                "links": [
                    {
                        "type": "link",
                        "name": "vorpal",
                        "alt": "vorpal",
                        "game-obj": "Equipment",
                        "aonid": 308
                    }
                ]
            }
        ],
        "value": 20
    }
]

Hydra:

"immunities": [
    {
        "type": "stat_block_section",
        "subtype": "immunity",
        "name": "area damage"
    }
],
"weaknesses": [
    {
        "type": "stat_block_section",
        "subtype": "weakness",
        "name": "slashing",
        "value": 5
    }
]


*/
