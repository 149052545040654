import React from 'react'
import CancelIcon from '@material-ui/icons/Cancel'
import cn from 'classnames'

import './Modal.css'

const Modal = (props) => {
  const {
    children,
    onClose,
    size,
    title,
  } = props

  const containerClasses = cn({
    'Modal__container': true,
    'Modal__container--default': size !== 'small',
    'Modal__container--small': size === 'small',
  })

  return (
    <div className='Modal'>
      <div className={containerClasses}>
        <div className='Modal__header'>
          <div className='Modal__title'>{title}</div>
          <CancelIcon
            className='Modal__close'
            fontSize="default"
            style={{ color: '#eee', cursor: 'pointer' }}
            onClick={onClose}
          />
        </div>
        <div className='Modal__content'>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal
