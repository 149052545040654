import React from 'react'
import { connect } from 'react-redux'
import { updateGroupRollName } from '../../actions'
import * as groupRollService from '../../services/groupRoll'

import './GM.css'

const GM = (props) => {
  const {
    gameId,
    groupRollName,
    token,
    updateGroupRollName,
    groupRolls,
  } = props

  const labels = Object.values(groupRolls).map(gr => gr.label)
  const labelSet = new Set(labels)
  labelSet.add("Initiative")
  const uniqueLabels = [...labelSet]

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleGroupRollNameSave()
    }
  }

  const handleGroupRollNameChange = (e) => {
    updateGroupRollName(e.target.value)
  }

  const handleGroupRollNameSave = () => {
    groupRollService.create(groupRollName, gameId, token)
    updateGroupRollName('')
  }

  const handleRepeatGroupRollCreate = (label) => {
    groupRollService.create(label, gameId, token)
  }

  return (
    <div className='GM'>
      <div className='lets-roll-section'>Group Rolls</div>
      <p>Request that everyone in the party make the same roll. If the player
        has a shortcut it will prompt them to roll it, otherwise it will
        prompt them to create a new shortcut.</p>

      <div className='lets-roll-subSection'>Create a new Group Roll Request</div>
      <div className='GM__groupRollName'>
        <label className='GM__groupRollNameLabel'>Name of Group Roll </label>
        <input type='text'
          className='GM__groupRollNameInput'
          value={groupRollName}
          onChange={handleGroupRollNameChange}
          onKeyDown={handleKeyDown}
        />
        <button onClick={handleGroupRollNameSave}>Request</button>
      </div>

      <div className='lets-roll-subSection'>Request a previously used Group Roll again:</div>
      <div className='GM__groupRolls'>
        {uniqueLabels.map((label,i) => {
          const requestFn = handleRepeatGroupRollCreate.bind(this, label)
          return (
            <div key={i} className='GM__groupRoll'>
              <button onClick={requestFn}>{label}</button>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    gameId: state.game.id,
    groupRollName: state.client.groupRollName,
    token: state.client.token,
    groupRolls: state.groupRolls.list,
    ownerId: state.ownerId,
  }
}

const mapDispatchToProps = { updateGroupRollName }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GM)
