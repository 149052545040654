import React from 'react'
import PropTypes from 'prop-types';
import {
  TRAITS
} from '../../../../constants/monster_constants'

const Traits = (props) => {
  const {
    traits
  } = props

  if (!traits) {return null}

  let renderTraits = traitlist(traits);

  // TODO: Handle onHover for trait text
  // TODO: Handle links
  return (
    <div className='Monster__traits'>
      {renderTraits.map((trait, i) => {
          let name = trait.name
          return (
              <div
                  key={i}
                  className={`Monster__trait Monster__trait--${trait.class}`}
              >
                  {name}
              </div>
          )
      })}
    </div>
  )
}

const isRarity = trait => {
  const rarity = ["common", "uncommon", "rare", "unique"]
  for (let i = 0; i < rarity.length; i++) {
    if (trait.classes.includes(rarity[i])) {
      return rarity[i]
    }
  }
  return null
}

const traitlist = traits => {
  let newTraits = []
  let sizeTraits = []
  let rarityTraits = []
  let alignmentTraits = []
  if (!traits) {
    return []
  }
  // Sort traits into buckets
  traits.forEach(trait => {
    if (trait.classes.includes('alignment')) {
      alignmentTraits.push(trait)
    } else if (trait.classes.includes('size')) {
      sizeTraits.push({'name': trait.name, 'class': 'size', 'trait': trait})
    } else if (isRarity(trait)) {
      rarityTraits.push({'name': trait.name, 'class': isRarity(trait), 'trait': trait})
    } else {
      newTraits.push({'name': trait.name, 'class': 'general', 'trait': trait})
    }
  });

  // merge buckets
  let alignmentAbbrev = alignmentTraits.map(t => t.name.split(' ').map(n => n.charAt(0)).join('')).join('');
  alignmentAbbrev = alignmentAbbrev == "A" ? "Any" : alignmentAbbrev
  let renderTraits = []
  rarityTraits.forEach(t => {
    renderTraits.push(t)
  })
  if (alignmentAbbrev) {
    renderTraits.push({'name': alignmentAbbrev, 'class': 'alignment'})
  }
  sizeTraits.forEach(t => {
    renderTraits.push(t)
  })
  newTraits.forEach(t => {
    renderTraits.push(t)
  })
  return renderTraits
}

//Traits.propTypes = PropTypes.shape(TRAITS)

export default Traits

/* Rarity Examples
Mari Lwyd: Uncommon
Ancient Magma Dragon: Rare
Ainamuuren: Unique
*/

/* Traits Examples
Mari Lwyd:

"traits": [
    {
        "name": "Uncommon",
        "type": "trait",
        "sources": [
            {
                "type": "source",
                "name": "Core Rulebook",
                "link": {
                    "type": "link",
                    "name": "Core Rulebook pg. 637",
                    "alt": "Core Rulebook pg. 637",
                    "game-obj": "Sources",
                    "aonid": 1
                },
                "page": 637,
                "errata": {
                    "type": "link",
                    "name": "4.0",
                    "alt": "4.0",
                    "game-obj": "Sources",
                    "aonid": 1
                }
            }
        ],
        "game-obj": "Traits",
        "game-id": "e2d69320fc94de70210ee7c7d43fd27b",
        "text": "Something of uncommon rarity requires special training or comes from a particular culture or part of the world. Some character choices give access to uncommon options, and the GM can choose to allow access for anyone. Less is known about uncommon creatures than common creatures. They typically can't be summoned. The DC of Recall Knowledge checks related to these creature is increased by 2.",
        "classes": [
            "actions",
            "ancestries",
            "armor",
            "backgrounds",
            "curses",
            "diseases",
            "equipment",
            "feats",
            "focus_spells",
            "hazards",
            "languages",
            "monsters",
            "rituals",
            "spells",
            "uncommon",
            "vehicles",
            "weapons"
        ],
        "links": [
            {
                "type": "link",
                "name": "Recall Knowledge",
                "alt": "Recall Knowledge",
                "game-obj": "Actions",
                "aonid": 26
            }
        ]
    },
    {
        "name": "Chaotic",
        "type": "trait",
        "sources": [
            {
                "type": "source",
                "name": "Core Rulebook",
                "link": {
                    "type": "link",
                    "name": "Core Rulebook pg. 629",
                    "alt": "Core Rulebook pg. 629",
                    "game-obj": "Sources",
                    "aonid": 1
                },
                "page": 629,
                "errata": {
                    "type": "link",
                    "name": "4.0",
                    "alt": "4.0",
                    "game-obj": "Sources",
                    "aonid": 1
                }
            }
        ],
        "game-obj": "Traits",
        "game-id": "213a025fa0c9f9beb3271a265a7d05e8",
        "text": "Chaotic effects often manipulate energy from chaos-aligned Outer Planes and are anathema to lawful divine servants or divine servants of lawful deities. A creature with this trait is chaotic in alignment.",
        "classes": [
            "alignment",
            "equipment",
            "spells"
        ]
    },
    {
        "name": "Neutral",
        "type": "trait",
        "sources": [
            {
                "type": "source",
                "name": "Core Rulebook",
                "link": {
                    "type": "link",
                    "name": "Core Rulebook pg. 29",
                    "alt": "Core Rulebook pg. 29",
                    "href": "https://paizo.com/products/btq01y0k?Pathfinder-Core-Rulebook"
                },
                "page": 29,
                "errata": {
                    "type": "link",
                    "name": "2.0",
                    "alt": "2.0",
                    "game-obj": "Sources"
                }
            }
        ],
        "game-obj": "Traits",
        "game-id": "6e82bdbf68157ae4da17bb888bf012d6",
        "classes": [
            "alignment"
        ]
    },

    {
        "name": "Large",
        "type": "trait",
        "sources": [
            {
                "type": "source",
                "name": "Core Rulebook",
                "link": {
                    "type": "link",
                    "name": "Core Rulebook pg. 474",
                    "alt": "Core Rulebook pg. 474",
                    "href": "https://paizo.com/products/btq01y0k?Pathfinder-Core-Rulebook"
                },
                "page": 474,
                "errata": {
                    "type": "link",
                    "name": "2.0",
                    "alt": "2.0",
                    "game-obj": "Sources"
                }
            }
        ],
        "game-obj": "Traits",
        "game-id": "448cf113826665b98a61ca5d9d068bee",
        "classes": [
            "size"
        ],
        "text": "*Space:* 10 feet, *Reach (Tall):* 10 feet, *Reach (Long):* 5 feet"
    },
    {
        "name": "Fey",
        "type": "trait",
        "sources": [
            {
                "type": "source",
                "name": "Core Rulebook",
                "link": {
                    "type": "link",
                    "name": "Core Rulebook pg. 631",
                    "alt": "Core Rulebook pg. 631",
                    "game-obj": "Sources",
                    "aonid": 1
                },
                "page": 631,
                "errata": {
                    "type": "link",
                    "name": "4.0",
                    "alt": "4.0",
                    "game-obj": "Sources",
                    "aonid": 1
                }
            }
        ],
        "game-obj": "Traits",
        "game-id": "b891dcc29c0a2a11bb5a38ea7ba63bef",
        "text": "Creatures of the First World are called the fey.",
        "classes": [
            "ancestries",
            "creature_type",
            "eidolons",
            "equipment",
            "monsters",
            "npcs"
        ],
        "links": [
            {
                "type": "link",
                "name": "First World",
                "alt": "First World",
                "game-obj": "Planes",
                "aonid": 10
            }
        ]
    }
],


*/