import React from 'react'
import { connect } from 'react-redux'
import cn from 'classnames'

import { closeReleaseNotes  } from '../../actions'
import Modal from '../components/Modal'
import './ReleaseNotes.css'

const ReleaseNotes = (props) => {
  const {
    releaseNotes,
    closeReleaseNotes
  } = props

  const handleClose = () => {
    closeReleaseNotes()
  }

  return (
    <Modal title="What's New?" onClose={handleClose} size='small'>
      {Object.keys(releaseNotes).map((k,i) => {
        return (
          <div className='ReleaseNote' key={i}>
            <div className='ReleaseNote__title'>{k}: {releaseNotes[k].title}</div>
            <div className='ReleaseNote__note'>{releaseNotes[k].note}</div>
          </div>
        )
      })}
    </Modal>
  )
}

const mapStateToProps = (state) => {
  return {
    releaseNotes: state.client.releaseNotes
  }
}

const mapDispatchToProps = { closeReleaseNotes }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReleaseNotes)
