import mixpanel from 'mixpanel-browser'
import {
  MP_CREATE_VARIABLE,
  MP_UPDATE_VARIABLE,
  MP_DELETE_VARIABLE,
} from '../constants/mixpanel_events'

export async function create(values, characterId, token) {
  const response = await fetch(`${baseUrl()}/${characterId}/variables`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': token
    },
    body: JSON.stringify(values)
  })
  mixpanel.track(MP_CREATE_VARIABLE, values)
  const variableData = await response.json()
  return variableData
}

export async function update(values, variableId, characterId, token) {
  const response = await fetch(`${baseUrl()}/${characterId}/variables/${variableId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': token
    },
    body: JSON.stringify(values)
  })

  mixpanel.track(MP_UPDATE_VARIABLE, values)
  const variableData = await response.json()
  return variableData
}

export async function destroy(variableId, characterId, token) {
  const response = await fetch(`${baseUrl()}/${characterId}/variables/${variableId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': token
    }
  })

  mixpanel.track(MP_DELETE_VARIABLE)
  const variableData = await response.json()
  return variableData
}

function baseUrl() {
  return `${window.location.protocol}//${location.host}/characters`
}
