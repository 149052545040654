/* Avatar */
export const OPEN_ADD_AVATAR = 'OPEN_ADD_AVATAR'
export const CLOSE_ADD_AVATAR = 'CLOSE_ADD_AVATAR'
export const ADD_AVATAR = 'ADD_AVATAR'
export const DESTROY_AVATAR = 'DESTROY_AVATAR'

/* Character */
export const ADD_CHARACTER = 'ADD_CHARACTER'
export const UPDATE_CHARACTER = 'UPDATE_CHARACTER'
export const DESTROY_CHARACTER = 'DESTROY_CHARACTER'
export const UPDATE_NEW_CHARACTER_NAME = 'UPDATE_NEW_CHARACTER_NAME'
export const UPDATE_NEW_CHARACTER_AVATAR_URL = 'UPDATE_NEW_CHARACTER_AVATAR_URL'

/* Feedback */
export const OPEN_FEEDBACK = 'OPEN_FEEDBACK'
export const CLOSE_FEEDBACK = 'CLOSE_FEEDBACK'

/* Game */
export const UPDATE_GAME = 'UPDATE_GAME'
export const REORDER_CHARACTERS = 'REORDER_CHARACTERS'

/* Dashboard */
export const OPEN_DASHBOARD = 'OPEN_DASHBOARD'
export const CLOSE_DASHBOARD = 'CLOSE_DASHBOARD'

/* Group Rolls */
export const UPDATE_GROUP_ROLL_NAME = 'UPDATE_GROUP_ROLL_NAME'
export const ADD_GROUP_ROLL = 'ADD_GROUP_ROLL'
export const UPDATE_GROUP_ROLL = 'UPDATE_GROUP_ROLL'
export const DESTROY_GROUP_ROLL = 'DESTROY_GROUP_ROLL'
export const UPDATE_GROUP_ROLL_SHORTCUT_COMMAND = 'UPDATE_GROUP_ROLL_SHORTCUT_COMMAND'

/* Invitation */
export const UPDATE_NEW_INVITATION = 'UPDATE_NEW_INVITATION'
export const ADD_INVITATION = 'ADD_INVITATION'
export const DESTROY_INVITATION = 'DESTROY_INVITATION'

/* Monsters */
export const FETCH_MONSTERS_BEGIN = 'FETCH_MONSTERS_BEGIN'
export const FETCH_MONSTERS_SUCCESS = 'FETCH_MONSTERS_SUCCESS'
export const FETCH_MONSTERS_FAILURE = 'FETCH_MONSTERS_FAILURE'
export const UPDATE_MONSTER_SEARCH = 'UPDATE_MONSTER_SEARCH'
export const CHOOSE_MONSTER = 'CHOOSE_MONSTER'
export const CLEAR_MONSTER_SEARCH = 'CLEAR_MONSTER_SEARCH'
export const REMOVE_MONSTER = 'REMOVE_MONSTER'
export const FETCH_MONSTER_BEGIN = 'FETCH_MONSTER_BEGIN'
export const FETCH_MONSTER_SUCCESS = 'FETCH_MONSTER_SUCCESS'
export const FETCH_MONSTER_FAILURE = 'FETCH_MONSTERS_FAILURE'

/* Release Notes */
export const OPEN_RELEASE_NOTES = 'OPEN_RELEASE_NOTES'
export const CLOSE_RELEASE_NOTES = 'CLOSE_RELEASE_NOTES'

/* Registry */
export const ADD_REGISTRY = 'ADD_REGISTRY'
export const DESTROY_REGISTRY = 'DESTROY_REGISTRY'
export const UPDATE_REGISTRY = 'UPDATE_REGISTRY'

/* Rolls */
export const UPDATE_ROLL_COMMAND = 'UPDATE_ROLL_COMMAND'
export const ADD_ROLL = 'ADD_ROLL'
export const FILTER_ROLLS = 'FILTER_ROLLS'

/* Settings */
export const OPEN_SETTINGS = 'OPEN_SETTINGS'
export const CLOSE_SETTINGS = 'CLOSE_SETTINGS'

/* Shortcut */
export const SET_SHORTCUT_SORT_ORDER = 'SET_SHORTCUT_SORT_ORDER'
export const UPDATE_NEW_SHORTCUT_LABEL = 'UPDATE_NEW_SHORTCUT_LABEL'
export const UPDATE_NEW_SHORTCUT_COMMAND = 'UPDATE_NEW_SHORTCUT_COMMAND'
export const OPEN_EDIT_SHORTCUT = 'OPEN_EDIT_SHORTCUT'
export const CLOSE_EDIT_SHORTCUT = 'CLOSE_EDIT_SHORTCUT'
export const ADD_SHORTCUT = 'ADD_SHORTCUT'
export const DESTROY_SHORTCUT = 'DESTROY_SHORTCUT'
export const UPDATE_SHORTCUT = 'UPDATE_SHORTCUT'

/* Variables */
export const ADD_VARIABLE = 'ADD_VARIABLE'
export const DESTROY_VARIABLE = 'DESTROY_VARIABLE'
export const UPDATE_VARIABLE = 'UPDATE_VARIABLE'

/* Misc */
export const SWITCH_PANEL = 'SWITCH_PANEL'

/* History */
export const ADD_TRACKED_ROLL = 'ADD_TRACKED_ROLL'

/* Initiative Queue */
export const REORDER_INITIATIVE_QUEUE_ITEMS = 'REORDER_INITIATIVE_QUEUE_ITEMS'

/* Encounters */
export const ADD_ENCOUNTER = 'ADD_ENCOUNTER'
export const UPDATE_ENCOUNTER = 'UPDATE_ENCOUNTER'
export const DESTROY_ENCOUNTER = 'DESTROY_ENCOUNTER'
export const UPDATE_NEW_ENCOUNTER_NAME = 'UPDATE_NEW_ENCOUNTER_NAME'
export const UPDATE_ENCOUNTER_NAME = 'UPDATE_ENCOUNTER_NAME'
export const SELECT_ENCOUNTER = 'SELECT_ENCOUNTER'

/* Encounter Monsters */
export const ADD_ENCOUNTER_MONSTER = 'ADD_ENCOUNTER_MONSTER'
export const UPDATE_ENCOUNTER_MONSTER = 'UPDATE_ENCOUNTER_MONSTER'
export const DESTROY_ENCOUNTER_MONSTER = 'DESTROY_ENCOUNTER_MONSTER'
