import React, {useState} from 'react'

import './EncounterMonster.css'

import VisibilityIcon from '@material-ui/icons/Visibility'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import CancelIcon from '@material-ui/icons/Cancel'
import SaveIcon from '@material-ui/icons/Save'

const EncounterMonster = (props) => {
  const {
    encounterMonster,
    monster,
    onDamageMonster,
    onViewMonster,
    onDestroyMonster,
    onUpdateNick,
  } = props

  const [damage, setDamage] = useState(0)
  const [editing, setEditing] = useState(false)
  const [nick, setNick] = useState(encounterMonster.nickname)

  const handleChangeDamage = e => {
    const value = e.target.value;
    setDamage(value);
  }

  const handleDamageMonster = () => {
    onDamageMonster(encounterMonster.health - damage)
  }

  const handleDestroyMonster = () => {
    if (confirm(`Are you sure you want to destroy '${monster.name}'?`)) {
      onDestroyMonster()
    }
  }

  const handleToggleEditMonster = () => {
    setEditing(!editing)
  }

  const handleNickEdit = e => {
    setNick(e.target.value)
  }

  const handleNickSave = () => {
    onUpdateNick(nick)
    setEditing(false)
  }

  if (editing) {
    return(
      <div className='EncounterMonster' key={encounterMonster.id}>
        <div className='EncounterMonster__actions'>
          <button className='EncounterMonster__edit' onClick={handleToggleEditMonster}>
            <CancelIcon style={{ fontSize: 13 }} />
          </button>
        </div>
        Nickname:
        <input className='EncounterMonster__nickInput' value={nick} onChange={handleNickEdit} name='monsterNick' />
        <button className='EncounterMonster__nickSave' onClick={handleNickSave}>
          <SaveIcon style={{ fontSize: 13 }} />
        </button>

      </div>
    )
  } else {
    return (
      <div className='EncounterMonster' key={encounterMonster.id}>
        <div className='EncounterMonster__actions'>
          <button className='EncounterMonster__edit' onClick={handleToggleEditMonster}>
            <EditIcon style={{ fontSize: 13 }} />
          </button>
          <button className='EncounterMonster__view' onClick={onViewMonster}>
            <VisibilityIcon style={{ fontSize: 13 }} />
          </button>
          <button className='EncounterMonster__delete' onClick={handleDestroyMonster}>
            <DeleteIcon style={{ fontSize: 13 }} />
          </button>
        </div>

        <div className='EncounterMonster__name'>
          <span className='EncounterMonster__monsterName'>{monster.name}</span>
          <span className='EncounterMonster__nickname'>{encounterMonster.nickname ? `(${encounterMonster.nickname})` : null}</span>
        </div>
        <div className='EncounterMonster__health'>
          <div className='EncounterMonster__hp'>HP: {encounterMonster.health}/{encounterMonster.max_health}</div>
          <input className='EncounterMonster__damage' type='number' value={damage} onChange={handleChangeDamage} name='monsterDamage' />
          <button className='EncounterMonster__saveDamage' onClick={handleDamageMonster}>
            Subtract HP
          </button>
        </div>
      </div>
    )
  }
}

export default EncounterMonster
