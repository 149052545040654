import React from 'react'

const DateStamp = ({date}) => {
  const dt = new Date(date)
  const day = new Date().toLocaleDateString("en-US")
  const time = new Date().toLocaleTimeString("en-US")
  return(
    <span className="--tooltiptext">{day}<br/>{time}</span>
  )
}

export default DateStamp
