import React from 'react'
import { connect } from 'react-redux'
import { chooseMonster, updateMonsterSearch, loadMonster, removeMonster } from '../../actions'
import MonsterSearch from '../components/MonsterSearch'
import Monster from './Monster'

import './Monsters.css'

const Monsters = props => {
  const {
    chooseMonster,
    error,
    loading,
    loadMonster,
    loadedMonster,
    monsters,
    removeMonster,
    selectedMonsters,
  } = props

  const renderLoading = () => {
    if (loading) {
      return <div>Loading...</div>
    }
  }

  const renderError = () => {
    if (error) {
      return <div>Error! {error.message}</div>
    }
  }

  const handleResultClick = monsterId => {
    chooseMonster(monsterId)
  }

  const handleLoadMonster = monsterId => {
    const monsterToLoad = monsters[monsterId]
    loadMonster(monsterToLoad)
  }

  const handleRemoveMonster = monsterId => {
    removeMonster(monsterId)
  }

  return (
    <div className='Monsters'>
      {renderLoading()}
      {renderError()}

      <div className='Monsters__search'>
        <MonsterSearch label='Add Monster' onSelectMonster={handleResultClick} />

        <div className='Monsters__selected'>
          {selectedMonsters.map(monsterId => {
            const monster = monsters[monsterId]
            if (monster === undefined) {
              return null;
            }

            const loadFn = handleLoadMonster.bind(null, monsterId)
            const removeFn = handleRemoveMonster.bind(null, monsterId)
            return (
              <div
                key={monster.id}
                className='Monsters__monster'
              >
                <span className='Monsters__monsterName' onClick={loadFn}>{monster.name}</span>
                <span className='Monsters__monsterRemove' onClick={removeFn}>x</span>
              </div>
            )
          })}
        </div>

        <div className='Monsters__detail'>
          {loadedMonster ? (
            <Monster />
          ) : (
            <div>
              Search for a monster above and add it to your list, then select it
              to see details here.
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    error: state.monsters.error,
    loading: state.monsters.loading,
    loadedMonster: state.monsters.loadedMonster,
    monsters: state.monsters.list,
    selectedMonsters: state.monsters.selectedMonsters,
  }
}

const mapDispatchToProps = {
  chooseMonster,
  loadMonster,
  removeMonster,
}

export default connect(mapStateToProps, mapDispatchToProps)(Monsters)
