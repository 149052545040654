import React from 'react'
import cn from 'classnames'

import d20 from '../../assets/d20_wireframe.png'

import * as rollService from '../../services/rolls'
import * as shortcutService from '../../services/shortcuts'
import * as groupRollService from '../../services/groupRoll'
import Avatar from './Avatar'
import RollResults from './RollResults'
import DateStamp from './DateStamp'
import * as dice from '../../lib/dice'

import './GroupRoll.css'

const GroupRoll = ({
  label, id, created_at, characters, rolls, ownerId, characterId, gameId, shortcuts, variables, groupRollDefaults,
  active, token, updateGroupRollShortcutCommand, groupRollShortcutCommand }) => {

  const character = characters[characterId]
  const shortcut = Object.values(shortcuts).filter(sc => sc.label.toLowerCase() === label.toLowerCase())[0]
  const isGM = character.id === ownerId
  let characterValues = Object.values(characters)
  characterValues = characterValues.filter(char => char.id !== ownerId)

  characterValues.sort((c1, c2) => {
    const c1Roll = rolls.filter(r => r.character_id === c1.id)[0]
    const c2Roll = rolls.filter(r => r.character_id === c2.id)[0]

    if (c1Roll && c2Roll) {
      if (c1Roll.total > c2Roll.total) {
        return -1
      } else if (c1Roll.total < c2Roll.total) {
        return 1
      }
    } else if (c1Roll && !c2Roll) {
      return -1
    } else if (!c1Roll && c2Roll) {
      return 1
    } else {
      return 0
    }
  })

  const handleUseShortcut = () => {
    if (shortcut.structured_formula) {
      rollService.create({
        token: token,
        label: shortcut.label,
        gameId: gameId,
        structuredFormula: dice.getCollapsedFormula(shortcut.structured_formula, variables),
        groupRollId: id})
    } else {
      rollService.create({
        token: token,
        label: shortcut.label,
        gameId: gameId,
        formula: shortcut.value,
        groupRollId: id})
    }
  }

  const handleAddShortcut = async () => {
    await shortcutService.create(
      label,
      groupRollShortcutCommand,
      characterId,
      token,
    )

    rollService.create({
      token: token,
      label: label,
      gameId: gameId,
      formula: groupRollShortcutCommand,
      groupRollId: id})
    updateGroupRollShortcutCommand('')
  }

  const handleSkip = async(currentCharacterId) => {
    const skipFormula = {
      name: "Skip",
      formulas: [{
        label: "Skip",
        note: "Skip"
      }]
    }
    rollService.create({
      token: token,
      label: "Skip",
      gameId: gameId,
      structuredFormula: skipFormula,
      groupRollId: id,
      characterId: currentCharacterId})
  }

  const handleDefaultRoll = async(currentCharacterId, defaultRoll) => {
    if (defaultRoll.structured_formula) {
      rollService.create({
        token: token,
        label: defaultRoll.label,
        gameId: gameId,
        structuredFormula: dice.getCollapsedFormula(defaultRoll.structured_formula, variables),
        groupRollId: id,
        characterId: currentCharacterId})
    } else {
      rollService.create({
        token: token,
        label: defaultRoll.label,
        gameId: gameId,
        formula: defaultRoll.formula,
        groupRollId: id,
        characterId: currentCharacterId})
    }
  }

  const handleDeleteGroupRoll = async () => {
    groupRollService.destroy(
      id,
      token,
    )
  }

  const handleGroupRollShortcutCommand = (e) => {
    updateGroupRollShortcutCommand(e.target.value)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleAddShortcut()
    }
  }

  const renderRollOptions = (e) => {
    if (!active) { return null }
    if (isGM) {
      return (
        <div className='GroupRoll__shortcut'>
          <div className='GroupRoll__shortcutForm'>
            <button className='GroupRoll__delete' onClick={handleDeleteGroupRoll}>Delete Group Roll</button>
          </div>
        </div>
      )
    }
    const roll = rolls.filter(r => r.character_id === characterId)[0]

    if (!shortcut && !roll) {
      return (
        <div className='GroupRoll__shortcut'>
          <div className='GroupRoll__shortcutMessage'>Create a shortcut for "{label}"</div>
          <div className='GroupRoll__shortcutForm'>
            <input type='text'
                name='shortcutValue'
                value={groupRollShortcutCommand}
                onChange={handleGroupRollShortcutCommand}
                className='GroupRoll__shortcutValue'
                placeholder='1d20+3, etc'
                onKeyDown={handleKeyDown}
            />
            <button className='GroupRoll__shortcutSave' onClick={handleAddShortcut}>Save and Roll</button>
          </div>
        </div>
      )
    }
    return null
  }

  const getGroupRollDefault = (currentCharacterId) => {
    if (label in groupRollDefaults) {
      const defaults = groupRollDefaults[label]
      if (currentCharacterId in defaults) {
        return defaults[currentCharacterId]
      }
    }
    return {
      "label": "Initiative",
      "formula": "1d20"
    }
  }

  const maybeRenderRollButton = (currentCharacterId) => {
    if (active === false) { return null }

    if (isGM) {
      const defaultRoll = getGroupRollDefault(currentCharacterId)
      let printableValue = "1d20"
      if ('formula' in defaultRoll) {
        printableValue = defaultRoll.formula
      } else if ('structured_formula' in defaultRoll) {
        printableValue = dice.getPrintableValue(defaultRoll.structured_formula, variables)
      }
      return (
        <div className='GroupRoll__rollGm'>
          <button onClick={handleSkip.bind(this, currentCharacterId)}>Skip</button>
          <button onClick={handleDefaultRoll.bind(this, currentCharacterId, defaultRoll)}>{printableValue}</button>
        </div>
      )
    } else if (shortcut && currentCharacterId === characterId) {
      return (
        <button onClick={handleUseShortcut}>Roll</button>
      )
    }
    return null
  }

  const groupRollClasses = cn({
    'GroupRoll': true,
    'GroupRoll--inactive': active === false,
    'GroupRoll--active': active === true,
  })

  return (
    <div className={groupRollClasses}>
      <span className='--tooltip'>
        <img src={d20} className='GroupRoll__icon' />
        <DateStamp date={created_at} />
      </span>
      <div className='GroupRoll__content'>
        <div className='GroupRoll__header'>
          <span className='GroupRoll__title'>GM calls for: </span>
          <span className='GroupRoll__label'>{label}</span>
        </div>
        <div className='GroupRoll__status'>
          {characterValues.map((char) => {
            const roll = rolls.filter(r => r.character_id === char.id)[0]
            const buttonFn = maybeRenderRollButton.bind(this, char.id)
            const avatarClasses = cn({
              'GroupRoll__avatar': true,
              'GroupRoll__avatar--complete': roll !== undefined
            })
            return (
              <div key={char.id} className='GroupRoll__roll --tooltip'>
                <div className={avatarClasses}>
                  <Avatar
                    id={char.id}
                    name={char.name}
                    ownerId={ownerId}
                    avatarUrl={char.avatar_url}
                    kind='roll'
                  />
                  <span className='GroupRoll__rollStatus'>{roll === undefined ? '🕓' : '✓'}</span>
                </div>
                <div className='GroupRoll__rollResult'>
                  {roll !== undefined ? roll.total : buttonFn()}
                </div>
                {roll ? <span className='--tooltiptext'><RollResults roll={roll}/></span> : null}
              </div>
            )
          })}
        </div>
        <div className='GroupRoll__RollOptions'>
          {renderRollOptions()}
        </div>
      </div>
    </div>
  )
}

export default GroupRoll
