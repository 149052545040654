import FuzzySet from 'fuzzyset'
import {
  CHOOSE_MONSTER,
  CLEAR_MONSTER_SEARCH,
  FETCH_MONSTERS_BEGIN,
  FETCH_MONSTERS_FAILURE,
  FETCH_MONSTERS_SUCCESS,
  FETCH_MONSTER_BEGIN,
  FETCH_MONSTER_FAILURE,
  FETCH_MONSTER_SUCCESS,
  REMOVE_MONSTER,
  UPDATE_MONSTER_SEARCH,
} from '../constants/action-types'

const initialState = {
  activeMonsterId: null,
  error: null,
  list: [],
  loading: false,
  loadedMonster: null,
  nameLookup: {},
  query: '',
  searchResults: [],
  selectedMonsters: [],
}

const fuzzySet = FuzzySet()

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MONSTERS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case FETCH_MONSTERS_SUCCESS:
      const monsterData = action.payload

      let newNameLookup = {}
      let names = []
      Object.keys(monsterData).forEach(monsterId => {
        const monster = monsterData[monsterId]
        newNameLookup[monster.name] = monsterId
        names.push(monster.name)
      })
      names.sort()
      names.forEach(name => {
        fuzzySet.add(name)
      })

      return {
        ...state,
        loading: false,
        list: monsterData,
        nameLookup: newNameLookup,
      }
    case FETCH_MONSTERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        items: [],
      }
    case UPDATE_MONSTER_SEARCH:
      const query = action.payload

      let searchResults = []
      if (query !== '') {
        const fuzzyResults = fuzzySet.get(query)
        if (fuzzyResults) {
          fuzzyResults.forEach(result => {
            searchResults.push(state.nameLookup[result[1]])
          })
        }
      }

      return {
        ...state,
        query: query,
        searchResults: searchResults,
      }
    case CHOOSE_MONSTER:
      let newSelectedMonsters = [...state.selectedMonsters]
      newSelectedMonsters.push(action.payload)

      return {
        ...state,
        query: '',
        searchResults: [],
        selectedMonsters: newSelectedMonsters,
      }
    case CLEAR_MONSTER_SEARCH:
      return {
        ...state,
        query: '',
        searchResults: [],
      }
    case REMOVE_MONSTER:
      let removedMonsters = state.selectedMonsters.filter((monsterId) => {
        return monsterId !== action.payload
      })

      return {
        ...state,
        selectedMonsters: removedMonsters,
      }
    case FETCH_MONSTER_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case FETCH_MONSTER_SUCCESS:
      const monsterDetails = action.payload

      return {
        ...state,
        loading: false,
        loadedMonster: monsterDetails,
      }
    case FETCH_MONSTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        items: [],
      }
    default:
      return state
  }
}
