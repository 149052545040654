import { combineReducers } from 'redux'

import avatars from './avatars'
import characterId from './characterId'
import characters from './characters'
import client from './client'
import encounters from './encounters'
import encounter_monsters from './encounter_monsters'
import game from './game'
import groupRolls from './groupRolls'
import groupRollDefaults from './groupRollDefaults'
import invitations from './invitations'
import monsters from './monsters'
import ownerId from './ownerId'
import registries from './registries'
import rolls from './rolls'
import shortcuts from './shortcuts'
import variables from './variables'
import history from './history'
import initiativeQueue from './initiativeQueue'
import initiativeQueueItems from './initiativeQueueItems'
import user from './user.js'

export default combineReducers({
    avatars,
    characterId,
    characters,
    client,
    encounters,
    encounter_monsters,
    game,
    groupRolls,
    groupRollDefaults,
    invitations,
    monsters,
    ownerId,
    registries,
    rolls,
    shortcuts,
    variables,
    history,
    initiativeQueue,
    initiativeQueueItems,
    user,
})
