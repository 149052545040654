import React from 'react'

const getComplexResults = (sections) => {
  return (
    <span className='Roll_results'>
      <ul>
        {sections.map((data, i) => {
          return (
            <li key={i}>
              {data.label}
              {data.structured_result
                ? <span>{': '}
                  {data.structured_result.map((result, i) => {
                    return (<span key={i}>{getStructuredResult(result)}</span>)
                  })}
                </span>
                : null
              }
              {data.note ? <span>{': '}{data.note}</span> : null}
            </li>
          )
        })}
      </ul>
    </span>
  )
}

const getStructuredResult = (result) => {
  let parts = [result.value]
  if(result.tally) {
    parts = result.tally
  }
  let value  = parts.join(",")
  if(result.fumble) {
    return (
      <span className="Roll__special Roll__special--fumble"> {result.sign} ({result.formula} [{value}])</span>
    )
  } else if (result.critical) {
    return (
      <span className="Roll__special Roll__special--critical"> {result.sign} ({result.formula} [{value}])</span>
    )
  } else if (result.formula.search("d") > -1) {
    return (
      <React.Fragment> {result.sign} ({result.formula} [{value}])</React.Fragment>
    )
  } else {
    return (
      <React.Fragment> {result.sign} ({value})</React.Fragment>
    )
  }
}

const getStructuredResults = (structured_results) => {
  if (structured_results) {
    return (
      <span className='Roll_results'>
        {structured_results.map((result, i) => {
          return (<span key={i}>{getStructuredResult(result)}</span>)
        })}
      </span>
    )
  }
  return null
}

const RollResults = ({ roll }) => {
  const roll_type = roll.roll_type
  const structured_results = roll.structured_results

  if (structured_results) {
    if (roll_type == "complex") {
      return getComplexResults(structured_results)
    } else {
      return getStructuredResults(structured_results)
    }
  }
  return null
}

export default RollResults
