import React from 'react'
import { Parser, Dice } from 'rpg-dice-roller';
import * as rollService from '../services/rolls'

export function d20Text(label, value, classname, gameId, token) {
  let modifier = decoratedNumber(value)
  const rollFn = rollService.create.bind(
    null, {
      token: token,
      label: label,
      gameId: gameId,
      formula: `1d20${modifier}`})
  return (
    <span className={classname} onClick={rollFn}>{modifier}</span>
  )
}

export function dOthersText(label, formula, classname, gameId, token) {
  const rollFn = rollService.create.bind(
    null, {
      token: token,
      label: label,
      gameId: gameId,
      formula: formula})
  return (
    <span className={classname} onClick={rollFn}>{formula}</span>
  )
}

export function dComplexText(label, title, structuredFormula, classname, gameId, token) {
  const rollFn = rollService.create.bind(
    null, {
      token: token,
      label: label,
      gameId: gameId,
      structuredFormula: structuredFormula})
  return (
    <span className={classname} onClick={rollFn}>{title}</span>
  )
}

export function getCollapsedFormula(structured_formula, variables) {
  // Returns a copy of a formula that has all variables reduced
  const copy = JSON.parse(JSON.stringify(structured_formula));
  copy.formulas.map((f) => {
    let temp = collapseVariables(f, variables)
    if(temp != "") {
      f.formula = temp
    }
  })
  return copy
}

export function getPrintableValue(structured_formula, variables) {
  // Gets the printable representation of a formula
  let copy = null
  try {
    copy = getCollapsedFormula(structured_formula, variables)
  } catch (e) {
    console.log(e)
    copy = structured_formula
  }
  let formulas = copy.formulas
  let parts = []
  for(var i = 0; i < formulas.length; i++) {
    if(formulas[i].formula) {
      parts.push(formulas[i].formula)
    } else {
      parts.push(formulas[i].label)
    }
  }
  return parts.join(" + ")
}

export function has1d20(formula) {
  try {
    var result = Parser.parse(formula)
    for (let i = 0; i < result.length; i++) {
      let p = result[i]
      if(typeof(p) === 'object') {
        if(p.qty == 1 && p.sides == 20) {
          return true
        }
      }
    }
  } catch (e) {}
  return false
}

const collapseVariables = (roll, variables) => {
  // Loops through variables, and adds them to the formula, then simplifies
  let parts = []
  if(roll.formula) {
    parts.push(roll.formula)
  }
  let count = 0
  if(roll.variables) {
    roll.variables.map((v) => {
      let data = variables[v].structured_data
      if(data.active != false) {
        let component = data.formula
        if(isNaN(Number(component))) {
          if (parts.length > 0) {
            parts.push(decoratedString(component))
          } else {
            parts.push(component)
          }
        } else {
          count += Number(component)
        }
      }
    })
  }
  if (count != 0) {
    if(parts.length > 0) {
      parts.push(decoratedNumber(count))
    } else {
      parts.push(`${count}`)
    }
  }
  let newparts  = parts.join("")
  if(newparts != "") {
    let { formula, plus } = splitFormula(parts.join(""))
    if (plus == 0) {
      return formula
    } else if (formula == "") {
      return `${plus}`
    }
    return `${formula}${decoratedNumber(plus)}`
  }
  return ""
}

const splitFormula = (formula) => {
  // Simplifies a formula down to expressions and constants.
  let plus = 0
  var result = Parser.parse(formula)
  const parts = []
  var sign = ""
  result.map((p) => {
    if(typeof(p) === 'object') {
      parts.push(sign + p.toString())
    } else if (typeof(p) === 'string') {
      sign = p
    } else if (typeof(p) === 'number') {
      if(sign == "+" || sign == "-" || sign == "") {
        plus += parseInt(`${sign}${p}`)
      } else {
        console.log(`Error, unsupported sign ${sign}`)
      }
    }
  })
  return {
    formula: parts.join(""),
    plus: plus
  }
}

const decoratedString = value => {
  if (value.trim().startsWith("-")) {
    return value
  }
  return `+${value}`
}

const decoratedNumber = value => {
  return value > 0 ? `+${value}` : `${value}`
}
