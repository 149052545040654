import React, { useState } from 'react'
import { connect } from 'react-redux'
import Variable from './Variable'
import * as variableService from '../../services/variables'
import './Variables.css'

const Variables = (props) => {
  const {
    variables,
    token,
    characterId,
  } = props

  const [newVariableLabel, setNewVariableLabel] = useState('')
  const [newVariableCommand, setNewVariableCommand] = useState('')

  const variableList = Object.values(variables)
  variableList.sort((a, b) => a.name > b.name ? 1 : -1)

  const handleNewVariableLabelChange = (e) => {
    setNewVariableLabel(e.target.value)
  }

  const handleNewVariableCommandChange = (e) => {
    setNewVariableCommand(e.target.value)
  }

  const handleAddVariable = () => {
    const values = {
      name: newVariableLabel,
      structured_data: {
        label: newVariableLabel,
        formula: newVariableCommand,
        active: true,
      }
    }
    variableService.create(values, characterId, token)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleAddVariable()
    }
  }

  return(
    <div className='Variables'>
      <div className='Variables__list'>
        <div className='Variables__container'>
          {variableList.map((variable) => {
            return (
              <Variable key={variable.id} variable={variable} />
            )
          })}
        </div>
      </div>
      <div className='Variables__controls'>
        <label htmlFor='newLabel'>Name</label>
        <input type='text'
              name='newLabel'
              value={newVariableLabel}
              onChange={handleNewVariableLabelChange}
              className='Variables__newName'
              placeholder='Name your variable'
              onKeyDown={handleKeyDown}
        />
        <label htmlFor='newValue'>Value</label>
        <input type='text'
              name='newValue'
              value={newVariableCommand}
              onChange={handleNewVariableCommandChange}
              className='Variables__newValue'
              placeholder='3, etc'
              onKeyDown={handleKeyDown}
        />
        <button onClick={handleAddVariable}>Add Variable</button>
      </div>
    </div>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    characterId: state.characterId,
    gameId: state.game.id,
    variables: state.variables.list,
    token: state.client.token,
  }
}

const mapDispatchToProps = {
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Variables)
