import React, { useState } from 'react'
import { connect } from 'react-redux'

import { closeFeedback } from '../../actions'
import * as FeedbackService from '../../services/feedback'
import Modal from '../components/Modal'
import './Feedback.css'

const Feedback = (props) => {
  const [message, setMessage] = useState('')
  const [email, setEmail] = useState('')
  const [labels, setLabels] = useState([])
  const [feedbackSent, setFeedbackSent] = useState(false)

  const {
    closeFeedback,
    characterId,
    token,
  } = props

  const handleClose = () => {
    closeFeedback()
  }

  const handleSendFeedback = async () => {
    const result = await FeedbackService.send(
      characterId, email, message, labels.join(','), token
    )

    if (result.story) {
      setFeedbackSent(true)
    }
  }

  const handleToggleLabel = (e) => {
    const label = e.target.value
    if (e.target.checked) {
      setLabels([
        ...labels,
        label
      ])
    } else {
      setLabels(labels.filter(l => l !== label));
    }
  }

  const renderThanks = () => {
    return (
      <>
        <h3>Thank you!</h3>

        <p>We are so glad you are using Lets Roll! and your feedback will make it even better. Now go get rolling!</p>
      </>
    )
  }

  const renderForm = () => {
    return (
      <>
        <h3>Hi!</h3>

        <p>Thanks for taking the time to send us your feedback, it's <strong>really</strong> appreciated!</p>
        <p>If you are reporting a bug, please provide as much detail as possible so we can try to reproduce it.</p>

        <label className='Feedback__label' htmlFor='email'>E-mail</label>
        <input type='text'
          name='email'
          className='Feedback__input'
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <label className='Feedback__label' htmlFor='message'>Message</label>
        <textarea
          name='message'
          className='Feedback__textarea'
          value={message}
          onChange={e => setMessage(e.target.value)}>{message}</textarea>

        <label className='Feedback__label' htmlFor='labels'>Check any/all that apply</label>
        <div><input className='Feedback__checkbox' type="checkbox" onClick={handleToggleLabel} value="bug" /> Bug</div>
        <div><input className='Feedback__checkbox' type="checkbox" onClick={handleToggleLabel} value="idea" /> Product Idea</div>
        <div><input className='Feedback__checkbox' type="checkbox" onClick={handleToggleLabel} value="love" /> ❤</div>

        <button className='Feedback__button' onClick={handleSendFeedback}>Send Feedback</button>
      </>
    )
  }

  return(
    <Modal title='Send Feedback' onClose={handleClose}>
      {feedbackSent ? renderThanks() : renderForm()}
    </Modal>
  )
}

const mapStateToProps = (state) => {
  return {
    characterId: state.characterId,
    token: state.client.token,
  }
}

const mapDispatchToProps = { closeFeedback }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Feedback)
