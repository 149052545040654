import React from 'react'
import RollResults from './RollResults'
import Avatar from '../components/Avatar'
import DateStamp from './DateStamp'

const getClassName = (myRoll, structured_results) => {
  let items = ["Roll"]
  if (myRoll) {
    items.push("Roll--myRoll")
  }
  if (structured_results) {
    for (let i = 0; i < structured_results.length; i++) {
      let result = structured_results[i]
      if (result.fumble) {
        items.push("Roll--fumble")
        break
      } else if (result.critical) {
        items.push("Roll--critical")
        break
      }
    }
  }
  return items.join(" ")
}

const getIcon = (character, ownerId, roll) => {
  return (
    <div className='Roll__avatar --tooltip'>
      <Avatar
        key={character.id}
        id={character.id}
        name={character.name}
        ownerId={ownerId}
        avatarUrl={character.avatarUrl}
        kind='roll'
      />
      <img src={character.avatar_url} className='Roll__portrait' />
      <DateStamp date={roll.created_at} />
    </div>
  )
}

const getDetails = (name, roll) => {
  const special = getSpecial(roll.structured_results)
  const roll_name = getRollName(roll)
  return (
    <div className='Roll__details'>
      <span className='Roll__character'>{name} </span>
      rolled
      <span className='Roll__original'> {roll_name} </span>
      {special}
      <br />
      <RollResults roll={roll} />
    </div>
  )
}

const getSpecial = (structured_results) => {
  if (structured_results) {
    for (let i = 0; i < structured_results.length; i++) {
      let result = structured_results[i]
      if (result.fumble) {
        return (<strong className="Roll__special Roll__special--fumble"> [Fumble]</strong>)
        break
      } else if (result.critical) {
        return (<strong className="Roll__special Roll__special--critical"> [Critical]</strong>)
        break
      }
    }
  }
  return null
}

const getRollName = (roll) => {
  if (roll.roll_type == "complex") {
    return roll.label
  } else {
    if (roll.label) {
      return `(${roll.label}) ${roll.formula} `
    } else {
      return roll.formula
    }
  }
}


const getTotal = (roll) => {
  if(roll.roll_type == "simple") {
    return (
      <div className='Roll__totalContainer'>
        <div className='Roll__total'> {roll.total} </div>
      </div>
    )
  } else {
    let t = 0
    if (roll.structured_results) {
      roll.structured_results.map((data, i) => {
        data.total ? t += data.total : null
      })
    }
    return (
      <div className='Roll__totalContainer'>
        <div className='Roll__total'>
          <span>{t}</span>
        </div>

        <table className='Roll__total_element'>
          <tbody>
            {roll.structured_results ? roll.structured_results.map((data, i) => {
              return (
                getTotalElement(data, i)
              )
            }) : null}
          </tbody>
        </table>
      </div>

    )
  }
}

const getTotalElement = (data, i) => {
  let className = ""
  let value = ""
  if(data.total && data.note) {
    if (`${data.total}` == `${data.note}`) {
      value = `${data.total}`
    } else {
      value = `${data.total} (${data.note})`
    }
    className = "Roll__total_element--noticable"
  } else if (data.total) {
    value = `${data.total}`
  } else {
    value = data.note
    className = "Roll__total_element--extra"
  }

  return (
    <tr key={i} className={className}>
      <td>{data.label} </td>
      <td>{value}</td>
    </tr>
  )
}

const Roll = ({ roll, character, characterId, ownerId }) => {
  const { name, id } = character

  const myRoll = characterId === id
  const className = getClassName(myRoll, roll.structured_results)
  const iconOut = getIcon(character, ownerId, roll)
  const detailsOut = getDetails(name, roll)
  const totalOut = getTotal(roll)

  return (
    <div className={className}>
      {iconOut}
      {detailsOut}
      {totalOut}
    </div>
  )
}

export default Roll
