import { UPDATE_GAME } from '../constants/action-types'

export default (state = INITIAL.ownerId, action) => {
  switch(action.type) {
    case UPDATE_GAME:
      return action.payload.user_id
    default:
      return state
  }
}
