import React from 'react'
//import PropTypes from 'prop-types'
/*import {
  SPELLS
} from '../../../../constants/monster_constants'*/
import SpellList from './SpellList'
import * as dice from '../../../../lib/dice'

// Tests
// Succubus: constant: Divine Innate Spells DC 26; 6th dominate; 5th dimension door; 4th dimension door (at will), suggestion (at will); 3rd mind reading (at will); 2nd detect alignment (at will); 1st charm (at will); Constant (5th) tongues
// Axiomite: cantrips, spell attack: Divine Innate Spells DC 27, attack +17; 5th telekinetic haul; 4th dispel magic, divine wrath (lawful), lightning bolt (x3); 3rd haste (x3), paralyze; 2nd telekinetic maneuver; 1st true strike (at will);
// Undine Hydromancer: focus points: Sorcerer Bloodline Spells DC 17, attack +9, (1 Focus Point); 1st elemental toss
// Duergar Bombardier: no DC: Alchemical Formulas (1st) lesser acid flask, lesser alchemist fire, lesser tanglefoot bag
// Devourer: notes: Occult Innate Spells DC 31, see soul spells below; 6th feeblemind, true seeing; 4th confusion, suggestion; 3rd bind undead, paralyze; 2nd death knell; 1st harm

const d20 = (label, value, sharedMonsterData) => {
  const title = `${sharedMonsterData.monsterName} ${label}`
  return dice.d20Text(title, value, 'Monster__roll', sharedMonsterData.gameId, sharedMonsterData.token)
}

const getModifiers = (spells, sharedMonsterData) => {
  let items = []
  if (spells.saving_throw) {
    items.push(` DC ${spells.saving_throw.dc}`)
  }
  if (spells.focus_points) {
    if (spells.focus_points == 1) {
      items.push(` ${spells.focus_points} Focus Point`)
    } else {
      items.push(` ${spells.focus_points} Focus Points`)
    }
  }
  if (spells.notes) {
    items.push.apply(items, spells.notes)
  }
  let label = `${spells.name} Attack`
  let spell_attack = null
  if (spells.spell_attack > 0) {
    spell_attack = spells.spell_attack
  }
  if(items.length == 0 && !spell_attack) {
    return null
  }
  return (
    <span>
      {items.join(', ')}
      {items.length > 0 && spell_attack ? <span>{', '}</span> : null}
      {spell_attack ? <span>attack {d20(label, spell_attack, sharedMonsterData)}</span> : null};
    </span>
  )
}

const Spells = (props) => {
  const {
    spells,
    i,
    gameId,
    monsterName,
    token,
  } = props

  const sharedMonsterData = {
    monsterName: monsterName,
    token: token,
    gameId: gameId,
  }
  if (!spells) {return null}

  let modifiers = getModifiers(spells, sharedMonsterData)

  return (
    <div key={i}>
      <strong>{spells.name}{' '}</strong>
      {modifiers}{' '}
      {spells.spell_list.map((sl, _) => {
        return (<SpellList spell_list={sl} />)
      })}
    </div>
  )
}

//Spells.propTypes = PropTypes.shape(SPELLS)

export default Spells

/*
*/
