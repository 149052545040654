/* Avatar */
export const MP_CHANGE_AVATAR = 'change_avatar'
export const MP_CREATE_AVATAR = 'create_avatar'
export const MP_DELETE_AVATAR = 'delete_avatar'

/* Character */
export const MP_CHANGE_CHARACTER_NAME = 'change_character_name'
export const MP_DELETE_CHARACTER = 'delete_character'

/* Feedback */
export const MP_SEND_FEEDBACK = 'send_feedback'

/* Group Roll */
export const MP_CREATE_GROUP_ROLL = 'create_group_roll'
export const MP_DELETE_GROUP_ROLL = 'delete_group_roll'

/* Invitations */
export const MP_CREATE_INVITATION = 'create_invitation'
export const MP_DELETE_INVITATION = 'delete_invitation'

/* Registry */
export const MP_CREATE_REGISTRY = 'create_registry'
export const MP_UPDATE_REGISTRY = 'update_registry'
export const MP_DELETE_REGISTRY = 'delete_registry'

/* Rolls */
export const MP_ROLL = 'roll'

/* Shortcuts */
export const MP_CREATE_SHORTCUT = 'create_shortcut'
export const MP_UPDATE_SHORTCUT = 'update_shortcut'
export const MP_DELETE_SHORTCUT = 'delete_shortcut'

/* Variables */
export const MP_CREATE_VARIABLE = 'create_variable'
export const MP_UPDATE_VARIABLE = 'update_variable'
export const MP_DELETE_VARIABLE = 'delete_variable'

/* Misc */
export const MP_PROMOTE_USER = 'promote_user'

/* Encounters */
export const MP_CREATE_ENCOUNTER = 'create_encounter'
export const MP_UPDATE_ENCOUNTER = 'update_encounter'
export const MP_DELETE_ENCOUNTER = 'delete_encounter'
