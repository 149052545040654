import React from "react"
import PropTypes from "prop-types";
import Games from "./Games";

class NewCharacter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: props.name || '',
      avatarUrl: props.avatarUrl || ''
    }
    this.handleSubmit_ = this.handleSubmit_.bind(this)
    this.handleNameChange_ = this.handleNameChange_.bind(this)
    this.handleAvatarChange_ = this.handleAvatarChange_.bind(this)
  }

  render () {
    return (
      <div>
        <h1>Create a character for the game: {this.props.gameName}</h1>
        <div className='ContentBox'>
          {this.maybeShowErrors_()}

          <form onSubmit={this.handleSubmit} action='/characters' method='POST'>
            <input type='hidden' name='authenticity_token' value={this.props.token} />
            <input type='hidden' name='character[game_id]' value={this.props.gameId} />
            <label htmlFor='character[name]'>Character Name</label>
            <br />
            <input type='text'
                   name='character[name]'
                   value={this.state.name}
                   onChange={this.handleNameChange_} />
            <br /><br />
            <label htmlFor='character[avatar_url]'>Avatar Url</label>
            <br />
            <input type='text'
                   name='character[avatar_url]'
                   value={this.state.avatarUrl}
                   onChange={this.handleAvatarChange_} />
            <br /><br />
            <input type='submit' value='Create Character' />
          </form>
        </div>
      </div>
    )
  }

  handleNameChange_(e) {
    this.setState({name: e.target.value})
  }

  handleAvatarChange_(e) {
    this.setState({avatarUrl: e.target.value})
  }

  handleSubmit_(e) {
    e.preventDefault()
  }

  maybeShowErrors_() {
    if (this.props.error) {
      return (
        <div className='error'>{this.props.error}</div>
      )
    } else {
      return null
    }
  }
}

NewCharacter.propTypes = {
  name: PropTypes.string,
  avatar_url: PropTypes.string,
  token: PropTypes.string,
  gameId: PropTypes.number,
  gameName: PropTypes.string,
  error: PropTypes.string,
};

export default NewCharacter
