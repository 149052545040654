import React from 'react'
import { connect } from 'react-redux'
import Traits from '../../../components/Monsters/PF2/Traits'
import Grafts from '../../../components/Monsters/PF2/Grafts'
import Knowledges from '../../../components/Monsters/PF2/Knowledges'
import Senses from '../../../components/Monsters/PF2/Senses'
import Languages from '../../../components/Monsters/PF2/Languages'
import Skills from '../../../components/Monsters/PF2/Skills'
import AbilityScores from '../../../components/Monsters/PF2/AbilityScores'
import Gear from '../../../components/Monsters/PF2/Gear'
import Ability from '../../../components/Monsters/PF2/Ability'
import Defense from '../../../components/Monsters/PF2/Defense'
import Offense from '../../../components/Monsters/PF2/Offense'
import MonsterCard from '../../../components/Monsters/MonsterCard'
import { capitalize } from '../../../../lib/utils'

import './Monster.css'

const Monster = props => {
    const { data, token, gameId } = props

    if (!data) {
        return null
    }

    const stat_block = data.stat_block
    const creature_type = stat_block.creature_type
    const stats = stat_block.statistics

    const sharedMonsterData = {
        monsterName: data.name,
        token: token,
        gameId: gameId,
    }

    if (data.schema_version == 1.2) {
      return (
        <div className='Monster'>
            <div className='Monster__header'>
                <div className='Monster__name'>{data.name}</div>
                <div className='Monster__level'>
                    {capitalize(data.type)} {creature_type.level}
                </div>
            </div>

            <hr />
            <Traits traits={creature_type.traits} {...sharedMonsterData} />
            <Grafts grafts={creature_type.grafts} {...sharedMonsterData} />
            <Knowledges knowledges={creature_type.knowledge} {...sharedMonsterData} />
            <hr />
            <Senses senses={stat_block.senses} {...sharedMonsterData} />
            <Languages languages={stats.languages} {...sharedMonsterData} />
            <Skills skills={stats.skills} {...sharedMonsterData} />
            <AbilityScores stats={stats} {...sharedMonsterData} />
            <Gear gear={stat_block.gear} {...sharedMonsterData} />
            {
              stat_block.interaction_abilities ? (
                <div className='Monster__abilities'>
                  {stat_block.interaction_abilities.map((ability, i) => {
                    return (
                      <Ability ability={ability} i={i} {...sharedMonsterData} />
                    )
                  })}
                </div>
              ) : (null)
            }
            <hr />
            <Defense defense={stat_block.defense} {...sharedMonsterData} />
            <hr />

            <Offense offense={stat_block.offense} {...sharedMonsterData} />

            <MonsterCard
                name={data.name}
                image={stat_block.image ? stat_block.image.image : null}
            />
        </div>
      )
    } else {
      return (
        <div className='Monster'>
          <h1>Cannot Render Creature</h1>
          Monster has a schema version that is not supported by this version of the app.<br/>
          Schema version: {data.schema_version}
        </div>
      )
    }
}

const mapStateToProps = state => {
    return {
        data: state.monsters.loadedMonster,
        token: state.client.token,
        gameId: state.game.id,
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Monster)
