import {
  ADD_ENCOUNTER_MONSTER,
  DESTROY_ENCOUNTER_MONSTER,
  UPDATE_ENCOUNTER_MONSTER,
} from "../constants/action-types"

const initialState = {
  list: INITIAL.encounter_monsters,
}

export default (state = initialState, action) => {
  let encounterMonsterId
  let encounterMonsterData

  if (action.payload) {
    encounterMonsterId = action.payload.id
    encounterMonsterData = action.payload
  }

  switch(action.type) {
    case ADD_ENCOUNTER_MONSTER:
      return {
        ...state,
        list: {
          ...state.list,
          [encounterMonsterId]: encounterMonsterData
        }
      }
    case UPDATE_ENCOUNTER_MONSTER:
      return {
        ...state,
        list: {
          ...state.list,
          [encounterMonsterId]: encounterMonsterData
        }
      }
    case DESTROY_ENCOUNTER_MONSTER:
      const { [action.payload.id]: _, ...withoutEncounterMonster } = state.list;
      return {
        ...state,
        list: withoutEncounterMonster,
      }
    default:
      return state
  }
}
