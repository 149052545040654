import React from 'react'
import Modifiers from './Modifiers';
import Save from './Save';

const Saves = (props) => {
  const {
    saves,
    monsterName,
    token,
    gameId
  } = props

  if (!saves) {return null}

  const sharedMonsterData = {
    monsterName: monsterName,
    token: token,
    gameId: gameId,
  }

  return (
    <span className='Monster__saves'>
      <Save save={saves.fort} {...sharedMonsterData} />{', '}
      <Save save={saves.ref} {...sharedMonsterData} />{', '}
      <Save save={saves.will} {...sharedMonsterData} />
      <Modifiers modifiers={saves.modifiers} noparens={true} semicolon={true}/>
    </span>
  )
}

export default Saves

/* Saves Example
Aasimar Redeemer:

"saves": {
    "type": "stat_block_section",
    "subtype": "saves",
    "fort": {
        "type": "stat_block_section",
        "subtype": "save",
        "name": "Fort",
        "value": 12
    },
    "ref": {
        "type": "stat_block_section",
        "subtype": "save",
        "name": "Ref",
        "value": 8
    },
    "modifiers": [
        {
            "type": "stat_block_section",
            "subtype": "modifier",
            "name": "+1 status to all saves vs. disease (against diseases, critical failures become failures)"
        }
    ],
    "will": {
        "type": "stat_block_section",
        "subtype": "save",
        "name": "Will",
        "value": 11
    }

*/
