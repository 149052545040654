import mixpanel from 'mixpanel-browser'
import {
  MP_CREATE_SHORTCUT,
  MP_UPDATE_SHORTCUT,
  MP_DELETE_SHORTCUT,
} from '../constants/mixpanel_events'

export async function create(label, value, characterId, token) {
  const response = await fetch(baseUrl(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': token
    },
    body: JSON.stringify({
      character_id: characterId,
      label: label,
      value: value.toLowerCase(),
    })
  })

  mixpanel.track(MP_CREATE_SHORTCUT, {
    label: label,
    command: value
  })

  const shortcutData = await response.json()
  return shortcutData
}

export async function update(values, shortcutId, token) {
  const response = await fetch(`${baseUrl()}/${shortcutId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': token
    },
    body: JSON.stringify(values)
  })
  mixpanel.track(MP_UPDATE_SHORTCUT, {
    command: values
  })

  const shortcutData = await response.json()
  return shortcutData
}

export async function destroy(shortcutId, token) {
  const response = await fetch(`${baseUrl()}/${shortcutId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': token
    }
  })

  mixpanel.track(MP_DELETE_SHORTCUT)

  const shortcutData = await response.json()
  return shortcutData
}

function baseUrl() {
  return `${window.location.protocol}//${location.host}/shortcuts`
}
