import React from 'react'
import { connect } from 'react-redux'
import { switchPanel } from '../../actions'
import cn from 'classnames'
import './PanelMenu.css'

const PanelMenu = (props) => {
  const {
    characterId,
    currentPanel,
    ownerId,
  } = props

  const panelOptions = ['Shortcuts']
  panelOptions.push('Variables')
  panelOptions.push('Avatars')
  if (characterId === ownerId) {
    panelOptions.push('GM')
    panelOptions.push('Players')
    panelOptions.push('Monsters')
    panelOptions.push('Encounters')
  }

  return (
    <div className='PanelMenu'>
      {panelOptions.map((option, i) => {
        const isCurrent = option === currentPanel
        const isOnly = panelOptions.length === 1

        const optionClasses = cn({
          'PanelMenu__option': true,
          'PanelMenu__option--current': isCurrent && !isOnly,
          'PanelMenu__option--clickable': !isCurrent && !isOnly,
        })

        const switchFn = handleSwitchFn.bind(this, option)
        return (
          <div
            key={i}
            className={optionClasses}
            onClick={switchFn}
          >
            {option}
          </div>
        )
      })}

    </div>
  )

  function handleSwitchFn(option) {
    props.switchPanel(option)
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    currentPanel: state.client.currentPanel,
    characterId: state.characterId,
    ownerId: state.ownerId,
  }
}

const mapDispatchToProps = { switchPanel }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PanelMenu)
