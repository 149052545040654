import React from 'react'
import PropTypes from 'prop-types'
import {
  ATTACK
} from '../../../../constants/monster_constants'
import Action from './Action'
import * as dice from '../../../../lib/dice'

// Tests
// Zebub: effect: Melee Single Action mandibles +12 [+7/+2] (evil, finesse, magical), Damage 1d10+3 piercing plus 1d6 evil and Cocytan filth
// Shining Child: Persistent damage: Melee Single Action fist +25 [+21/+17] (agile, finesse, magical), Damage 3d4+5 bludgeoning plus 4d6 fire and 2d4 persistent fire
// Shining Child: notes: Melee Single Action fire ray +25 [+20/+15] (arcane, evocation, magical, range 100 feet), Damage 3d10+3 fire, plus 2d10 positive damage if the target is undead
// Grothlut: splash: Ranged Single Action digestive spew +7 [+2/-3] (acid, range increment 15 feet, splash), Damage 2d6 acid damage plus 1d6 splash acid damage
// Voidworm: negative bonuses: Melee Single Action tail +9 [+4/-1] (chaotic, finesse, magical), Damage 1d4–1 slashing plus 1d4 chaotic and confounding lash
// Sprite: damage is an integer: Melee Single Action rapier +8 [+3/-2] (deadly 1d8, disarm, finesse, fire, magical), Damage 1d6–3 piercing plus 1 fire

const d20 = (label, value, sharedMonsterData) => {
  const title = `${sharedMonsterData.monsterName} ${label}`
  return dice.d20Text(title, value, 'Monster__roll', sharedMonsterData.gameId, sharedMonsterData.token)
}

const dOthers = (label, formula, sharedMonsterData) => {
  const title = `${sharedMonsterData.monsterName} ${label}`
  return dice.dOthersText(title, formula, 'Monster__roll', sharedMonsterData.gameId, sharedMonsterData.token)
}

const dDamage = (attack, sharedMonsterData) => {
  let formulas = []
  let structured_formula = {name: `${sharedMonsterData.monsterName} ${attack.weapon} damage`, formulas: formulas}
  for (var i = 0; i < attack.damage.length; i++) {
    let damage = attack.damage[i]
    let formula = {}
    if (damage.effect) {
      formula.label = damage.effect
    } else {
      let label_parts = []
      if(damage.persistent) {
        label_parts.push("persistent")
        formula.note = damage.formula
      } else {
        formula.formula = damage.formula
      }
      label_parts.push(damage.damage_type)
      if(damage.splash) {
        label_parts.push("splash")
        formula.note = damage.formula
      }
      if(damage.notes) {
        formula.note = damage.notes
      }
      formula.label = label_parts.join(" ")
    }
    formulas.push(formula)
  }
  const label = `${sharedMonsterData.monsterName} ${attack.weapon} Damage`
  return dice.dComplexText(label, 'Damage', structured_formula, 'Monster__roll', sharedMonsterData.gameId, sharedMonsterData.token)
}

const getTraits = (attack) => {
  if (attack.traits && attack.traits.length > 0) {
    return ` (${attack.traits.map(t => t.value ? `${t.name} ${t.value}` : t.name).join(', ')}),`
  }
  return ""
}

const setLabel = (attack) => {
  for (var j = 0; j < attack.damage.length; j++) {
    var label = []
    var types = []
    label.push(`${attack.weapon}:`)
    var d = attack.damage[j]
    d.persistent ? types.push("persistent") : null
    d.damage_type ? types.push(d.damage_type) : null
    d.splash ? types.push("splash") : null
    if (types.length > 0) {
      let type = types.join(" ")
      label.push(`(${type})`)
    }
    d.notes ? label.push(d.notes) : null
    d.label = label.join(" ")
  }
}

const getDamage = (attack, sharedMonsterData) => {
  //TODO: Handle links
  if (attack.damage && attack.damage.length > 0) {
    return (
      <React.Fragment>
        <strong> {dDamage(attack, sharedMonsterData)}</strong>&nbsp;
        {attack.damage.map((d, i) => {
          return (
            <span key={i}>
              {i == 0 ? null : <React.Fragment>, </React.Fragment>}
              {
                d.formula ? dOthers(d.label, d.formula, sharedMonsterData) : null
              }
              {
                d.effect ? <React.Fragment>{' '}{d.effect}</React.Fragment> : null
              }
              {
                d.persistent ? <React.Fragment>{' '}persistent</React.Fragment> : null
              }
              {
                d.splash ? <React.Fragment>{' '}splash</React.Fragment> : null
              }
              {
                d.damage_type ? <React.Fragment>{' '}{d.damage_type}</React.Fragment> : null
              }
              {
                d.notes ? <React.Fragment>{' '}{d.notes}</React.Fragment> : null
              }
            </span>
          )
        })}
      </React.Fragment>
    )
  }
  return ""
}

const getBonuses = (attack, sharedMonsterData) => {
  //TODO: Handle links
  if (attack.bonus) {
    return (
      <React.Fragment>
        {d20(`${attack.name} ${attack.weapon} (1st attack)`, attack.bonus.bonuses[0], sharedMonsterData)}
        {' '}
        [{
          d20(`${attack.name} ${attack.weapon} (2nd attack)`, attack.bonus.bonuses[1], sharedMonsterData)
        }/{
          d20(`${attack.name} ${attack.weapon} (3rd attack)`, attack.bonus.bonuses[2], sharedMonsterData)
        }]
      </React.Fragment>
    )
  }
  return ""
}

const requirements = (attack) => {
  if (!attack.requirement) {
    return null
  }
  return (
    <React.Fragment>
      <strong>Requirements</strong>&nbsp;
      {attack.requirement};&nbsp;
      <strong>Effect</strong>&nbsp;
    </React.Fragment>
  )
}

const Attack = (props) => {
  const {
    attack,
    i,
    gameId,
    monsterName,
    token,
  } = props

  const sharedMonsterData = {
    monsterName: monsterName,
    token: token,
    gameId: gameId,
  }

  if (!attack) {return null}

  let traits = getTraits(attack)
  setLabel(attack)
  let damage = getDamage(attack, sharedMonsterData)
  let bonuses = getBonuses(attack, sharedMonsterData)
  let text = attack.bonus ? null : ` ${attack.text}`

  return (
    <div key={i}>
      <strong>{attack.name}{' '}</strong>
      {attack.action ? <Action name={attack.action.name} /> : null}
      {requirements(attack)}
      {attack.weapon} {bonuses}
      {traits}
      {damage}
      {text ? <span dangerouslySetInnerHTML={{ __html: text }} /> : null}
    </div>
  )
}

//#Attack.propTypes = PropTypes.shape(ATTACK)

export default Attack

/*
"attack": {
  "type": "stat_block_section",
  "subtype": "attack",
  "attack_type": "melee",
  "name": "Melee",
  "action": {
    "type": "stat_block_section",
    "subtype": "action",
    "name": "Single Action",
    "image": {
      "type": "image",
      "name": "OneAction",
      "image": "OneAction.png"
    }
  },
  "weapon": "jaws",
  "bonus": {
    "type": "stat_block_section",
    "subtype": "attack_bonus",
    "link": {
      "type": "link",
      "name": "+23/+18",
      "alt": "+23/+18",
      "game-obj": "Rules",
      "aonid": 322
    },
    "bonuses": [
      28,
      23,
      18
    ]
  },
  "damage": [
    {
      "type": "stat_block_section",
      "subtype": "attack_damage",
      "formula": "2d12+15",
      "damage_type": "piercing"
    },
    {
      "type": "stat_block_section",
      "subtype": "attack_damage",
      "effect": "slime"
    },
    {
      "type": "stat_block_section",
      "subtype": "attack_damage",
      "formula": "2d4",
      "persistent": true,
      "damage_type": "fire"
    },
    {
      "type": "stat_block_section",
      "subtype": "attack_damage",
      "formula": "2d10",
      "notes": "if the target is undead",
      "damage_type": "positive"
    },
    {
      "type": "stat_block_section",
      "subtype": "attack_damage",
      "formula": "1d6",
      "splash": true,
      "damage_type": "acid"
    }
  ],
  "traits": [
    {
      "type": "stat_block_section",
      "subtype": "trait",
      "name": "electricity",
      "link": {
        "type": "link",
        "name": "electricity",
        "alt": "electricity",
        "game-obj": "Traits",
        "aonid": 56
      }
    },
    {
      "type": "stat_block_section",
      "subtype": "trait",
      "name": "magical",
      "link": {
        "type": "link",
        "name": "magical",
        "alt": "magical",
        "game-obj": "Traits",
        "aonid": 103
      }
    },
    {
      "type": "stat_block_section",
      "subtype": "trait",
      "name": "reach 15 feet",
      "link": {
        "type": "link",
        "name": "reach 15 feet",
        "alt": "reach 15 feet",
        "game-obj": "Traits",
        "aonid": 192
      }
    }
  ]
}
*/
