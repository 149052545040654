import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import MonsterSearch from '../components/MonsterSearch'
import EncounterMonster from '../components/EncounterMonster'

import * as encountersService from '../../services/encounters'
import * as encountersMonstersService from '../../services/encounters_monsters'

import './Encounter.css'

import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Cancel'
import EditIcon from '@material-ui/icons/Edit'

import {
  chooseMonster,
  loadMonster,
  selectEncounter,
  switchPanel,
  updateEncounter,
  updateEncounterName,
} from '../../actions'

const Encounter = (props) => {
  const [editName, setEditName] = useState(false)
  const [monsterToAdd, setMonsterToAdd] = useState(null)
  const [monsterQuantity, setMonsterQuantity] = useState(1)
  const editNameRef = useRef(null)

  const {
    token,
    updatedEncounterName,
    encounter,
    monsters,
    loading,
    encounterMonsters,
  } = props

  const id = encounter.id
  const name = encounter.name

  useEffect(() => {
    if (editName === true) {
      editNameRef.current.focus()
    }
  }, [editName])

  const handleCloseIconClick = () => {
    props.selectEncounter(null)
  }

  const handleDeleteIconClick = () => {
    if (window.confirm(`Delete encounter: ${name}?`)) {
      encountersService.destroy(id, token)
      props.selectEncounter(null)
    }
  }

  const handleToggleNameEditor = () => {
    setEditName(!editName)
  }

  const handleEncounterNameChange = (e) => {
    props.updateEncounterName(e.target.value)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleUpdateEncounterName()
    }
  }

  const handleUpdateEncounterName = () => {
    encountersService.update(
      id,
      token,
      { name: updatedEncounterName }
    )
    setEditName(false)
  }

  const handleVisibilityIconClick = monsterId => {
    const monsterToLoad = monsters[monsterId]

    props.chooseMonster(monsterId)
    props.loadMonster(monsterToLoad)
    props.switchPanel('Monsters')
  }

  const handleMonsterSelect = monsterId => {
    setMonsterToAdd(monsters[monsterId])
  }

  const handleCreateMonsters = () => {
    encountersMonstersService.create(encounter.id, monsterToAdd.id, monsterQuantity, token)
    setMonsterToAdd(null)
    setMonsterQuantity(1)
  }

  const handleDamageMonster = (encounterMonsterId, health) => {
    encountersMonstersService.update(encounterMonsterId, token, {health: health})
  }

  const handleDestroyMonster = (encounterMonsterId) => {
    encountersMonstersService.destroy(encounterMonsterId, token)
  }

  const handleUpdateMonsterNick = (encounterMonsterId, newNickname) => {
    encountersMonstersService.update(encounterMonsterId, token, {nickname: newNickname})
  }

  const handleMonsterQuantityChange = e => {
    setMonsterQuantity(e.target.value)
  }

  // rendering helpers
  const renderLoading = () => {
    if (loading) {
      return <div>Loading...</div>
    }
  }

  const nameDisplay = () => {
    return (
      <div className='Encounter__nameRead'>
        {name}
        <button className='Encounter__delete' onClick={handleToggleNameEditor}>
          <EditIcon style={{ fontSize: 13 }} />
        </button>
      </div>
    )
  }

  const nameEdit = () => {
    return (
      <div className='Encounter__nameEdit'>
        <input type='text'
          ref={editNameRef}
          className='Encounter__encounterName'
          value={updatedEncounterName}
          onChange={handleEncounterNameChange}
          onKeyDown={handleKeyDown}
        />
        <button className='Encounter__saveNameChange' onClick={handleUpdateEncounterName}>
          <SaveIcon style={{ fontSize: 13 }} />
        </button>
        <button className='Encounter__cancelEdit' onClick={handleToggleNameEditor}>
          <CancelIcon style={{ fontSize: 13 }} />
        </button>
      </div>
    )
  }

  const renderMonsters = () => {
    const sortedIds = encounter.encounter_monster_ids.sort((a,b) => {
      return a - b;
    })

    return (
      <div className='Encounter__monsterList'>
        {sortedIds.map(encounterMonsterId => {
          const encounterMonster = encounterMonsters[encounterMonsterId]
          const monster = monsters[encounterMonster.pf2_monster_id]
          const viewMonsterFn = handleVisibilityIconClick.bind(null, encounterMonster.pf2_monster_id)
          const damageMonsterFn = handleDamageMonster.bind(null, encounterMonster.id)
          const destroyMonsterFn = handleDestroyMonster.bind(null, encounterMonster.id)
          const updateNickFn = handleUpdateMonsterNick.bind(null, encounterMonster.id)

          return (
            <EncounterMonster
              key={encounterMonster.id}
              monster={monster}
              encounterMonster={encounterMonster}
              onDamageMonster={damageMonsterFn}
              onViewMonster={viewMonsterFn}
              onDestroyMonster={destroyMonsterFn}
              onUpdateNick={updateNickFn}
            />
          )
        })}
      </div>
    )
  }

  const renderMonsterForm = () => {
    return (
      <>
        Monster: {monsterToAdd.name}
        <input className='Encounter__quantity' type='number' value={monsterQuantity} onChange={handleMonsterQuantityChange} name='monsterQuantity' />
        <button className='Encounter__bulkCreate' onClick={handleCreateMonsters}>
          <SaveIcon style={{ fontSize: 13 }} />
        </button>
      </>
    )
  }

  return (
    <div className='Encounter'>
      <button className='Encounter__close' onClick={handleCloseIconClick}>
        <CloseIcon style={{ fontSize: 13 }} />
      </button>
      <div className={`Encounter__name ${id} lets-roll-section`}>
        {editName ? nameEdit() : nameDisplay()}
      </div>

      <div>
        {loading ? renderLoading() : renderMonsters()}
      </div>

      <div className='Encounter__monsterForm'>
        <MonsterSearch label='Select Monster' onSelectMonster={handleMonsterSelect} />
        {monsterToAdd === null ? null : renderMonsterForm()}
      </div>

      <button className='Encounter__delete' onClick={handleDeleteIconClick}>
        <span>Delete Encounter</span>
        <DeleteIcon style={{ fontSize: 13 }} />
      </button>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    encounters: state.encounters.list,
    encounterMonsters: state.encounter_monsters.list,
    selectedEncounterId: state.encounters.selectedEncounterId,
    token: state.client.token,
    updatedEncounterName: state.client.updatedEncounterName,
    monsters: state.monsters.list,
    loading: state.monsters.loading,
  }
}

const mapDispatchToProps = {
  chooseMonster,
  loadMonster,
  selectEncounter,
  switchPanel,
  updateEncounterName,
  updateEncounter,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Encounter)
