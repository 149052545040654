import React from 'react'
import Graft from './Graft';

const Grafts = (props) => {
  const {
    grafts,
    monsterName,
    token,
    gameId
  } = props

  const sharedMonsterData = {
    monsterName: monsterName,
    token: token,
    gameId: gameId,
  }

  if (!grafts) {return null}

  return (
    <div className='Monster__grafts'>
      {
        grafts.map((g, i) => {
          return (
            <Graft graft={g} {...sharedMonsterData} key={i} />
          )
        })
      }
    </div>
  )
}


export default Grafts

/* Grafts Examples

npc - Bloody Blade Mercenary

There need to be two fixes in PFSRD2 to make this work right

"grafts": [
  {
      "type": "stat_block_section",
      "subtype": "graft",
      "name": "Human",
      "links": [
          {
              "type": "link",
              "name": "Human",
              "alt": "Human",
              "game-obj": "Ancestries",
              "aonid": 6
          }
      ],
      "modifiers": [
          {
              "type": "stat_block_section",
              "subtype": "modifier",
              "name": "half-orc",
              "links": [
                  {
                      "type": "link",
                      "name": "half-orc",
                      "alt": "half-orc",
                      "game-obj": "Ancestries",
                      "aonid": 8
                  }
              ]
          }
      ]
  },
  {
      "type": "stat_block_section",
      "subtype": "graft",
      "name": "ranger",
      "links": [
          {
              "type": "link",
              "name": "ranger",
              "alt": "ranger",
              "game-obj": "Ancestries",
              "aonid": 9
          }
      ]
  },
  {
      "type": "stat_block_section",
      "subtype": "graft",
      "name": "1"
  }
],


*/