import React from "react"
import PropTypes from "prop-types"

class Games extends React.Component {
  render () {
    const {
      error,
      games,
      invitations,
    } = this.props

    const maybeRenderError = () => {
      if (!error) { return null }

      return (
        <div className='Flash__error'>
          {error}
        </div>
      )
    }

    return (
      <div>
        {maybeRenderError()}
        <h1>All Games</h1>
        <div className='ContentBox'>
          <a href='/games/new' className='ButtonLink'>Create a Game</a>

          <h3>Games</h3>
          <ul>
            {games.map(game => (
              <li key={game.id}><a href={`/games/${game.id}`}>{game.name}</a></li>
            ))}
          </ul>

          <h3>Invitations</h3>
          <ul>
            {invitations.map(data => {
              return (
                <li key={data.invitation.id}>
                  <a href={`/games/${data.game.id}`}>
                    {data.game.name}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    )
  }
}

Games.propTypes = {
  games: PropTypes.array,
  invitations: PropTypes.array,
};

export default Games
