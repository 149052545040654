import React from 'react'
/*import PropTypes from 'prop-types';
import {
  ABILITY
} from '../../../../constants/monster_constants'*/
import Action from './Action'
import Markdown from '../../../containers/Markdown';

const Ability = (props) => {
  const {
    ability,
    i
  } = props

  if (!ability) {return null}

  let period = "";

  let semicolon = " ";

  const separator = () => {
    let result = `${semicolon}`;
    semicolon = "; ";
    return result
  }

  const renderAction = (ability) => {
    if (!ability.action) {return null}
    return (
      <span>
        {' '}
        <Action name={ability.action.name}/>
      </span>)
  }

  const renderTraits = (ability) => {
    if (!ability.traits) {return null}
    return (
      <span>
        {' '}
        ({
          ability.traits.map((trait,i) => trait.value ?
           `${trait.name} ${trait.value}` : trait.name).join(', ')
        })
      </span>
    )
  }

  const renderRange = (ability) => {
    if (!ability.range) {return null}
    period = "."
    return (
      <span>
        {' '}
        <strong>Range</strong>
        {' '}
        <span>{ability.range.range} {ability.range.unit}</span>
      </span>
    )
  }

  const renderSavingThrow = (ability) => {
    if (!ability.saving_throw) {return null}
    period = "."
    return (
      <span>
        {' '}
        <strong>Save</strong>
        {' '}
        <span>
          DC{
            ability.saving_throw.dc ? ` ${ability.saving_throw.dc}` : ""
          }{
            ability.saving_throw.save_type ? ` ${ability.saving_throw.save_type}` : ''
          }{
            ability.saving_throw.result ? ` ${ability.saving_throw.result}` : ''
          }
        </span>
      </span>
    )
  }

  const renderDamage = (ability) => {
    const _dam = (damage) => {
      let retval = "";
      if (damage.formula) {
        retval += `${damage.formula} `;
      }
      if (damage.damage_type) {
        retval += `${damage.damage_type} `;
      }
      if (damage.notes) {
        retval += `${damage.notes}`;
      }
      return retval
    }

    if (!ability.damage) {return null}
    period = "."
    return (
      <span>
        {' '}
        <strong>Damage</strong>
        {' '}
        {
          ability.damage.map((d,i) =>
           `${_dam(d)}`).join(', ')
        }
      </span>
    )
  }

  const renderSuccess = (success, name) => {
    if (!success) {return null}
    return (
      <div className='Monster__ability_success'>
        <strong>{name}</strong>
        {' '}{success}
      </div>
    )
  }

  const renderSection = (section, name) => {
    if (!section) {return null}
    return (
      <span>
        {separator()}
        <strong>{name}</strong>
        {' '}
        <Markdown text={section} />
      </span>
    )
  }

  //TODO: Handle trait links
  //TODO: Raise error if value exists, or find their use cases.
  //TODO: Raise error if modifiers exist, or find their use cases.
  return (
    <div key={i}>
      <strong>{ability.name}</strong> {
        renderAction(ability)
      }{
        renderTraits(ability)
      }{
        renderRange(ability)
      }{
        renderSavingThrow(ability)
      }{
        renderDamage(ability)
      }{period}{' '}{
        typeof ability.text === 'undefined'
          ? null
          : <Markdown text={ability.text} />
      }{
        renderSection(ability.cost, "Cost")
      }{
        renderSection(ability.prerequisite, "Prerequisite")
      }{
        renderSection(ability.requirement, "Requirement")
      }{
        renderSection(ability.frequency, "Frequency")
      }{
        renderSection(ability.trigger, "Trigger")
      }{
        renderSection(ability.effect, "Effect")
      }{
        renderSuccess(ability.critical_success, "Critical Success")
      }{
        renderSuccess(ability.success, "Success")
      }{
        renderSuccess(ability.failure, "Failure")
      }{
        renderSuccess(ability.critical_failure, "Critical Failure")
      }{/*
        {
        typeof ability.prerequisite === 'undefined'
          ? null
          : <span>
              {' '}
              <strong>Prerequisite</strong>
              {' '}
              <span dangerouslySetInnerHTML={{ __html: ability.prerequisite}} />
              </span>
      }*/}
    </div>
  )
}

//Ability.propTypes = PropTypes.shape(ABILITY)

export default Ability

/* Example

Greater Nightmare: - Range, Saving Throw w/ save result
(Note: Alghollthu Master gives you a save w/o result)

"interaction_abilities": [
    {
        "name": "Smoke",
        "type": "stat_block_section",
        "subtype": "ability",
        "ability_type": "interaction",
        "traits": [
            {
                "name": "Aura",
                "type": "trait",
                "sources": [
                    {
                        "type": "source",
                        "name": "Core Rulebook",
                        "link": {
                            "type": "link",
                            "name": "Core Rulebook pg. 629",
                            "alt": "Core Rulebook pg. 629",
                            "game-obj": "Sources",
                            "aonid": 1
                        },
                        "page": 629,
                        "errata": {
                            "type": "link",
                            "name": "4.0",
                            "alt": "4.0",
                            "game-obj": "Sources",
                            "aonid": 1
                        }
                    }
                ],
                "game-obj": "Traits",
                "game-id": "fed42ee4e8c8baf344ba3613c090a15f",
                "text": "An aura is an emanation that continually ebbs out from you, affecting creatures within a certain radius. Aura can also refer to the magical signature of an item or a creature with a strong alignment.",
                "classes": [
                    "equipment",
                    "feats",
                    "focus_spells",
                    "spells"
                ]
            }
        ],
        "text": "The nightmare continually exhales black smoke that creates concealment in an aura around it. Nightmares and their riders can see through this smoke. A creature that begins its turn in the area becomes sickened 2 (DC 28 Fortitude negates) and is then temporarily immune sickness from the smoke for 1 minute. The nightmare, its rider, any creature currently holding its breath (or that does not need to breathe), and any creature immune to poison are immune to the aura\u2019s sickened effect but not the concealment.",
        "range": {
            "type": "stat_block_section",
            "subtype": "range",
            "text": "20 feet",
            "range": 20,
            "unit": "feet"
        },
        "saving_throw": {
            "type": "stat_block_section",
            "subtype": "save_dc",
            "text": "DC 28 Fortitude negates",
            "dc": 28,
            "save_type": "Fort",
            "result": "negates"
        }
    }
],

Grikkitog: - Action

"interaction_abilities": [
    {
        "name": "Implant Core",
        "type": "stat_block_section",
        "subtype": "ability",
        "ability_type": "interaction",
        "action": {
            "type": "stat_block_section",
            "subtype": "action",
            "name": "Three Actions"
        },
        "traits": [
            {
                "name": "Manipulate",
                "type": "trait",
                "sources": [
                    {
                        "type": "source",
                        "name": "Core Rulebook",
                        "link": {
                            "type": "link",
                            "name": "Core Rulebook pg. 633",
                            "alt": "Core Rulebook pg. 633",
                            "game-obj": "Sources",
                            "aonid": 1
                        },
                        "page": 633,
                        "errata": {
                            "type": "link",
                            "name": "4.0",
                            "alt": "4.0",
                            "game-obj": "Sources",
                            "aonid": 1
                        }
                    }
                ],
                "game-obj": "Traits",
                "game-id": "2e56eb5a6b40def0cf1846d4dae37f43",
                "text": "You must physically manipulate an item or make gestures to use an action with this trait. Creatures without a suitable appendage can\u2019t perform actions with this trait. Manipulate actions often trigger reactions.",
                "classes": [
                    "actions",
                    "activities",
                    "feats",
                    "skills"
                ]
            }
        ],
        "text": "The grikkitog implants its core into an adjacent section of earth or stone, melding seamlessly and changing its visual appearance to match the surrounding rock. It\u2019s immobilized but automatically succeeds at its Deception check to Impersonate the stone around it; creatures actively searching for it can still attempt Perception checks against its Deception DC as normal. A grikkitog can release its implantation as a free action, which has the manipulate trait. A grikkitog\u2019s infestation aura and manifold vision are only active while implanted."
    },
    {
        "name": "Manifold Vision",
        "type": "stat_block_section",
        "subtype": "ability",
        "ability_type": "interaction",
        "text": "While its core is implanted, the grikkitog can see through the eyes it creates throughout the area of its infestation aura, gaining the benefits of all-around vision."
    }
],

Cauthooj: - Trait Links

"interaction_abilities": [
    {
        "name": "Thoughtsense",
        "type": "stat_block_section",
        "subtype": "ability",
        "ability_type": "interaction",
        "traits": [
            {
                "name": "Divination",
                "type": "trait",
                "sources": [
                    {
                        "type": "source",
                        "name": "Core Rulebook",
                        "link": {
                            "type": "link",
                            "name": "Core Rulebook pg. 631",
                            "alt": "Core Rulebook pg. 631",
                            "game-obj": "Sources",
                            "aonid": 1
                        },
                        "page": 631,
                        "errata": {
                            "type": "link",
                            "name": "4.0",
                            "alt": "4.0",
                            "game-obj": "Sources",
                            "aonid": 1
                        }
                    }
                ],
                "game-obj": "Traits",
                "game-id": "dc277f983a39bb74b92ffada679a9c8a",
                "text": "The divination school of magic typically involves obtaining or transferring information, or predicting events.",
                "classes": [
                    "actions",
                    "equipment",
                    "feats",
                    "focus_spells",
                    "rituals",
                    "spells"
                ]
            },
            {
                "name": "Mental",
                "type": "trait",
                "sources": [
                    {
                        "type": "source",
                        "name": "Core Rulebook",
                        "link": {
                            "type": "link",
                            "name": "Core Rulebook pg. 634",
                            "alt": "Core Rulebook pg. 634",
                            "game-obj": "Sources",
                            "aonid": 1
                        },
                        "page": 634,
                        "errata": {
                            "type": "link",
                            "name": "4.0",
                            "alt": "4.0",
                            "game-obj": "Sources",
                            "aonid": 1
                        }
                    }
                ],
                "game-obj": "Traits",
                "game-id": "977ad82e0847887b24c5f31a1f8e0c1d",
                "text": "A mental effect can alter the target's mind. It has no effect on an object or a mindless creature.",
                "classes": [
                    "actions",
                    "curses",
                    "equipment",
                    "feats",
                    "focus_spells",
                    "hazards",
                    "monsters",
                    "rituals",
                    "skills",
                    "spells"
                ],
                "links": [
                    {
                        "type": "link",
                        "name": "mindless",
                        "alt": "mindless",
                        "game-obj": "Traits",
                        "aonid": 108
                    }
                ]
            },
            {
                "name": "Occult",
                "type": "trait",
                "sources": [
                    {
                        "type": "source",
                        "name": "Core Rulebook",
                        "link": {
                            "type": "link",
                            "name": "Core Rulebook pg. 634",
                            "alt": "Core Rulebook pg. 634",
                            "game-obj": "Sources",
                            "aonid": 1
                        },
                        "page": 634,
                        "errata": {
                            "type": "link",
                            "name": "4.0",
                            "alt": "4.0",
                            "game-obj": "Sources",
                            "aonid": 1
                        }
                    }
                ],
                "game-obj": "Traits",
                "game-id": "0d7ce784f802442aeb7924ae7137e68a",
                "text": "This magic comes from the occult tradition, calling upon bizarre and ephemeral mysteries. Anything with this trait is magical.",
                "classes": [
                    "actions",
                    "curses",
                    "diseases",
                    "equipment",
                    "feats"
                ],
                "links": [
                    {
                        "type": "link",
                        "name": "magical",
                        "alt": "magical",
                        "game-obj": "Traits",
                        "aonid": 103
                    }
                ]
            }
        ],
        "text": "The cauthooj senses a creatures mental essence at the listed ranged."
    }
],



Anais Hag:

"interaction_abilities": [
    {
        "name": "Coven",
        "type": "stat_block_section",
        "subtype": "ability",
        "ability_type": "interaction",
        "text": "An annis hag adds *earthbind*, *passwall*, and *spellwrack* to her coven\u2019s spells.",
        "links": [
            {
                "type": "link",
                "name": "earthbind",
                "alt": "earthbind",
                "game-obj": "Spells",
                "aonid": 94
            },
            {
                "type": "link",
                "name": "passwall",
                "alt": "passwall",
                "game-obj": "Spells",
                "aonid": 216
            },
            {
                "type": "link",
                "name": "spellwrack",
                "alt": "spellwrack",
                "game-obj": "Spells",
                "aonid": 298
            }
        ]
    }
],

Scalescribe:

"interaction_abilities": [
    {
        "name": "Mage Bond",
        "type": "stat_block_section",
        "subtype": "ability",
        "ability_type": "interaction",
        "text": "After performing a 1-hour ritual, a scalescribe can bond with one willing creature capable of Casting Spells. The scalescribe can Aid their bonded caster in Crafting a scroll, and they can make their Transcribed spell available to the caster so long as the spell is of the caster's tradition. A bonded prepared caster can prepare the spell. A bonded spontaneous caster can add the spell to their spell repertoire during their daily preparations, and it remains as long as the Transcribed scroll exists.",
        "links": [
            {
                "type": "link",
                "name": "Aid",
                "alt": "Aid",
                "game-obj": "Actions",
                "aonid": 75
            },
            {
                "type": "link",
                "name": "Crafting",
                "alt": "Crafting",
                "game-obj": "Skills",
                "aonid": 4
            },
            {
                "type": "link",
                "name": "scroll",
                "alt": "scroll",
                "href": "Equipment.aspx?Category=15&Subcategory=52"
            }
        ]
    }
],

Nasurgeth:

{
    "type": "stat_block_section",
    "subtype": "ability",
    "ability_type": "automatic",
    "name": "Midnight Depths",
    "traits": [
        {
            "name": "Aura",
            "type": "trait",
            "sources": [
                {
                    "type": "source",
                    "name": "Core Rulebook",
                    "link": {
                        "type": "link",
                        "name": "Core Rulebook pg. 629",
                        "alt": "Core Rulebook pg. 629",
                        "game-obj": "Sources",
                        "aonid": 1
                    },
                    "page": 629,
                    "errata": {
                        "type": "link",
                        "name": "4.0",
                        "alt": "4.0",
                        "game-obj": "Sources",
                        "aonid": 1
                    }
                }
            ],
            "game-obj": "Traits",
            "game-id": "fed42ee4e8c8baf344ba3613c090a15f",
            "text": "An aura is an emanation that continually ebbs out from you, affecting creatures within a certain radius. Aura can also refer to the magical signature of an item or a creature with a strong alignment.",
            "classes": [
                "equipment",
                "feats",
                "focus_spells",
                "spells"
            ]
        },
        {
            "name": "Cold",
            "type": "trait",
            "sources": [
                {
                    "type": "source",
                    "name": "Core Rulebook",
                    "link": {
                        "type": "link",
                        "name": "Core Rulebook pg. 629",
                        "alt": "Core Rulebook pg. 629",
                        "game-obj": "Sources",
                        "aonid": 1
                    },
                    "page": 629,
                    "errata": {
                        "type": "link",
                        "name": "4.0",
                        "alt": "4.0",
                        "game-obj": "Sources",
                        "aonid": 1
                    }
                }
            ],
            "game-obj": "Traits",
            "game-id": "f8249eb70df69fe9dec268ae3ef0a2f1",
            "text": "Effects with this trait deal cold damage. Creatures with this trait have a connection to magical cold.",
            "classes": [
                "equipment",
                "feats",
                "focus_spells",
                "hazards",
                "monsters",
                "spells"
            ]
        },
        {
            "name": "Darkness",
            "type": "trait",
            "sources": [
                {
                    "type": "source",
                    "name": "Core Rulebook",
                    "link": {
                        "type": "link",
                        "name": "Core Rulebook pg. 630",
                        "alt": "Core Rulebook pg. 630",
                        "game-obj": "Sources",
                        "aonid": 1
                    },
                    "page": 630,
                    "errata": {
                        "type": "link",
                        "name": "4.0",
                        "alt": "4.0",
                        "game-obj": "Sources",
                        "aonid": 1
                    }
                }
            ],
            "game-obj": "Traits",
            "game-id": "2d7eb68cc70cfaa49e7535398147087e",
            "text": "Darkness effects extinguish non-magical light in the area, and can counteract less powerful magical light. You must usually target light magic with your darkness magic directly to counteract the light, but some darkness spells automatically attempt to counteract light.",
            "classes": [
                "equipment",
                "feats",
                "focus_spells",
                "spells"
            ],
            "links": [
                {
                    "type": "link",
                    "name": "counteract",
                    "alt": "counteract",
                    "game-obj": "Rules",
                    "aonid": 371
                },
                {
                    "type": "link",
                    "name": "light",
                    "alt": "light",
                    "game-obj": "Traits",
                    "aonid": 100
                }
            ]
        },
        {
            "name": "Divine",
            "type": "trait",
            "sources": [
                {
                    "type": "source",
                    "name": "Core Rulebook",
                    "link": {
                        "type": "link",
                        "name": "Core Rulebook pg. 631",
                        "alt": "Core Rulebook pg. 631",
                        "game-obj": "Sources",
                        "aonid": 1
                    },
                    "page": 631,
                    "errata": {
                        "type": "link",
                        "name": "4.0",
                        "alt": "4.0",
                        "game-obj": "Sources",
                        "aonid": 1
                    }
                }
            ],
            "game-obj": "Traits",
            "game-id": "7b6036035f872e012dcd1cf4f5447bf0",
            "text": "This magic comes from the divine tradition, drawing power from deities or similar sources. Anything with this trait is magical.",
            "classes": [
                "actions",
                "curses",
                "equipment",
                "feats",
                "focus_spells",
                "hazards",
                "monsters"
            ],
            "links": [
                {
                    "type": "link",
                    "name": "magical",
                    "alt": "magical",
                    "game-obj": "Traits",
                    "aonid": 103
                }
            ]
        },
        {
            "name": "Necromancy",
            "type": "trait",
            "sources": [
                {
                    "type": "source",
                    "name": "Core Rulebook",
                    "link": {
                        "type": "link",
                        "name": "Core Rulebook pg. 634",
                        "alt": "Core Rulebook pg. 634",
                        "game-obj": "Sources",
                        "aonid": 1
                    },
                    "page": 634,
                    "errata": {
                        "type": "link",
                        "name": "4.0",
                        "alt": "4.0",
                        "game-obj": "Sources",
                        "aonid": 1
                    }
                }
            ],
            "game-obj": "Traits",
            "game-id": "43f6a564e13fcacc75648269d2f58f98",
            "text": "Effects and magic items with this trait are associated with the necromancy school of magic, typically involving forces of life and death.",
            "classes": [
                "actions",
                "curses",
                "diseases",
                "equipment",
                "feats",
                "focus_spells",
                "hazards",
                "item_curses",
                "rituals",
                "spells"
            ]
        },
        {
            "name": "Negative",
            "type": "trait",
            "sources": [
                {
                    "type": "source",
                    "name": "Core Rulebook",
                    "link": {
                        "type": "link",
                        "name": "Core Rulebook pg. 634",
                        "alt": "Core Rulebook pg. 634",
                        "game-obj": "Sources",
                        "aonid": 1
                    },
                    "page": 634,
                    "errata": {
                        "type": "link",
                        "name": "4.0",
                        "alt": "4.0",
                        "game-obj": "Sources",
                        "aonid": 1
                    }
                }
            ],
            "game-obj": "Traits",
            "game-id": "af3fa75440073adbb3ba5a5ac6d36ac2",
            "text": "Effects with this trait heal undead creatures with negative energy, deal negative damage to living creatures, or manipulate negative energy. Planes with this trait are vast, empty reaches that suck the life from the living. Creatures with this trait are natives of the Negative Energy Plane. They can survive the basic environmental effects of the Negative Energy Plane.",
            "classes": [
                "actions",
                "curses",
                "equipment",
                "feats",
                "focus_spells",
                "hazards",
                "monsters",
                "planes",
                "rituals",
                "spells"
            ],
            "links": [
                {
                    "type": "link",
                    "name": "undead",
                    "alt": "undead",
                    "game-obj": "Traits",
                    "aonid": 160
                },
                {
                    "type": "link",
                    "name": "Negative Energy Plane",
                    "alt": "Negative Energy Plane",
                    "game-obj": "Planes",
                    "aonid": 2
                }
            ]
        }
    ],
    "text": "A nasurgeth's entropy grows even stronger underwater. All water within the aura is completely dark (as 4th-level *darkness*). Magical light with a counteract level of 4th level or lower, along with magical light cantrips, are suppressed. A living creature entering or starting its turn in the aura takes 4d6 negative damage, and the creature also takes an additional 2d10 cold damage if it's in water (DC 39 basic Fortitude save). If it fails, it's also enfeebled 1 for 1 minute and pulled 10 feet toward the nasurgeth.",
    "range": {
        "type": "stat_block_section",
        "subtype": "range",
        "text": "60 feet",
        "range": 60,
        "unit": "feet"
    },
    "damage": [
        {
            "type": "stat_block_section",
            "subtype": "attack_damage",
            "formula": "4d6",
            "notes": "plus 2d10 cold damage if it's in water",
            "damage_type": "negative"
        }
    ],
    "saving_throw": {
        "type": "stat_block_section",
        "subtype": "save_dc",
        "text": "DC 39 basic Fortitude save",
        "dc": 39,
        "save_type": "Fort",
        "result": "basic"
    },
    "links": [
        {
            "type": "link",
            "name": "darkness",
            "alt": "darkness",
            "game-obj": "Spells",
            "aonid": 59
        },
        {
            "type": "link",
            "name": "light",
            "alt": "light",
            "game-obj": "Traits",
            "aonid": 100
        },
        {
            "type": "link",
            "name": "counteract",
            "alt": "counteract",
            "game-obj": "Rules",
            "aonid": 371
        },
        {
            "type": "link",
            "name": "basic",
            "alt": "basic",
            "game-obj": "Rules",
            "aonid": 329
        },
        {
            "type": "link",
            "name": "enfeebled 1",
            "alt": "enfeebled 1",
            "game-obj": "Conditions",
            "aonid": 13
        },
        {
            "type": "link",
            "name": "pulled",
            "alt": "pulled",
            "game-obj": "Rules",
            "aonid": 451
        }
    ]
},

Babau:

"automatic_abilities": [
    {
        "type": "stat_block_section",
        "subtype": "ability",
        "ability_type": "automatic",
        "name": "Mercy Vulnerability",
        "text": "Babaus revel in gore, and when wounds are healed, they recoil in pain. When a creature heals from damage that the babau dealt on their last turn with Grievous Strike, sneak attack, or a critical hit, the demon takes 4d6 mental damage. The babau can take this mental damage only once per round.",
        "links": [
            {
                "type": "link",
                "name": "mental",
                "alt": "mental",
                "game-obj": "Traits",
                "aonid": 106
            }
        ]
    },
    {
        "type": "stat_block_section",
        "subtype": "ability",
        "ability_type": "automatic",
        "name": "Reactive Slime",
        "action": {
            "type": "stat_block_section",
            "subtype": "action",
            "name": "Reaction"
        },
        "trigger": "A creature within the babau's reach successfully hits the babau with a Strike",
        "effect": "The babau excretes a gout of acidic, bloodlike slime against the attacker and its weapon. The attacker must attempt a DC 24 Reflex save.",
        "critical_success": "The attacker is unaffected.",
        "success": "The attacker takes 1d6 acid damage.",
        "failure": "The attacker takes 2d6 acid damage.",
        "links": [
            {
                "type": "link",
                "name": "broken",
                "alt": "broken",
                "game-obj": "Conditions",
                "aonid": 2
            }
        ],
        "critical_failure": "As failure, except the weapon used to Strike the babau becomes broken, unless the weapon is made of a material that is immune to acid."
    }
]

*/
