import {
  ADD_TRACKED_ROLL,
} from '../constants/action-types'

const initialState = {
  trackedRolls: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_TRACKED_ROLL:

      let newTrackedRolls = [...state.trackedRolls]
      newTrackedRolls.push(action.payload)

      return {
        ...state,
        trackedRolls: newTrackedRolls,
      }
    default:
      return state
  }
}
