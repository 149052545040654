import { UPDATE_GAME } from '../constants/action-types'

export default (state = INITIAL.game, action) => {
  let gameId
  let gameData

  if (action.payload) {
    gameId = action.payload.id
    gameData = action.payload
  }

  switch(action.type) {
    case UPDATE_GAME:
      return {
        ...state,
        user_id: gameData.user_id,
      }
    default:
      return state
  }
}
