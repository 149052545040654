import React from 'react'
import PropTypes from 'prop-types';
import {
  KNOWLEDGES
} from '../../../../constants/monster_constants'

const Knowledges = (props) => {
  const {
    knowledges
  } = props

  if (!knowledges) {return null}

  let text = knowledges.map(k => {
    let name = k.name;

    if (k.skills) {
      let skills = k.skills.map(s => {
        return s.name
      }).join(", ");
      name += ` (${skills})`
    }

    let dc = k.dc;
    name += ` DC ${dc}`
    return name
  }).join("; ")

  // TODO: handle links
  // TODO: handle skill links
  // TODO: break out knowledge into it's own file
  return (
    <div className='Monster__knowledge'>
      <strong>Recall Knowledge</strong>
        &nbsp;{text}
    </div>
  )
}

//Knowledges.propTypes = PropTypes.shape(KNOWLEDGES)

export default Knowledges

/* Knowledge Examples
Mari Lwyd:

"knowledge": [
    {
        "type": "stat_block_section",
        "subtype": "knowledge",
        "dc": 30,
        "name": "Recall Knowledge - Fey",
        "link": {
            "type": "link",
            "name": "Recall Knowledge - Fey",
            "alt": "Recall Knowledge - Fey",
            "game-obj": "Rules",
            "aonid": 563
        },
        "skills": [
            {
                "name": "Nature",
                "type": "stat_block_section",
                "subtype": "knowledge_skill",
                "link": {
                    "type": "link",
                    "name": "Nature",
                    "alt": "Nature",
                    "game-obj": "Skills",
                    "aonid": 10
                }
            }
        ]
    },
    {
        "type": "stat_block_section",
        "subtype": "knowledge",
        "dc": 28,
        "name": "Unspecific Lore",
        "link": {
            "type": "link",
            "name": "Unspecific Lore",
            "alt": "Unspecific Lore",
            "game-obj": "Rules",
            "aonid": 563
        }
    },
    {
        "type": "stat_block_section",
        "subtype": "knowledge",
        "dc": 25,
        "name": "Specific Lore",
        "link": {
            "type": "link",
            "name": "Specific Lore",
            "alt": "Specific Lore",
            "game-obj": "Rules",
            "aonid": 563
        }
    }
],
*/