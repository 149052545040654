import {
  ADD_VARIABLE,
  DESTROY_VARIABLE,
  UPDATE_VARIABLE,
} from '../constants/action-types'
import { convertStateListToObject } from '../lib/utils'

const initialState = {
  list: INITIAL.variables,
  vars: convertStateListToObject(INITIAL.variables, "name"),
}

export default (state = initialState, action) => {
  const variables = Object.values(state.list)
  const initialVariables = [...variables]

  switch(action.type) {
    case ADD_VARIABLE:
      const variableId = action.payload.id
      const variableData = action.payload
      const characterId = action.characterId

      if (variableData.character_id !== characterId) {
        return state
      }

      initialVariables.push(variableData)

      const list = {
        ...state.list,
        [variableId]: variableData
      }
      return {
        ...state,
        list: list,
        vars: convertStateListToObject(list, "name"),
      }
    case UPDATE_VARIABLE: {
      const variableId = action.payload.id
      const variableData = action.payload
      const characterId = action.characterId

      if (variableData.character_id !== characterId) {
        return state
      }

      let list = {...state.list}
      list[variableId] = variableData

      return {
        ...state,
        list: list,
        vars: convertStateListToObject(list, "name"),
      }
    }
    case DESTROY_VARIABLE:
      const { [action.payload.id]: _, ...withoutVariable } = state.list

      return {
        ...state,
        list: withoutVariable,
        vars: convertStateListToObject(withoutVariable, "name"),
      }
    default:
      return state
  }
}
