import React from 'react'
import { connect } from 'react-redux'
import EditIcon from '@material-ui/icons/Edit'
import Encounter from './Encounter'

import {
  selectEncounter,
  updateNewEncounterName,
  updateEncounterName,
} from '../../actions'

import * as encountersService from '../../services/encounters'

import './Encounters.css'

const Encounters = (props) => {
  const {
    encounters,
    gameId,
    newEncounterName,
    token,
    selectedEncounterId,
  } = props

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleNewEncounterCreate()
    }
  }

  const handleNewEncounterChange = (e) => {
    props.updateNewEncounterName(e.target.value)
  }

  const handleNewEncounterCreate = () => {
    encountersService.create(newEncounterName, gameId, token)
    props.updateNewEncounterName('')
  }

  const handleEditIconClick = encounterId => {
    props.selectEncounter(encounterId)
    props.updateEncounterName(encounters[encounterId].name)
  }

  const encounterValues = Object.values(encounters)

  if (selectedEncounterId) {
    return (
      <div className='Encounters'>
        <Encounter encounter={ encounters[selectedEncounterId] }/>
      </div>
    )
  } else {
    return (
      <div className='Encounters'>
        <div className='lets-roll-section'>Encounters</div>
        {encounterValues.map(encounter => {
          const selectFn = handleEditIconClick.bind(null, encounter.id)
          return (
            <div key={encounter.id} className='Encounters__encounter'>
              <span className='Encounters__name'>{encounter.name}</span>
              <button className='Encounters__view' onClick={selectFn}>
                <EditIcon style={{ fontSize: 13 }} />
              </button>
            </div>
          )
        })}

        <div className='Encounters__newEncounter'>
          <label className='Encounters__newEncounterLabel'>Name</label>
          <input type='text'
            className='Encounters__newEncounter'
            value={newEncounterName}
            onChange={handleNewEncounterChange}
            onKeyDown={handleKeyDown}
          />
          <button onClick={handleNewEncounterCreate}>Create Encounter</button>
        </div>

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    gameId: state.game.id,
    encounters: state.encounters.list,
    selectedEncounterId: state.encounters.selectedEncounterId,
    newEncounterName: state.client.newEncounterName,
    token: state.client.token,
  }
}

const mapDispatchToProps = {
  updateNewEncounterName,
  selectEncounter,
  updateEncounterName
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Encounters)
