import {
  REORDER_INITIATIVE_QUEUE_ITEMS
} from "../constants/action-types"

const orderedIdsFromItems = (itemHash) => {
  const items = Object.values(itemHash).sort((a, b) => {
    if (a.initiative === b.initiative) {
      return a.initiative_priority - b.initiative_priority;
    }
    return b.initiative - a.initiative;
  });
  const itemIds = items.map((item) => {
    return `${item.id}`;
  });
  return itemIds;
};

const initalState = {
  items: INITIAL.initiativeQueueItems,
  orderedItemIds: orderedIdsFromItems(INITIAL.initiativeQueueItems)
}

export default (state = initalState, action) => {
  switch(action.type) {
    case REORDER_INITIATIVE_QUEUE_ITEMS:
      return {
        ...state,
        items: action.payload,
        orderedItemIds: orderedIdsFromItems(action.payload)
      }
    default:
      return state
  }
}
