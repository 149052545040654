import { ADD_CHARACTER, DESTROY_CHARACTER, REORDER_CHARACTERS, UPDATE_CHARACTER } from "../constants/action-types"

const initalState = {
  list: INITIAL.characters,
  orderedIds: Object.keys(INITIAL.characters)
}

export default (state = initalState, action) => {
  switch(action.type) {
    case ADD_CHARACTER: {
      const characterId = action.payload.id
      const characterData = action.payload
      const orderedIds = [...state.orderedIds]
      orderedIds.push(characterId)
      return {
        ...state,
        orderedIds,
        list: {
          ...state.list,
          [characterId]: characterData
        }
      }
    }
    case UPDATE_CHARACTER: {
      const characterId = action.payload.id
      const characterData = action.payload

      return {
        ...state,
        list: {
          ...state.list,
          [characterId]: characterData
        }
      }
    }
    case DESTROY_CHARACTER:
      const { [action.payload.id]: _, ...withoutCharacter } = state.list;
      const newOrderedIds = Object.values(withoutCharacter).map((c) => {
        return `${c.id}`
      })

      return {
        ...state,
        list: withoutCharacter,
        orderedIds: newOrderedIds
      }
    case REORDER_CHARACTERS:
      return {
        ...state,
        orderedIds: action.payload,
      }
    default:
      return state
  }
}