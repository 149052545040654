import React from 'react'
import defaultPortrait from '../../../assets/default_portrait'

import './MonsterCard.css'

const MonsterCard = props => {
  const { image, name } = props

  let portrait = defaultPortrait
  if (image) {
    portrait = `https://2e.aonprd.com/Images/Monsters/${image}`
  }

  return (
    <div className='MonsterCard'>
      <a href={portrait} target='_blank'>
        <img
          src={portrait}
          className='MonsterCard__portrait MonsterCard__portrait--party'
        />
      </a>
      <div className='MonsterCard__name'>{name}</div>
    </div>
  )
}

export default MonsterCard
