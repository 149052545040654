import React from 'react'
import Modifiers from './Modifiers';
import * as dice from '../../../../lib/dice'

const Save = (props) => {
  const {
    save,
    monsterName,
    token,
    gameId
  } = props

  if (!save) {return null}

  // TODO: handle skill link
  return (
    <span className='Monster__save'>
      <strong>{save.name}</strong> {dice.d20Text(`${monsterName} ${save.name}`, save.value, 'Monster__roll', gameId, token)}
      <Modifiers modifiers={save.modifiers} />
    </span>
  )
}

export default Save

/* Save Example
Slurk:

"fort": {
    "type": "stat_block_section",
    "subtype": "save",
    "name": "Fort",
    "value": 10,
    "modifiers": [
        {
            "type": "stat_block_section",
            "subtype": "modifier",
            "name": "+12 vs. Grapple or Shove"
        }
    ]
},

Veteran Reclaimer:

"ref": {
    "type": "stat_block_section",
    "subtype": "save",
    "name": "Ref",
    "value": 23,
    "modifiers": [
        {
            "type": "stat_block_section",
            "subtype": "modifier",
            "name": "successes are instead critical successes"
        }
    ]
},


Quelaunt:
"will": {
    "type": "stat_block_section",
    "subtype": "save",
    "name": "Will",
    "value": 31,
    "modifiers": [
        {
            "type": "stat_block_section",
            "subtype": "modifier",
            "name": "+33 vs. emotion"
        }
    ]
}

*/
