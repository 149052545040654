import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import Avatar from '../components/Avatar'
import { reorderCharacters, reorderInitiativeQueueItems, updateCharacter } from '../../actions'
import * as characterService from '../../services/characters'

import './Party.css'

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: '16px 16px 8px 16px',
  margin: `0 ${grid}px 0 0`,

  // change background colour if dragging
  background: isDragging ? '#F79639' : '#222',
  borderRadius: '4px',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  // background: isDraggingOver ? 'lightblue' : 'lightgrey',
  display: 'flex',
  padding: grid,
  overflow: 'auto',
});

const Party = (props) => {
  const {
    characters,
    ownerId,
    token,
    reorderInitiativeQueueItems,
    updateCharacter,
    initiativeQueueItems,
  } = props

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    if (sourceIndex === destinationIndex) {
      return;
    }

    const queueItemToMove = initiativeQueueItems.items[initiativeQueueItems.orderedItemIds[sourceIndex]]
    const entityToMove = characters[queueItemToMove.queueable_id]

    const originalInitiative = entityToMove.initiative
    const originalInitiativePriority = entityToMove.initiative_priority
    let updatedInitiative
    let updatedInitiativePriority

    if (sourceIndex > destinationIndex) {
      if (destinationIndex > 0) {
        const entityToGoAfter = initiativeQueueItems.items[initiativeQueueItems.orderedItemIds[destinationIndex - 1]]
        const anchorChar = characters[entityToGoAfter.queueable_id]

        updatedInitiative = anchorChar.initiative
        updatedInitiativePriority = anchorChar.initiative_priority+1
      } else {
        const entityToGoBefore = initiativeQueueItems.items[initiativeQueueItems.orderedItemIds[destinationIndex]]
        const anchorChar = characters[entityToGoBefore.queueable_id]
        updatedInitiative = anchorChar.initiative
        updatedInitiativePriority = anchorChar.initiative_priority
      }
    } else if (sourceIndex < destinationIndex) {
      const entityToGoAfter = initiativeQueueItems.items[initiativeQueueItems.orderedItemIds[destinationIndex]]
      const anchorChar = characters[entityToGoAfter.queueable_id]

      updatedInitiative = anchorChar.initiative
      if (updatedInitiative == originalInitiative) {
        updatedInitiativePriority = anchorChar.initiative_priority
      } else {
        updatedInitiativePriority = anchorChar.initiative_priority+1
      }
    }

    const updatedInitiativeQueueItems = {}
    for (const key in initiativeQueueItems.items) {
      const item = initiativeQueueItems.items[key]
      const newItem = JSON.parse(JSON.stringify(item))
      const char = characters[item.queueable_id]
      const newChar = JSON.parse(JSON.stringify(char))

      if (item.id == queueItemToMove.id) {
        newItem.initiative = updatedInitiative
        newItem.initiative_priority = updatedInitiativePriority

        newChar.initiative = newItem.initiative
        newChar.initiative_priority = newItem.initiative_priority
      } else {
        newChar.initiative_priority = newItem.initiative_priority
        if (item.initiative == originalInitiative && item.initiative_priority > originalInitiativePriority) {
          newItem.initiative_priority = item.initiative_priority - 1
        } else if (item.initiative == updatedInitiative && item.initiative_priority >= updatedInitiativePriority) {
          newItem.initiative_priority = item.initiative_priority + 1
        }
      }
      updateCharacter(newChar)
      characterService.updateInitiative(newChar.initiative, newChar.initiative_priority, newChar.id, token)
      updatedInitiativeQueueItems[newItem.id] = newItem
    }

    reorderInitiativeQueueItems(updatedInitiativeQueueItems)
  };

  return(
    <div className='Party'>
      <div className='Party__header'>Party</div>
      <div className='Party__characters'>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >

                {initiativeQueueItems.orderedItemIds.map((id, index) => {
                  const queueItem = initiativeQueueItems.items[id]

                  let char
                  if (queueItem.queueable_type == 'Character') {
                    char = characters[queueItem.queueable_id];
                  } else {
                    char = null;
                  }
                  if (char == null) {
                    return; // Exit the current iteration of the loop
                  }

                  return (
                    <Draggable key={char.id} draggableId={char.name} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <Avatar
                            key={char.id}
                            id={char.id}
                            name={char.name}
                            ownerId={ownerId}
                            isOwner={props.isOwner}
                            avatarUrl={char.avatar_url}
                            initiative={char.initiative}
                            initiativePriority={char.initiative_priority}
                            kind='party'
                          />
                        </div>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

    </div>
  )
}

const mapDispatchToProps = { reorderCharacters, reorderInitiativeQueueItems, updateCharacter }

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    characters: state.characters.list,
    ownerId: state.ownerId,
    token: state.client.token,
    gameId: state.game.id,
    initiativeQueueItems: state.initiativeQueueItems,
    isOwner: state.user.is_owner,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Party)
