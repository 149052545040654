import React from 'react'
import { capitalize } from '../../../../lib/utils'
import * as dice from '../../../../lib/dice'

const AbilityScores = (props) => {
  const {
    stats,
    monsterName,
    token,
    gameId
  } = props

  if (!stats) {return null}

  const ability_scores = ['str', 'dex', 'con', 'int', 'wis', 'cha'].map(as => [
    as,
    stats[as],
  ])

  return (
    <div className='Monster__abilityScores'>
      {ability_scores.map((as, i) => {
        return (
          <span key={i} className='Monster__abilityScore'>
            <strong>{capitalize(as[0])}</strong>{' '}{dice.d20Text(`${monsterName} ${capitalize(as[0])}`, as[1], 'Monster__roll', gameId, token)}
          </span>
        )
      })}
    </div>

  )
}

export default AbilityScores

