import React from 'react'
import PropTypes from 'prop-types';
import {
  SENSES
} from '../../../../constants/monster_constants'
import SpecialSense from './SpecialSense';
import Modifiers from './Modifiers';
import * as dice from '../../../../lib/dice'
import { comma } from '../../../../lib/pf2/monster_utils';

const Senses = (props) => {
  const {
    senses,
    monsterName,
    token,
    gameId
  } = props

  if (!senses) {return null}
  const perception = senses.perception
  const special_senses = senses.special_senses

  return (
    <div className='Monster__senses'>
      <strong>Perception</strong>{' '}{dice.d20Text(`${monsterName} Initiative`, perception.value, 'Monster__roll', gameId, token)}
      <Modifiers modifiers={perception.modifiers} />{comma(-1, special_senses, "; ")}
      {special_senses ? special_senses.map( (ss, i) => {
        return (
          <SpecialSense specialSense={ss} i={i} key={i}>{comma(i, special_senses)}</SpecialSense>
        )
      }) : null}
    </div>
  )
}

//Senses.propTypes = PropTypes.shape(SENSES)

export default Senses

/* Senses Examples

Balisse:

"senses": {
    "type": "stat_block_section",
    "subtype": "senses",
    "perception": {
        "type": "stat_block_section",
        "subtype": "perception",
        "modifiers": [
            {
                "type": "stat_block_section",
                "subtype": "modifier",
                "name": "+20 to detect lies and illusions"
            }
        ],
        "value": 18
    },
    "special_senses": [
        {
            "type": "stat_block_section",
            "subtype": "special_sense",
            "name": "darkvision"
        }
    ]
},

*/