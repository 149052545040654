import React from 'react'

import './Action.css'

const Action = props => {
  const { name } = props

  let symbols = {
    'Free Action': 'F',
    'One Action': 'A',
    'Single Action': 'A',
    'Two Actions': 'D',
    'Three Actions': 'T',
    'Reaction': 'R',
  }
  let text = symbols[name]

  return (
    <span
      className="Monster__actionIcon"
      title={name}>{text} </span>
  )
}

export default Action
