import React from 'react'
import { connect } from 'react-redux'
import * as variableService from '../../services/variables'
import './VariableButton.css'

const Variable = (props) => {
  const {
    variable,
    token,
    characterId,
  } = props

  const { structured_data } = variable
  const { label } = structured_data

  const activeToggle = variable.structured_data.active != false
  const handleToggleVariable = (e) => {
    variable.structured_data.active = activeToggle != true
    variableService.update(variable, variable.id, characterId, token)
  }

  return (
    <div key={variable.id} className='VariableButton'>
      {activeToggle ? (
        <button
          className='VariableButton__active'
          onClick={handleToggleVariable}>{label}
        </button>
      ) : (
        <button
          className='VariableButton__inactive'
          onClick={handleToggleVariable}>{label}
        </button>
      )}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    variable: ownProps.variable,
    token: state.client.token,
    gameId: state.game.id,
    characterId: state.characterId,
  }
}

const mapDispatchToProps = {
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Variable)
