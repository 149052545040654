import React from 'react'
import Modifiers from './Modifiers';

const Graft = (props) => {
  const {
    graft,
    i,
    children
  } = props

  if (!graft) {return null}

  let name = graft.name
  if (graft.value) {
    name += ` ${graft.value}`
  }

  // TODO: handle graft link
  return (
    <span className='Monster__graft' key={i}>
      {name}<Modifiers modifiers={graft.modifiers} />&nbsp;
    </span>
  )
}

export default Graft

/* Graft Example
npc - Bloody Blade Mercenary

{
      "type": "stat_block_section",
      "subtype": "graft",
      "name": "Human",
      "links": [
          {
              "type": "link",
              "name": "Human",
              "alt": "Human",
              "game-obj": "Ancestries",
              "aonid": 6
          }
      ],
      "modifiers": [
          {
              "type": "stat_block_section",
              "subtype": "modifier",
              "name": "half-orc",
              "links": [
                  {
                      "type": "link",
                      "name": "half-orc",
                      "alt": "half-orc",
                      "game-obj": "Ancestries",
                      "aonid": 8
                  }
              ]
          }
      ]
  },
*/
