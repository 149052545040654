import mixpanel from 'mixpanel-browser'
import { MP_CREATE_GROUP_ROLL, MP_DELETE_GROUP_ROLL } from "../constants/mixpanel_events"


export async function create(label, gameId, token) {
  post({
    label: label,
    game_id: gameId,
  }, token)

  mixpanel.track(MP_CREATE_GROUP_ROLL, {
    label: label
  })
}

export async function destroy(groupRollId, token) {
  const response = await fetch(`${baseUrl()}/${groupRollId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': token
    }
  })

  mixpanel.track(MP_DELETE_GROUP_ROLL)

  const groupRollData = await response.json()
  return groupRollData
}

async function post(values, token) {
  const response = await fetch(baseUrl(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': token
    },
    body: JSON.stringify(values)
  })
}

function baseUrl() {
  return `${window.location.protocol}//${location.host}/group_rolls`
}